import axios from "axios";
import React, { useContext, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import './Sidebar.css';

let cancelToken = axios.CancelToken.source();
const defaultMenu = [
    { name: "Dashboard", link: "/dashboard", icon: "fa fa-home", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
    {
        name: "Outline", link: "/outline", icon: "fa fa-list-ul", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
        submenu: [
            { name: "Missions", link: "/outline/missions", icon: "fa fa-rocket", unLocked: true, isCompleted: false, access: [0], helpText: "" },
            { name: "Stages & Activities", link: "/outline/stages", icon: "fa fa-tasks", unLocked: false, isCompleted: false, access: [0], helpText: "Create missions to unlock" }
        ]
    },
    {
        name: "Content", link: "/content", icon: "fa fa-hdd-o", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
        submenu: [
            { name: "Offering Details", link: "/content/offering-details", icon: "fa fa-cubes", unLocked: true, isCompleted: false, access: [0], helpText: "" },
            { name: "Links", link: "/content/links", icon: "fa fa-link", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            { name: "Thought Catalog", link: "/content/thought-catalog", icon: "fa fa-book", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            { name: "Profile Questions", link: "/content/meta-data", icon: "fa fa-address-card-o", unLocked: false, isCompleted: false, access: [0], helpText: "" }
        ]
    },
    {
        name: "Settings", link: "/settings", icon: "fa fa-cog", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
        submenu: [
            { name: "Gamification", link: "/settings/gamification", icon: "fa fa-gamepad", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            { name: "Rating Scales", link: "/settings/rating-scales", icon: "fa fa-star-half-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            { name: "Tokens", link: "/settings/tokens", icon: "fa fa-tag", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            // { name: "Award Categories", link: "/settings/award-category", icon: "fa fa-bookmark-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
            { name: "Email Template", link: "/settings/email-template", icon: "fa fa-envelope-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },

            // { name: "UI Theme", link: "/settings/theme", icon: "fa fa-desktop", unLocked: false, isCompleted: false, access: [0], helpText: "" }
        ]
    },
    { name: "Schedule", link: "/schedule", icon: "fa fa-calendar", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
    { name: "Registrations", link: "/registrations", icon: "fa fa-users", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
    { name: "Team Allocation", link: "/team-allocation", icon: "fa fa-cogs", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "There is no registered user" },
    { name: "Publish", link: "/publish", icon: "fa fa-globe", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
    { name: "Reports", link: "/reports", icon: "fa fa-table", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
    { name: "Certificates", link: "/certificates", icon: "fa fa-certificate", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
    { type: "divider" },
    { name: "Program Offerings", link: "/offering-list", icon: "fa fa-list-ul", unLocked: true, isCompleted: false, access: [1, 2, 3], type: "menu", helpText: "" },
    { name: "Offering Users", link: "/offering/user-management", icon: "fa fa-user", unLocked: true, isCompleted: false, access: [1, 2], type: "menu", helpText: "" }
];

const AdminSidebar = (props) => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [menuData, setMenuData] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [activeMenu, setActiveMenu] = React.useState('dashboard');
    const [rawMenuData, setRawMenuData] = React.useState([...defaultMenu]);
    const [menuStringId, setMenuStringId] = React.useState(null);


    const [adminMenuData, setAdminMenuData] = React.useState([
        { name: "Program Offerings", link: "/offering-list", icon: "fa fa-list-ul", unLocked: true, isCompleted: false, access: [1, 2], type: "menu", helpText: "" },
        { name: "User Management", link: "/user-management", icon: "fa fa-user", unLocked: true, isCompleted: false, access: [1], type: "menu", helpText: "" },
    ]);


    useEffect(() => {
        const newMenu = [
            { name: "Dashboard", link: "/dashboard", icon: "fa fa-home", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
            {
                name: "Outline", link: "/outline", icon: "fa fa-list-ul", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
                submenu: [
                    { name: "Missions", link: "/outline/missions", icon: "fa fa-rocket", unLocked: true, isCompleted: false, access: [0], helpText: "" },
                    { name: "Stages & Activities", link: "/outline/stages", icon: "fa fa-tasks", unLocked: false, isCompleted: false, access: [0], helpText: "Create missions to unlock" }
                ]
            },
            {
                name: "Content", link: "/content", icon: "fa fa-hdd-o", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
                submenu: [
                    { name: "Offering Details", link: "/content/offering-details", icon: "fa fa-cubes", unLocked: true, isCompleted: false, access: [0], helpText: "" },
                    { name: "Links", link: "/content/links", icon: "fa fa-link", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    { name: "Thought Catalog", link: "/content/thought-catalog", icon: "fa fa-book", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    { name: "Profile Questions", link: "/content/meta-data", icon: "fa fa-address-card-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    { name: "Email Template", link: "/settings/email-template", icon: "fa fa-envelope-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                ]
            },
            {
                name: "Settings", link: "/settings", icon: "fa fa-cog", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "",
                submenu: [
                    { name: "Gamification", link: "/settings/gamification", icon: "fa fa-gamepad", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    { name: "Rating Scales", link: "/settings/rating-scales", icon: "fa fa-star-half-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    { name: "Tokens", link: "/settings/tokens", icon: "fa fa-tag", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    // { name: "Award Categories", link: "/settings/award-category", icon: "fa fa-bookmark-o", unLocked: false, isCompleted: false, access: [0], helpText: "" },
                    // { name: "UI Theme", link: "/settings/theme", icon: "fa fa-desktop", unLocked: false, isCompleted: false, access: [0], helpText: "" }
                ]
            },
            { name: "Schedule", link: "/schedule", icon: "fa fa-calendar", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
            { name: "Registrations", link: "/registrations", icon: "fa fa-users", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
            { name: "Team Allocation", link: "/team-allocation", icon: "fa fa-cogs", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "There is no registered user" },
            { name: "Publish", link: "/publish", icon: "fa fa-globe", unLocked: false, isCompleted: false, access: [0], type: "menu", helpText: "" },
            { name: "Reports", link: "/reports", icon: "fa fa-table", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
            { name: "Certificates", link: "/certificates", icon: "fa fa-certificate", unLocked: true, isCompleted: false, access: [0], type: "menu", helpText: "" },
            { type: "divider" },
            { name: "Program Offerings", link: "/offering-list", icon: "fa fa-list-ul", unLocked: true, isCompleted: false, access: [1, 2, 3], type: "menu", helpText: "" },
            { name: "Offering Users", link: "/offering/user-management", icon: "fa fa-user", unLocked: true, isCompleted: false, access: [1, 2], type: "menu", helpText: "" }
        ]

        if (user.selectedOffering > 0) {
            cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            ProgramSetupAxios.get('/OfferingStatus/offeringstatusstring/' + user.selectedOffering, { cancelToken: cancelToken.token })
                .then(res => {
                    if (res.status === 204) {
                        // console.log("Default menu", newMenu);
                        updateMenuString(newMenu);
                    } else {
                        let rawMenu = res.data;
                        let updatedMenu = JSON.parse(rawMenu.offeringStatusString);
                        setMenuStringId(rawMenu.id);

                        let updatedRawMenu = rawMenuData.map(item => {
                            let menuItem = updatedMenu.find(x => x.link === item.link);
                            if (menuItem) {
                                item.unLocked = menuItem.unLocked;
                                item.isCompleted = menuItem.isCompleted;
                            }


                            if (item.submenu) {
                                let updatedSubMenu = item.submenu.map(subItem => {
                                    let subMenuItem = menuItem.submenu.find(x => x.link === subItem.link);
                                    if (subMenuItem) {
                                        subItem.unLocked = subMenuItem.unLocked;
                                        subItem.isCompleted = subMenuItem.isCompleted;
                                    }
                                    return subItem;
                                });

                                item.submenu = updatedSubMenu;
                            }
                            return item;
                        }
                        );
                        // console.log("updatedRawMenu", updatedRawMenu);
                        setRawMenuData(updatedRawMenu);
                    }
                }
                ).catch(err => {
                    console.log(err);
                    if (axios.isCancel(err)) {
                        console.log("Request canceled");
                    }
                    setRawMenuData(newMenu);
                }
                );
        }
    }, [user.selectedOffering]);

    const updateMenuString = (updatedMenu) => {
        let menuString = {
            offeringId: user.selectedOffering,
            offeringStatusString: JSON.stringify(updatedMenu)
        }
        if (menuStringId) {
            menuString.id = menuStringId;
        }

        ProgramSetupAxios.put('/OfferingStatus/updateofferingstatus', menuString)
            .then(res => {
                // console.log(res);
                setRawMenuData(updatedMenu);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {

        let menu = [];
        let baseMenu = rawMenuData;

        if (props.onlyAdminMenu) {
            baseMenu = adminMenuData;
        }

        menu = baseMenu.filter(item => {
            if (item.type === "menu") {
                if (item.access.includes(0)) {
                    if (item.submenu) {
                        item.submenu = item.submenu.filter(subitem => {
                            if (subitem.access.includes(0)) {
                                return true;
                            }
                            return subitem.access.includes(user.roleId);
                        });
                    }
                    return true;
                }
                return item.access.includes(user.roleId);
            }
            return true;
        });

        setMenuData(menu);
    }, [user, rawMenuData]);

    useEffect(() => {
        let menu = [...rawMenuData];

        user.menuStatus.forEach(step => {
            let linkPortions = step.link.split('/');

            if (linkPortions.length > 2) {
                let stepIndex = menu.findIndex(item => item.link === "/" + linkPortions[1]);

                if (stepIndex > -1) {
                    let childStepIndex = menu[stepIndex].submenu.findIndex(item => item.link === step.link);
                    menu[stepIndex].submenu[childStepIndex].isCompleted = Boolean(step.isCompleted);
                    menu[stepIndex].submenu[childStepIndex].unLocked = Boolean(step.unLocked);
                }
            }
            else {
                let stepIndex = menu.findIndex(item => item.link === step.link);
                menu[stepIndex].isCompleted = Boolean(step.isCompleted);
                menu[stepIndex].unLocked = Boolean(step.unLocked);
            }
        });

        // setRawMenuData(menu);
        if (user.menuStatus.length > 0) {
            updateMenuString(menu);
        }
    }, [user.menuStatus]);

    useEffect(() => {
        if (props.setMenuData) {
            props.setMenuData(menuData);
        }
    }, [menuData]);

    const handleClick = (e, title) => {
        let linkDisabled = e.currentTarget.classList.contains("disabled");
        if (linkDisabled) e.preventDefault()
        document.title = "CDx Admin Portal - " + title;
    }

    const checkActive = (e, link) => {
        if (link === activeMenu && !e.target.classList.contains("dropdown-toggle"))
            setActiveMenu('')
        else
            setActiveMenu(link)
    }

    const redirectHome = () => {

        let link = menuData[0].link || "/dashboard";
        let title = menuData[0].name || "Dashboard";

        document.title = "CDx Admin Portal - " + title;
        navigate(link, { replace: true });
    }

    return (
        <div className="sidebar" id='sidebar'>
            <div className="brand" onClick={redirectHome}>
                CDx <div className="menu-text"> &nbsp; Admin Portal</div>
            </div>
            <div className="sidebar-content">
                <ul className="sidebar-menu nav  flex-column">
                    {/* {activeMenu} */}
                    {menuData.map((item, index) => (
                        <>

                            {item.type === "menu" &&
                                <li key={index} className="s-menu-item">
                                    {item.submenu ?
                                        <>
                                            <NavDropdown
                                                title={
                                                    <div className="link" onClick={(e) => checkActive(e, item.link)}>
                                                        <div className="s-menu-icon-title">
                                                            <i className={item.icon} />
                                                            <div className="menu-text">{item.name}</div>
                                                        </div>
                                                        <i className="fa fa-angle-right s-menu-caret pointer menu-text" />
                                                    </div>
                                                }
                                                className="s-menu-dropdown"
                                                show={activeMenu === item.link ? true : false}
                                                onToggle={() => setShow(!show)}
                                            >
                                                {item.submenu.map((subitem, subindex) => (
                                                    <NavLink
                                                        to={subitem.link}
                                                        className={subitem.unLocked ? 'link' : 'link disabled'}
                                                        onClick={(e) => { handleClick(e, subitem.name); }} key={"sub-menu-" + subindex}
                                                    >
                                                        <div className="s-menu-icon-title">
                                                            <i className={subitem.icon} />
                                                            <div className="menu-text">{subitem.name}</div>
                                                        </div>
                                                        <div className="status-icon">
                                                            {!subitem.unLocked
                                                                ? <i className='fa fa-lock' />
                                                                : subitem.isCompleted && "" //<i className='fa fa-check success' />
                                                            }
                                                        </div>

                                                    </NavLink>
                                                ))}
                                            </NavDropdown>
                                        </>
                                        :
                                        <NavLink
                                            to={item.link}
                                            className={item.unLocked ? 'link' : 'link disabled'}
                                            onClick={(e) => { handleClick(e, item.name); setActiveMenu("") }}
                                            title={!item.unLocked ? item.helpText : ""}
                                        >
                                            <div className="s-menu-icon-title" title={!item.unLocked ? item.helpText : ''}>
                                                <i className={item.icon} />
                                                <div className="menu-text">{item.name}</div>
                                            </div>
                                            <div className="status-icon">
                                                {!item.unLocked && <i className='fa fa-lock' />}
                                                {/* {item.isCompleted && <i className='fa fa-check' />} */}
                                            </div>
                                        </NavLink>
                                    }




                                </li>}

                            {item.type === "divider" &&
                                <li key={index} className="menu-divider" />
                            }
                        </>

                    ))}
                </ul>
            </div>
        </div >
    );
};

export default AdminSidebar;