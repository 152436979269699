import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import "./AdminLayout.css";
import AdminHeader from "./components/Layout/Header";
import AdminSidebar from "./components/Layout/Sidebar";

import UserContext from "./UserContext";

const AdminLayout = (props) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [sidebarMenu, setSidebarMenu] = React.useState([]);
  const [isLocked, setIsLocked] = React.useState(false);

  const toggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("collapsed");
  };

  useEffect(() => {
    const link = window.location.pathname;
    if (sidebarMenu.length > 0) {
      let menu = sidebarMenu.find(x => link.includes(x.link));

      if (menu && menu.submenu) {
        let submenu = menu.submenu.find(mItem => mItem.link === link);
        setIsLocked(submenu ? submenu.unLocked : false);
      } else {
        setIsLocked(menu.unLocked ? menu.unLocked : false);
      }
    }
  }, [sidebarMenu]);


  return (
    <div className="admin-layout">
      <AdminSidebar setMenuData={setSidebarMenu} />
      <div className="main-content">
        <AdminHeader offeringList={user.offeringList} toggleSidebar={toggleSidebar} />
        <div className="admin-content">
          {!isLocked
            ? <>
              <div className="admin-content-lock">
                <div className="admin-content-lock-text">
                  <h1 className='mb-2'>Locked</h1>
                  <h3 className="mb-3"><i className='fa fa-lock' /> Page is locked.</h3>
                  <h5 className='text'>You need to complete the steps require for the page access.</h5>
                </div>
              </div>
              {/* <div className="error-page">
                <div className="error-box">
                  <h1>Locked</h1>
                  <h3><i className="fa fa-lock" /> Oops! Page locked</h3>
                  <p>You need to complete the steps require for the page access.</p>
                  {/* <NavLink to='dashboard' className={'link'} >Go to Home</NavLink> 
                </div>
              </div> */}
            </>
            : <Outlet />
          }
        </div>
      </div>
    </div>
  );
};


export default AdminLayout;
