import React, { useEffect, useState } from "react";
import './login.css';
import { useNavigate } from "react-router-dom";
import { IAMAxios } from "../../API";

const Login = (props) => {
    let navigate = useNavigate();
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");
    const [userTokenData, setUserTokenData] = useState({});
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('usertokendata'));
        if (items) {
            setUserTokenData(items);
        }
    }, []);

    useEffect(() => {
        if (userTokenData && userTokenData?.account && userTokenData?.account?.username) {
            setUserEmail(userTokenData?.account?.username);
        }
    }, [userTokenData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        IAMAxios.get("/adminuser/getadminuser/" + userEmail)
            .then(function (response) {
                if (response.data) {
                    props.setUser({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        roleId: response.data.roleId,
                        email: response.data.email,
                        adminId: response.data.id
                    });

                    navigate("/offering-list");

                    localStorage.setItem("user", JSON.stringify({
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        roleId: response.data.roleId,
                        email: response.data.email,
                        adminId: response.data.id
                    }));
                }
                else {
                    setError("You don't have access admin portal, Please contact program manager.");

                }
            })
            .catch(function (error) {
                console.log(error);
            });
        // if (userEmail === "admin@deloitte.com" && password === "admin") {
        //     props.setUser({
        //         firstName: "Admin",
        //         lastName: "User",
        //         roleId: 3
        //     });
        //     navigate("/dashboard");
        //     localStorage.setItem("user", JSON.stringify({
        //         firstName: "Admin",
        //         lastName: "User",
        //         roleId: 3
        //     }));

        // } else {
        //     setError("You don't have access admin portal, Please contact program manager.");
        // }
    }


    return (
        <div className="login-page">
            <form onSubmit={handleSubmit}>
                <h2 className="mb-3 text-center">Admin Login</h2>
                <div className="form-group">
                    <label htmlFor="username">Email </label>
                    <input
                        type="email"
                        className="form-control"
                        id="username"
                        placeholder="Enter email"
                        defaultValue={userEmail}
                        onChange={(e) => setUserEmail(e.target.value.trim())}
                        required
                    />
                </div>
                {/* <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                        required />
                </div> */}
                <div className="text-center">
                    <p className="text-danger ">{error}</p>
                </div>
                <button type="submit" className="btn btn-primary btn-block ">Login</button>

            </form>
        </div>
    )
}

export default Login;