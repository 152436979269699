import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const MissionMetadata = () => {
    const user = useContext(UserContext);
    const [key, setKey] = React.useState();
    const [missionList, setMissionList] = React.useState([]);
    const [metaData, setMetaData] = React.useState([]);
    const [missionMetadata, setMissionMetadata] = React.useState([]);


    useEffect(() => {
        getMissionsByOffering();
    }, []);


    useEffect(() => {
        let missionMetadata = [];
        missionList.forEach(m => {
            let mission = {
                id: m.id, name: m.missionName,
                metadata: metaData.filter(mt => mt.missionId === m.id)
            };
            console.log(mission);
            missionMetadata.push(mission);

        });
        setMissionMetadata(missionMetadata);

    }, [missionList, metaData]);

    const getMissionsByOffering = () => {
        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then((res) => {
                setMissionList(res.data);
                missionMetadata && missionMetadata[0] && setKey(missionMetadata[0].id);
            })
            .catch((err) => {
                console.log(err);
            });

        ProgramSetupAxios.get("/OfferingMetadata/getofferingmetadata/" + user.selectedOffering)
            .then((res) => {
                setMetaData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSave = (e, item) => {
        e.preventDefault();
        const data = item.metadata;
        ProgramSetupAxios.put("/OfferingMetadata/updateofferingmetadata", data)
            .then((res) => {
                console.log(res);
                user.createToast({
                    title: "Meta Data Updated",
                    message: "Mission Meta Data Updated Successfully",
                    variant: "success"
                });
            })
            .catch((err) => {
                console.log(err);
                user.createToast({
                    title: "Meta Data Update Failed",
                    message: "Mission Meta Data Update Failed",
                    variant: "danger"
                });
            });

    };

    return (
        <>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {missionMetadata?.map((tab, mIndex) => (
                    <Tab key={"tab-" + tab.id} eventKey={tab.id} title={tab.name}>
                        <form onSubmit={(e) => handleSave(e, tab)}>
                            <div className="page-header mb-3">
                                <h5 className="m-0">Mission Metadata</h5>
                                <div className="page-header-actions">
                                    <Button type="submit" variant="primary" size="sm">
                                        <i className="fa fa-save me-2"></i>Save
                                    </Button>
                                </div>
                            </div>

                            {tab.metadata.map((meta, index) => (
                                <Card className="mb-2" key={"card-" + meta.id}>
                                    <Card.Header>Meta key: {meta.metakey}</Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col">
                                                <div className="d-flex align-items-center">
                                                    <label htmlFor="metaName" className="program-label">Meta Name</label>
                                                    <input type="text" className="form-control" name="metaName" id="metaName" placeholder="Meta Name"
                                                        value={meta.metaName}
                                                        onChange={(e) => {
                                                            let newMetadata = [...missionMetadata];
                                                            newMetadata[mIndex].metadata[index].metaName = e.target.value;
                                                            setMissionMetadata(newMetadata);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <div className="d-flex ">
                                                    <label htmlFor="metaData" className="program-label">Meta Data</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={meta.metaValue}
                                                        config={{
                                                            placeholder: "Enter here",
                                                            toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
                                                        }}
                                                        onReady={editor => {
                                                            editor.editing.view.change(writer => {
                                                                writer.setStyle("min-height", "100px", editor.editing.view.document.getRoot());
                                                            });
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            let newMetadata = [...missionMetadata];
                                                            newMetadata[mIndex].metadata[index].metaValue = data;
                                                            setMissionMetadata(newMetadata);

                                                        }}
                                                    />
                                                    {/* <textarea rows={4} className="form-control" name="metaData" id="metaData" placeholder="Meta Data"
                                                    value={meta.value}
                                                    required
                                                /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </form>
                    </Tab>
                ))}
            </Tabs >

        </>
    );

}

export default MissionMetadata;