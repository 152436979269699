import React, { useContext, useEffect, useState } from "react";
import Stages from "./Stages";
import { ProgramSetupAxios } from "../../../API";
import { Button, Form, FormCheck, Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import UserContext from "../../../UserContext";

const StageSetup = (props) => {
    const user = useContext(UserContext);
    //const [missionData, setMissionData] = useState([]);
    const [stageData, setStageData] = useState([]);
    const [showMissionUpdateModal, setShowMissionUpdateModal] = useState(false);
    const [allStageData, setAllStageData] = useState([])
    const [sameAs, setSameAs] = useState(props.mission?.sameAs)
    const [activeKey, setActiveKey] = useState(1)
    const [isSort, setIsSort] = useState(false);


    useEffect(() => {
        //getMissionByOfferingId();
        getStagesByOfferingId();
    }, []);


    const getStagesByOfferingId = () => {

        ProgramSetupAxios.get("OfferingStage/getstagedetailsbyofferingid/" + user.selectedOffering)
            .then(function (response) {
                setStageData(response.data.filter(x => x.missionId == props.mission.id));
                setAllStageData(response.data);
                setIsSort(false);
                if (response.data.length > 0) {
                    let updatedMenuStatus = [
                        { link: '/outline/stages', isCompleted: true, unLocked: true },
                        { link: '/content/links', unLocked: true },
                        { link: '/content/thought-catalog', unLocked: true },
                        { link: '/content/meta-data', unLocked: true },
                        { link: '/settings/gamification', unLocked: true },
                        { link: '/settings/rating-scales', unLocked: true },
                        { link: '/settings/tokens', unLocked: true },
                        { link: '/settings/email-template', unLocked: true },
                        { link: '/schedule', unLocked: true },
                        { link: '/registrations', unLocked: true }
                    ];
                    user.setMenuStatus(updatedMenuStatus)
                }
            })
            .catch(function (error) {
                console.log(error);
                setIsSort(false);
            });
    };

    const addStage = () => {
        let temp = {
            id: 0,
            offeringId: user.selectedOffering,
            missionId: "",
            stageName: "",
            shortDescription: "",
            longDescription: null,
            sequence: stageData.length + 1,
            imagePath: "",
            startDate: null,
            endDate: null,
            timezone: "",
            activities: [{
                id: 0,
                activityName: "",
                offeringId: user.selectedOffering,
                missionId: props.mission.id,
                stageId: 0,
                missionName: props.mission.missionName,
                activityType: 0,
                shortDescription: null,
                longDescription: "[{\"status\":0, \"text\":\"\"}]",
                sequence: 1,
                imagePath: "",
                startDate: null,
                endDate: null,
                timezone: "",
                isTeamActivity: false,
                isCoachOnlyActivity: false,
                isLearnerOnlyActivity: false,
                isAutoComplete: false,
                open: true
            }]
        };
        let tempStageData = JSON.parse(JSON.stringify(stageData));
        tempStageData.push(temp);
        setStageData(tempStageData);
    };


    const handleChange = (index, data) => {
        let tempData = JSON.parse(JSON.stringify(stageData));

        tempData[index] = data;
        setStageData(tempData);
    };

    const activityChange = (parentIndex, index, data) => {
        let tempData = JSON.parse(JSON.stringify(stageData));
        tempData[parentIndex].activities[index] = data;
        setStageData(tempData);
    }

    const addActivity = (item) => {
        setStageData(item);
    }

    const updateMission = () => {
        let data = {
            id: props.mission.id,
            missionName: props.mission.missionName,
            shortDescription: props.mission.shortDescription,
            longDescription: props.mission.longDescription,
            additionalInfo: props.mission.additionalInfo,
            sequence: props.mission.sequence,
            imagePath: props.mission.imagePath,
            coachMaxSeats: props.mission.coachMaxSeats,
            learnerMaxSeats: props.mission.learnerMaxSeats,
            journeyVideo: props.mission.journeyVideo,
            sameAs: props.mission.sameAs,
        };

        ProgramSetupAxios.put("/OfferingMission/updatemissiondata", data)
            .then((response) => {
                if (props.mission.sameAs != 0) {
                    deletePreviousStageData(props.mission.sameAs, props.mission.id)
                } else {
                    props.getMissionByOfferingId();
                }
                setSameAs(props.mission.sameAs);
                setShowMissionUpdateModal(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const deletePreviousStageData = (sameAsId, newMissionId) => {
        let stageDataToDelete = [];
        var tempStageData = allStageData.filter(x => x.missionId == props.mission.id);
        if (tempStageData == undefined) {
            cloneMissionStageData(sameAsId, newMissionId);
            return;
        }
        tempStageData.forEach(element => {
            stageDataToDelete.push(element.id);
        });

        ProgramSetupAxios.post("/OfferingStage/deleteofferingstages", stageDataToDelete)
            .then((response) => {
                cloneMissionStageData(sameAsId, newMissionId);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const cloneMissionStageData = (sameAsId, newMissionId) => {
        // delete previous 
        let stageDataToUpdate = [];
        var tempStageData = allStageData.filter(x => x.missionId == sameAsId);

        tempStageData.forEach(element => {
            element.id = 0;
            element.missionId = newMissionId;
            element.imagePath = "";

            element.activities.forEach(activity => {
                activity.missionId = newMissionId;
                activity.missionName = props.mission.missionName;
                activity.id = 0;
            });
            stageDataToUpdate.push(element);
        });

        ProgramSetupAxios.post("/OfferingStage/createofferingstagewithactivities", stageDataToUpdate)
            .then((response) => {
                props.getMissionByOfferingId();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const toogleAccordion = (key) => {

        if (key === activeKey) {
            setActiveKey(0)
        }
        else {
            setActiveKey(key);
        }
    }
    return (<>
        <div className="page-header">
            <h1>Stages & Activities</h1>
            <Button onClick={() => { addStage(); setActiveKey(stageData.length + 1) }} className="btn-sm" disabled={props.mission && ((props.mission?.sameAs == 0) || (props.mission.id == props.missionOne.id)) && !user.isPublished ? false : true} >
                + Add Stage
            </Button>
        </div>
        <div className="mission-container">
            {props.mission && ((sameAs == 0) || (props.mission.id == props.missionOne.id)) ?
                <Accordion activeKey={activeKey} >
                    {stageData?.length > 0 && stageData.sort((a, b) => a.sequence - b.sequence).map((stage, index) => {
                        let disableNext = false;
                        if (stageData[index + 1]?.id === 0) {
                            disableNext = true;
                        }
                        return <Stages setActiveKey={() => toogleAccordion(index + 1)} item={stage} key={"stageId" + stage.stageName + index} index={index} setStageData={handleChange} getStagesByOfferingId={getStagesByOfferingId} selectedOffering={user.selectedOffering} activityChange={activityChange} addActivity={addActivity} selectedMission={props.mission} stageData={stageData} allStageData={allStageData} missionsData={props.missionData} disableNext={disableNext} setIsSort={setIsSort} isSort={isSort} />;
                    })}
                </Accordion>
                :
                <div className="copy-mission-container">
                    <span className="pe-3">The stages and activities for this mission are same as </span> <Form.Select className="mission-width-name" value={props.mission.sameAs} onChange={(e) => { props.setMissionData(props.index, { ...props.mission, sameAs: e.target.value }); setShowMissionUpdateModal(true) }}>
                        <option value={0}>None</option>
                        {props.missionData?.length > 0 && props.missionData.map((mission) => {
                            if (mission.id !== props.mission.id) {
                                return (<option value={mission.id}>{mission.missionName}</option>);
                            }
                        })
                        }
                    </Form.Select>
                </div>
            }
        </div >
        {/* <div className="add-mission-button" onClick={addStage}>
            + Add Stage</div> */}
        < Modal
            show={showMissionUpdateModal}
            centered
            className="admin-modal"
        >
            <Modal.Header >
                <Modal.Title>Update Mission</Modal.Title>
                <div className="close" onClick={() => { props.setMissionData(props.index, { ...props.mission, sameAs: sameAs }); setShowMissionUpdateModal(false); }}>
                    <span aria-hidden="true">&times;</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to update this mission's save as?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => { props.setMissionData(props.index, { ...props.mission, sameAs: sameAs }); setShowMissionUpdateModal(false); }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={updateMission}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal >
    </>);
}

export default StageSetup;