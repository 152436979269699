import { Tab } from "bootstrap";
import React from "react";
import { Tabs } from "react-bootstrap";
import ActivityFeedback from "../RatingScales/ActivityFeedback";
import Ratings from "./Ratings";


const RatingScales = (props) => {
    const [key, setKey] = React.useState('huddleFeedback');
    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="huddleFeedback" title="Huddle Feedback">
                <ActivityFeedback />
            </Tab>
            <Tab eventKey="solutionFeedback" title="Solution Feedback">
                <Ratings />
            </Tab>
        </Tabs >
    );
}

export default RatingScales;