
import axios from "axios";

export const IAMAxios = axios.create({
    baseURL: process.env.REACT_APP_IAMAPIURL
});

export const ConfigurationAxios = axios.create({
    baseURL: process.env.REACT_APP_CONFIGURATIONAPIURL
});

export const ProgramSetupAxios = axios.create({
    baseURL: process.env.REACT_APP_PROGRAMSETUPAPIURL
});

export const OnboardingAxios = axios.create({
    baseURL: process.env.REACT_APP_ONBOARDINGAPIURL
});

export const NetworkAxios = axios.create({
    baseURL: process.env.REACT_APP_NETWORKAPIURL
});

export const UserActivityAxios = axios.create({
    baseURL: process.env.REACT_APP_USERACTIVITYAPIURL
});
