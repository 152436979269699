import React, { useContext, useEffect } from "react";
import { OnboardingAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import ExportExcel from "../Exports/ExportExcel";

const UserCoupon = (props) => {
    const user = useContext(UserContext);
    const [userCoupon, setUserCoupon] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);

    useEffect(() => {
        OnboardingAxios.get(`/OfferingUserCoupon/getcouponreport/${user.selectedOffering}`)
            .then((response) => {
                setUserCoupon(response.data);
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
            });

    }, [user.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [userCoupon]);

    const generateTableData = () => {

        let columns = [

            {
                label: "Coupon Name",
                field: 'couponName',
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Id', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "User Name",
                field: 'userName',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "User Email",
                field: 'userEmail',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Team Name",
                field: 'teamName',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Notes",
                field: 'notes',
                attributes: { 'aria-controls': 'DataTable' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Awarded User Name",
                field: 'awardedUserName',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Awarded User Email",
                field: 'awardedUserEmail',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            }
        ];

        let rows = [];
        userCoupon.forEach((coupon, index) => {
            let row = {};
            row.id = { value: index, label: index };
            row.couponName = { value: coupon.couponName, label: coupon.couponName, export: coupon.couponName };
            row.userName = { value: coupon.userName, label: coupon.userName, export: coupon.userName };
            row.userEmail = { value: coupon.email, label: coupon.email, export: coupon.email };
            row.teamName = { value: coupon.teamName, label: coupon.teamName, export: coupon.teamName };
            row.notes = { value: coupon.notes ? coupon.notes : "", label: coupon.notes ? coupon.notes : "", export: coupon.notes ? coupon.notes : "" };
            row.awardedUserName = { value: coupon.awardedUserName, label: coupon.awardedUserName, export: coupon.awardedUserName };
            row.awardedUserEmail = { value: coupon.awardedUserEmail, label: coupon.awardedUserEmail, export: coupon.awardedUserEmail };
            rows.push(row);
        });

        setTableData({ columns, rows });
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>User Token Report</h4>
                <div className="page-actions">
                    <ExportExcel jsonObject={filterData} columns={tableData.columns} fileName="User_Token_report.csv" />
                </div>
            </div>
            <div className="data-table">
                <DataTable loaderShow={isLoading} {...tableData} multiSelect={false} setFilterData={setFilterData} />
            </div>
        </>
    );
}

export default UserCoupon;