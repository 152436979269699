import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { OnboardingAxios, ProgramSetupAxios, UserActivityAxios } from "../API";
import { useEffect } from "react";
import UserContext from "../UserContext";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AdminDashboard = () => {
    const user = useContext(UserContext);

    const [dataSummary, setDataSummary] = useState({
        missions: [],
        usage: [
            {
                date: new Date(),
                learnerCount: 0,
                coachCount: 0
            },
            {
                date: new Date(new Date().setDate(new Date().getDate() - 1)),
                learnerCount: 0,
                coachCount: 0
            },
            {
                date: new Date(new Date().setDate(new Date().getDate() - 2)),
                learnerCount: 0,
                coachCount: 0
            },
            {
                date: new Date(new Date().setDate(new Date().getDate() - 3)),
                learnerCount: 0,
                coachCount: 0
            },
            {
                date: new Date(new Date().setDate(new Date().getDate() - 4)),
                learnerCount: 0,
                coachCount: 0
            },
        ]
    });

    const [teamLeaderboard, setTeamLeaderboard] = useState([]);
    const [learnerLeaderboard, setLearnerLeaderboard] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        console.log("Getting data summary", dataSummary);
    }, [dataSummary]);

    useEffect(() => {
        if (user && user.isPublished) {
            let updatedMenuStatus = [{ link: '/publish', unLocked: true }];
            user.setMenuStatus(updatedMenuStatus)
        }
    }, []);

    useEffect(() => {
        let localData = { missions: [], usage: [] };
        ProgramSetupAxios.get('/EligiblePool/registrationstatus/' + user.selectedOffering)
            .then((response) => {
                let data = response.data;
                localData = {
                    ...localData,
                    ...data
                };
                setDataSummary(localData);
            })
            .catch((error) => {
                console.log(error);
            });

        UserActivityAxios.get('/UserActivityLog/getdailyusage/' + user.selectedOffering)
            .then((response) => {
                let data = response.data;
                localData = {
                    ...localData,
                    usage: data
                };
                setDataSummary(localData);
            })
            .catch((error) => {
                console.log(error);
            });


        OnboardingAxios.get("/Team?includeConclaves=false&onlyConclaves=false")
            .then(function (response) {
                setTeams(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [user]);

    useEffect(() => {
        let offeringTeam = teams.find(team => team.offeringId === user.selectedOffering);
        console.log(offeringTeam);

        if (offeringTeam) {
            UserActivityAxios.get('Leaderboard/getleaderboard/' + user.selectedOffering + '/0/' + offeringTeam.id)
                .then(function (response) {
                    // if response code is 204 then no data found
                    if (response.status === 200) {
                        let data = response.data;
                        setTeamLeaderboard(data.programTopTeams);
                        setLearnerLeaderboard(data.programTopUsers);
                        // dispatch(leaderboardSlice.actions.setLeaderboard(response.data));
                    }
                })
                .catch(function (error) { console.log(error); });
        }
    }, [teams]);

    const dateFormat = (date) => {
        const d = new Date(date);
        const month = d.toLocaleString('default', { month: 'short' });
        const day = d.getDate();
        return `${month} ${day}`;
    }

    return (
        <>
            <div className="page-header mb-4">
                <h1>Admin Dashboard</h1>
                <div className="page-actions">
                    {/* <Button variant="outline-primary" size="sm"><i className="fa fa-th" /></Button>
                    <Button variant="outline-primary" size="sm"><i className="fa fa-bar-chart" /></Button> */}
                </div>
            </div>
            <div className="row dashboard-graph">
                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header title">Participant Registrations</div>
                        <div className="card-body">
                            <Bar
                                options={{
                                    indexAxis: 'y',
                                    elements: { bar: { borderWidth: 1, }, },
                                    responsive: true,
                                    barThickness: 30,
                                    plugins: {
                                        legend: { position: 'bottom', },
                                        title: { display: false, text: 'Coach Registrations', },
                                    },
                                }}
                                data={{
                                    labels: dataSummary.missions.map(mission => mission.missionName),
                                    datasets: [{
                                        label: 'Seats Available',
                                        backgroundColor: 'rgba(102, 169, 197, 0.5)',
                                        borderColor: 'rgb(102, 169, 197)',
                                        data: dataSummary.missions.map(mission => mission.totalLernaerSets),
                                    },
                                    {
                                        label: 'Registered',
                                        backgroundColor: 'rgba(3, 161, 19, 0.5)',
                                        borderColor: 'rgb(3, 161, 19)',
                                        data: dataSummary.missions.map(mission => mission.totalLernaerSetsCompleted),
                                    }]
                                }} />


                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header title">Coach Registrations</div>
                        <div className="card-body">
                            <Bar
                                options={{
                                    indexAxis: 'y',
                                    elements: { bar: { borderWidth: 1, }, },
                                    responsive: true,
                                    barThickness: 30,
                                    plugins: {
                                        legend: { position: 'bottom', },
                                        title: { display: false, text: 'Coach Registrations', },
                                    },
                                }}
                                data={{
                                    labels: dataSummary.missions.map(mission => mission.missionName),
                                    datasets: [{
                                        label: 'Seats Available',
                                        backgroundColor: 'rgba(102, 169, 197, 0.5)',
                                        borderColor: 'rgb(102, 169, 197)',
                                        data: dataSummary.missions.map(mission => mission.totalCoachSets),
                                    },
                                    {
                                        label: 'Registered',
                                        backgroundColor: 'rgba(3, 161, 19, 0.5)',
                                        borderColor: 'rgb(3, 161, 19)',
                                        data: dataSummary.missions.map(mission => mission.totalCoachSetsCompleted),
                                    }]
                                }} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header title">Usage (Last 5 Days)</div>
                        <div className="card-body">
                            <Bar options={{
                                elements: { bar: { borderWidth: 1 } },
                                responsive: true,
                                barThickness: 30,
                                plugins: {
                                    legend: {
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: false,
                                        text: 'Participant Registrations',
                                    },
                                },
                            }}
                                data={{
                                    labels: dataSummary.usage.map(usage => dateFormat(usage.date)),
                                    datasets: [{
                                        label: 'Participants',
                                        backgroundColor: 'rgba(0, 170, 170, 0.5)',
                                        borderColor: 'rgb(0, 170, 170)',
                                        data: dataSummary.usage.map(usage => usage.learnerCount),
                                    },
                                    {
                                        label: 'Coaches',
                                        backgroundColor: 'rgba(245, 154, 35, 0.5)',
                                        borderColor: 'rgb(245, 154, 35)',
                                        data: dataSummary.usage.map(usage => usage.coachCount),
                                    }]
                                }} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">

                </div>
                <div className="col-md-6">
                    <div className="card h-100">
                        <div className="card-header title">Team Leaderboard</div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Team Name</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teamLeaderboard.slice(0, 5).map((team, index) => (
                                            <tr key={team.teamId}>
                                                <td>{team.rank}</td>
                                                <td>{team.name}</td>
                                                <td>{team.points}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {teamLeaderboard.length === 0 && (
                                <div className="no-record">No records found</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card h-100">
                        <div className="card-header title">Individual Leaderboard</div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Participant Name</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Show only five records */}
                                        {learnerLeaderboard.slice(0, 5).map((learner, index) => (
                                            <tr key={learner.userId}>
                                                <td>{learner.rank}</td>
                                                <td>{learner.name}</td>
                                                <td>{learner.points}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {learnerLeaderboard.length === 0 && (
                                <div className="no-record">No records found</div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default AdminDashboard;