import React, { useState, useEffect, useContext } from "react";
import ExportExcel from "../Exports/ExportExcel";
import DataTable from "../DataTable/DataTable";
import { OnboardingAxios, ProgramSetupAxios, UserActivityAxios } from "../../API";
import Moment from "react-moment";
import UserContext from "../../UserContext";

const SkillTrajectory = (props) => {
    const user = useContext(UserContext);
    const [tableData, setTableData] = useState({});
    const [filters, setFilters] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [skillTrajectoryData, setSkillTrajectoryData] = useState([]);
    const [selectedOfferingName, setSelectedOfferingName] = useState();
    const [loaderShow, setLoaderShow] = React.useState(false);

    useEffect(() => {
        setLoaderShow(true);
        if (user.offeringList && user.selectedOffering) {
            var tempName = user.offeringList.filter(x => x.id == user.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getSkillTrajectoryreport();
        }
    }, [user.selectedOffering, user.offeringList]);


    useEffect(() => {
        generateTableData();
    }, [skillTrajectoryData]);

    const getSkillTrajectoryreport = () => {

        UserActivityAxios.get("/OfferingUserActivity/getskilltrajectoryreport/" + user.selectedOffering)
            .then(function (response) {
                if (response && response.data && response.data.length > 0) {
                    response.data.forEach((item, index, originalArr) => {
                        if (item.coachFeedback.indexOf('{') > -1) {
                            if (item.coachFeedback.trim().length == 0) {
                                originalArr[index]['feedbackText'] = "";
                                originalArr[index]['connect'] = "";
                                originalArr[index]['own'] = "";
                                originalArr[index]['solve'] = "";
                                originalArr[index]['communicate'] = "";
                            }
                            else {
                                let summary = JSON.parse(item.coachFeedback);
                                originalArr[index]['feedbackText'] = summary.note;
                                if (summary && summary.feedback.length > 0 && item.activityName == "Discover your skill trajectory") {
                                    originalArr[index]['connect'] = summary.feedback[0] != undefined ? parseFloat(summary.feedback[0]['Value']).toFixed(2) : 0;
                                    originalArr[index]['own'] = summary.feedback[1] != undefined ? parseFloat(summary.feedback[1]['Value']).toFixed(2) : 0;
                                    originalArr[index]['solve'] = summary.feedback[2] != undefined ? parseFloat(summary.feedback[2]['Value']).toFixed(2) : 0;
                                    originalArr[index]['communicate'] = summary.feedback[3] != undefined ? parseFloat(summary.feedback[3]['Value']).toFixed(2) : 0;
                                }
                                else if (summary && summary.feedback.length > 0) {
                                    originalArr[index]['connect'] = summary.feedback[0]['feedback'] != undefined ? parseFloat(summary.feedback[0]['feedback']['value']).toFixed(2) : 0;
                                    originalArr[index]['own'] = summary.feedback[1]['feedback'] != undefined ? parseFloat(summary.feedback[1]['feedback']['value']).toFixed(2) : 0;
                                    originalArr[index]['solve'] = summary.feedback[2]['feedback'] != undefined ? parseFloat(summary.feedback[2]['feedback']['value']).toFixed(2) : 0;
                                    originalArr[index]['communicate'] = summary.feedback[3]['feedback'] != undefined ? parseFloat(summary.feedback[3]['feedback']['value']).toFixed(2) : 0;
                                }
                            }
                        }
                    })
                    setSkillTrajectoryData(response.data);
                }
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

    }

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            // {
            //     label: "Role",
            //     field: 'role',
            //     attributes: {
            //         'aria-controls': 'DataTable',
            //         'aria-label': 'Role',
            //         className: 'text-center',
            //     },
            //     sort: true,
            //     filter: true,
            //     filterType: 'number',
            // },
            {
                label: "Team Name",
                field: 'team',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Learner Name",
                field: 'learnerName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Learner Name',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Learner Email",
                field: 'learnerEmail',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Learner Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Mission Name",
                field: 'missionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Activity Name",
                field: 'activityName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Activity Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Connect",
                field: 'connect',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Connect',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Own",
                field: 'own',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Own',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Solve",
                field: 'solve',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Solve',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Communicate",
                field: 'communicate',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Communicate',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Coach Feedback Text",
                field: 'note',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Coach Feedback Text',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
        ];
        let rows = [];
        skillTrajectoryData.forEach((skillTrajectory) => {
            let row = {};
            let offeringName = selectedOfferingName;

            row.id = { value: skillTrajectory.id, label: skillTrajectory.id, export: skillTrajectory.id };
            //  row.role = { value: skillTrajectory.role, label: skillTrajectory.role, export: role };
            row.team = { value: skillTrajectory.teamName, label: skillTrajectory.teamName, export: skillTrajectory.teamName };
            row.learnerName = { value: skillTrajectory.learnerName, label: skillTrajectory.learnerName, export: skillTrajectory.learnerName };
            row.learnerEmail = { value: skillTrajectory.learnerEmail, label: skillTrajectory.learnerEmail, export: skillTrajectory.learnerEmail };
            row.activityName = { value: skillTrajectory.activityName, label: skillTrajectory.activityName, export: skillTrajectory.activityName };
            row.missionName = {
                value: skillTrajectory.missionName,
                label: skillTrajectory.missionName,
                export: skillTrajectory.missionName
            };
            row.connect = { value: skillTrajectory.connect, label: skillTrajectory.connect, export: skillTrajectory.connect };
            row.own = { value: skillTrajectory.own, label: skillTrajectory.own, export: skillTrajectory.own };
            row.solve = { value: skillTrajectory.solve, label: skillTrajectory.solve, export: skillTrajectory.solve };
            row.communicate = { value: skillTrajectory.communicate, label: skillTrajectory.communicate, export: skillTrajectory.communicate };
            row.note = { value: skillTrajectory.feedbackText, label: skillTrajectory.feedbackText, export: skillTrajectory.feedbackText };

            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    return (<>
        <div className="page-header mb-4">
            <h4>Skill Trajectory Report</h4>
            <div className="page-actions">
                <ExportExcel jsonObject={filterData} columns={tableData.columns} fileName="SkillTrajectory.csv" />
            </div>
        </div>
        <div className="data-table">
            <DataTable loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
        </div>
    </>);
}

export default SkillTrajectory;