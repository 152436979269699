import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FileInput from "../Inpsuts/FileInput";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { IAMAxios, ProgramSetupAxios } from "../../API";

const UploadData = (props) => {
  const [show, setShow] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [filename, setFilename] = React.useState("Choose File");
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [insertCount, setInsertCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [notInsertCount, setNotInsertCount] = useState(0);
  const [errorRecordList, setErrorRecordList] = useState([])

  useEffect(() => {
    setImportInprogress(false);
    if (file) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [file]);

  const onChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
    }
  };

  const errorExcel = (errorList) => {
    let ws = XLSX.utils.json_to_sheet(errorList);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, 'ErrorList.xlsx');

    // var hiddenElement = document.createElement('a');
    // hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    // hiddenElement.target = '_blank';
    // hiddenElement.download = 'ErrorList.csv';
    // hiddenElement.click();

  }
  const [importInprogress, setImportInprogress] = useState(false);
  const uploadFile = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    const formData = new FormData();
    formData.append("file", file);
    IAMAxios.post("/SAPUser/importsapdata", formData)
      .then((response) => {
        setImportInprogress(true)
        setDisableSubmit(false);
        console.log("File upload ", response);
        setUploadSuccess(true);
        setFile(null);
        setFilename("Choose File"); // reset filename
        // setShow(false);
        setInsertCount(response.data.insertCount);
        setUpdateCount(response.data.updateCount);
        setNotInsertCount(response.data.notInsertedCount);
        if (Object.keys(response.data.errorList).length > 0) {
          setErrorRecordList([response.data.errorList])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <div>
      <Button onClick={() => { setShow(true); setUploadSuccess(false) }} className='btn-sm' disabled={props.disabled}>
        <i className="fa fa-upload me-2" />
        {props.title || "Import Data"}
      </Button>
      <Modal show={show}
        dialogClassName="upload-modal"
        centered>
        <form onSubmit={uploadFile}>
          <Modal.Header>
            <Modal.Title>{props.title || "Import Data"}</Modal.Title>
            <div className="close" onClick={() => { setShow(false); props.getSAPUsers(); }}>
              <span aria-hidden="true">&times;</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              <FileInput
                required={true}
                label="Select File"
                filename={filename}
                onChange={onChange}
              />
            </div>
            {uploadSuccess && (<>
              {/* <div className="alert alert-success">
                File Uploaded Successfully
              </div> */}
              <div className="alert alert-success">
                <div>Insert Count : {insertCount}</div>
                <div>Update Count : {updateCount}</div>
                <div>Not Inserted Count : {notInsertCount}</div>
                {errorRecordList.length > 0 &&
                  <a href="" onClick={() => errorExcel(errorRecordList)}>Download Error List</a>
                }
              </div>
            </>
            )}


          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-text"
              variant="link"
              onClick={() => { setShow(false); props.getSAPUsers(); }}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={disableSubmit}>
              Upload
            </Button>
            {importInprogress && <div><span>The import is in progress, you can close this popup and check in the registration table.</span></div>}
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UploadData;
