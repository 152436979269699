import React, { useContext, useEffect } from "react";
import { UserActivityAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import ExportExcel from "../Exports/ExportExcel";

const CoachProgress = (props) => {
    const user = useContext(UserContext);
    const [coachProgress, setCoachProgress] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [today, setToday] = React.useState(new Date());

    useEffect(() => {
        UserActivityAxios.get(`/OfferingUserActivity/getcoachprogress/${user.selectedOffering}`)
            .then((response) => {
                setCoachProgress(response.data);
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
            });

    }, [user.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [coachProgress]);

    const generateTableData = () => {

        let columns = [
            {
                label: "Coach Name",
                field: 'coachName',
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Id', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Coach Email",
                field: 'coachEmail',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Team",
                field: 'teamName',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Mission",
                field: 'missionName',
                attributes: { 'aria-controls': 'DataTable', },
                filter: true,
                filterType: 'text',
            },
            // ...coachProgress.length > 0 && coachProgress[0].activities ? coachProgress[0].activities.map((activity, index) => {
            //     return {
            //         label: activity.activityName,
            //         field: `activity${index}`,
            //         attributes: { 'aria-controls': 'DataTable',  },
            //         filter: true,
            //         filterType: 'select',
            //         filterList: [
            //             { value: '', label: 'All' },
            //             { value: true, label: 'Yes' },
            //             { value: false, label: 'No' },
            //         ],
            //     }
            // }) : [],

            {
                label: "Is Soluton Submitted?",
                field: 'solutionSubmitted',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                ],
            },
            {
                label: "Pending Feedback",
                field: 'feedbackPending',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Given Feedback",
                field: 'feedbackGiven',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Team Solution Rating",
                field: 'solutionRating',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Total Solutions Rated",
                field: 'solutionRatingGiven',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Given Review",
                field: 'reviewGiven',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Pending Review",
                field: 'reviewPending',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: true,
                filterType: 'text',
            },
        ];

        let rows = [];
        coachProgress.forEach((coach) => {
            let row = {};

            row.id = { value: coach.id, label: coach.id, export: coach.id };
            row.coachName = { value: coach.coachName, label: coach.coachName, export: coach.coachName };
            row.coachEmail = { value: coach.coachEmail, label: coach.coachEmail, export: coach.coachEmail };
            row.teamName = { value: coach.teamName, label: coach.teamName, export: coach.teamName };
            row.missionName = { value: coach.missionName, label: coach.missionName, export: coach.missionName };
            // coach.activities.map((activity, index) => {
            //     row[`activity${index}`] = { value: activity.isCompleted, label: activity.isCompleted ? "Yes" : "No", export: activity.isCompleted ? "Yes" : "No" };
            // });
            row.solutionSubmitted = { value: coach.solutionSubmitted, label: coach.solutionSubmitted ? 'Yes' : 'No', export: coach.solutionSubmitted ? 'Yes' : 'No' };
            row.feedbackPending = { value: coach.feedbackPending, label: coach.feedbackPending, export: coach.feedbackPending };
            row.feedbackGiven = { value: coach.feedbackGiven, label: coach.feedbackGiven, export: coach.feedbackGiven };
            // row.solutionRatingPending = { value: coach.solutionRatingPending, label: coach.solutionRatingPending, export: coach.solutionRatingPending };
            row.solutionRating = { value: coach.solutionRating, label: coach.solutionRating, export: coach.solutionRating };
            row.solutionRatingGiven = { value: coach.solutionRatingGiven, label: coach.solutionRatingGiven, export: coach.solutionRatingGiven };
            row.reviewGiven = { value: coach.reviewGiven, label: coach.reviewGiven, export: coach.reviewGiven };
            row.reviewPending = { value: coach.reviewPending, label: coach.reviewPending, export: coach.reviewPending };

            rows.push(row);
        });

        setTableData({ columns, rows });
        // console.log({ columns, rows });
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Coach Progress Report</h4>
                <div className="page-actions">
                    <ExportExcel jsonObject={filterData} columns={tableData.columns} fileName="Coach_progress_report.csv" />
                </div>
            </div>
            <div className="data-table">
                <DataTable loaderShow={isLoading} {...tableData} multiSelect={false} setFilterData={setFilterData} />
            </div>
        </>
    );
}

export default CoachProgress;