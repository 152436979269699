import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { UserActivityAxios } from "../../API";

const ImageUpload = (props) => {

    const [fileUrl, setFileUrl] = useState(props.value);
    const [showLoader, setShowLoader] = useState(false);
    const onChange = (e) => {
        setShowLoader(true)
        var selectedImage = e.target.files[0]
        const formData = new FormData();
        formData.append("file", selectedImage);
        UserActivityAxios.put("/Solution/fileupload", formData)
            .then((response) => {
                console.log(response);
                setFileUrl(response.data);
                props.getFileUrl(response.data)
                setShowLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false)
            });

    }

    return <div className="custom-file image-upload">
        <input
            {...props}
            accept="image/png, image/gif, image/jpeg"
            type="file"
            // className="custom-file-input"
            id={"customFile" + props.inputId}
            onChange={onChange} />
        <label className="custom-file-label" htmlFor={"customFile" + props.inputId}>{showLoader ? <Spinner className="file-upload-spinier" size="sm" animation="border" variant="secondary" /> : props.filename || "Choose file"}</label>
    </div>
}

export default ImageUpload