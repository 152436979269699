import React, { useContext, useEffect } from "react";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import ProgramCertificate from "../Exports/ProgramCertificate";

const Certificate = (props) => {
    const user = useContext(UserContext);
    const [usersList, setUsersList] = React.useState([]);
    const [teams, setTeams] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [offeringDate, setOfferingDate] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [showLoading, setShowLoading] = React.useState(true);

    useEffect(() => {
        getUsers();
    }, [user]);

    useEffect(() => {
        generateTableData();
        if (user.selectedOffering) {
            let date = user.offeringList && user.offeringList.find(item => item.id === user.selectedOffering).endDate;

            var d = new Date(date);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            let shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var output = + (day < 10 ? '0' : '') + day + '-' + shortMonths[month - 1] + '-' + d.getFullYear();
            setOfferingDate(output);
        }

    }, [usersList, teams, preferredMission, user.selectedOffering]);


    const getUsers = () => {

        let queryString = `?offeringId=` + user.selectedOffering;
        // 5?OfferingId=1&MissionId=6&TeamId=1&FirstName=pre&LastName=0&Email=pre' \
        console.log(filters);
        if (filters.missionName) {
            queryString += `&missionId=${filters.missionName}`;
        }
        if (filters.teamId) {
            queryString += `&teamId=${filters.teamId}`;
        }
        if (filters.firstName) {
            queryString += `&firstName=${filters.firstName}`;
        }
        if (filters.lastName) {
            queryString += `&lastName=${filters.lastName}`;
        }

        if (filters.email) {
            queryString += `&email=${filters.email}`;
        }
        if (filters.solutionSubmitted) {
            queryString += `&solutionSubmitted=${filters.solutionSubmitted}`;
        }
        if (filters.avgCompletionrange && filters.avgCompletionrange.min) {
            queryString += `&minCompletion=${filters.avgCompletionrange.min}`;
        }
        if (filters.avgCompletionrange && filters.avgCompletionrange.max) {
            queryString += `&maxCompletion=${filters.avgCompletionrange.max}`;
        }

        OnboardingAxios.get("/OfferingUsers/getofferingusercertificate/" + user.selectedOffering + '/' + pageNo + '/' + pageSize + queryString)
            .then(function (response) {
                setUsersList(response.data || []);
                setShowLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setPreferredMission(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        getTeams();
    };

    const getTeams = () => {
        OnboardingAxios.get("/Team?includeConclaves=false&onlyConclaves=false")
            .then(function (response) {
                setTeams(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const generateTableData = () => {
        let columns = [
            {
                label: "Name",
                field: 'name',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },

                filter: true,
                filterType: 'text',
            },
            {
                label: "Email",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },

                filter: true,
                filterType: 'text',

            },

            {
                label: "Mission",
                field: 'missionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission',
                },

                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Team",
                field: 'teamId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team',
                },

                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...teams.filter(obj => obj.isConclave === false)
                        .map((x) => {
                            return { value: x.id, label: x.teamName };
                        }),
                    { value: 0, label: 'Blank' },
                ]
            },
            {
                label: "Solution Submitted?",
                field: 'solutionSubmitted',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Solution',
                },

                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                ]
            },
            {
                label: "Completion %",
                field: 'avgCompletion',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Progress',
                    'className': 'progress-input text-center',
                },

                filter: true,
                filterType: 'number-range',
            },

            {
                label: "Certificate",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action',
                }
            },

        ];
        let rows = [];

        usersList.forEach((user) => {
            let avg = parseInt(user.avgCompletion);
            let row = {};
            let mission = preferredMission.find(x => x.id === user.missionId);
            let team = teams.find(x => x.id === user.teamId);
            let conclave = teams.find(x => x.id === user.conclaveId);

            row.id = { value: user.id, label: user.id, export: user.id };
            row.name = { value: user.firstName + " " + user.lastName, label: user.firstName + " " + user.lastName, export: user.firstName + " " + user.lastName };
            row.userId = { value: user.userId ? true : false, label: user.userId ? 'Yes' : 'No', export: user.userId ? 'Yes' : 'No' };
            row.email = { value: user.email, label: user.email, export: user.email };
            row.missionName = {
                value: user.missionId,
                label: mission ? mission.missionName : 'All', export: mission && mission.missionName
            };
            row.teamId = {
                value: user.teamId,
                label: team ? team.teamName : 'All', export: team && team.teamName
            };
            row.conclaveId = {
                value: user.conclaveId,
                label: conclave ? conclave.teamName : 'All', export: conclave && conclave.teamName
            };

            row.solutionSubmitted = { value: user.solutionSubmitted, label: user.solutionSubmitted ? "Yes" : "No", export: user.solutionSubmitted ? "Yes" : "No" };
            row.avgCompletion = {
                value: avg,
                label: avg + '%',
                export: avg
            };
            row.action = {
                value: "", label:
                    <ProgramCertificate
                        userName={user.firstName + " " + user.lastName}
                        programName={user.programName}
                        date={offeringDate}
                        user={user}
                        text="Download"
                    />
            };
            row.selectable = user.solutionSubmitted ? false : true;


            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    return (
        <>
            <div className="page-header mb-4">
                <h1>Certificates Download</h1>
            </div>
            <div className="data-table">
                <DataTable
                    {...tableData}
                    setFilters={setFilters}
                    filters={filters}
                    setFilterData={setFilterData}
                    multiSelect={false}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    setPageNo={setPageNo}
                    setPageSize={setPageSize}
                    totalCount={totalCount}
                    applyFilters={getUsers}
                    loaderShow={showLoading}
                />
            </div>
        </>
    )
}

export default Certificate;