import React from "react";
import { Button } from "react-bootstrap";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "./vfs_fonts";



const ProgramCertificate = (props) => {

    const [userName, setUserName] = React.useState(props.userName || 'NAME SURNAME');
    const [programName, setProgramName] = React.useState(props.programName || 'PROGRAM NAME');
    const [date, setDate] = React.useState(props.date || '31-05-2022');

    const downloadCertificate = () => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        pdfMake.fonts = {
            Calibri: {
                normal: "Calibri-Regular.ttf",
                bold: "Calibri-Bold.ttf",

            },
            CalibriLight: {
                normal: "Calibri-Light.ttf",
            },
            ChakraPetchSemiBold: {
                normal: "ChakraPetch-SemiBold.ttf",
            }
        };

        let docDefinition = {
            pageOrientation: "landscape",
            pageSize: { width: 1365, height: 768 },
            pageMargins: [55, 95, 55, 50],
            defaultStyle: {
                font: "Calibri",
                lineHeight: 1.25,
                fontSize: 10,
                columnGap: 5,
            },

            background: [{ image: 'pagebg' }],
            content: [Certificate()],
            unbreakable: true,
            styles: {
                name: {
                    fontSize: 20,
                    bold: true,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                },
                title: {
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                }
            },

            images: {
                pagebg: 'pagebg.jpg',
                dividerLine: "dividerLine.png",
                globe: "globe.png",
                logo: "logo.png",
                line: "line.png",
                hline: "hline.png",
                signature: "signature.png",
            },

        };


        let fileName = userName + "_" + programName + "Certificate.pdf";
        // pdfMake.createPdf(docDefinition).print();
        pdfMake.createPdf(docDefinition).download(fileName);

    }

    const Certificate = (props) => {
        let result = [];
        result.push({
            table: {
                widths: ['*', 1, 400],
                heights: [600],
                body: [
                    [
                        {
                            stack: [
                                { text: 'CERTIFICATE', fontSize: 50, alignment: 'center', font: 'ChakraPetchSemiBold', characterSpacing: 12, margin: [0, 0, 0, 10] },
                                { text: 'OF  ACHIEVEMENT', fontSize: 25, alignment: 'center', characterSpacing: 10, font: 'CalibriLight', margin: [0, 0, 0, 40] },
                                { text: 'THIS  CERTIFICATE  IS  PRESENTED  TO', fontSize: 20, alignment: 'center', characterSpacing: 4, bold: true, },
                                { image: 'line', width: 350, alignment: 'center', margin: [0, 50, 0, 5] },
                                {
                                    columns: [
                                        { text: userName.toUpperCase(), fontSize: 60, lineHeight: 1, alignment: 'center', characterSpacing: 10, margin: [0, 0, 0, -5] },
                                    ]
                                },
                                { image: 'line', width: 350, alignment: 'center', margin: [0, 0, 0, 50] },
                                {
                                    text: [
                                        { text: 'ON  SUCCESSFULLY  COMPLETING  ', font: 'CalibriLight' },
                                        { text: programName, bold: true, }
                                    ],
                                    characterSpacing: 4,
                                    fontSize: 25, alignment: 'center',
                                    margin: [0, 0, 0, 70]
                                },
                                {
                                    table: {
                                        widths: [400, 400],
                                        heights: ['*'],
                                        body: [
                                            [
                                                { text: date, fontSize: 20, alignment: 'center', margin: [0, 22, 0, 0] },
                                                { image: 'signature', width: 100, alignment: 'center' },
                                            ],
                                            [
                                                { image: 'hline', width: 120, height: 1, alignment: 'center' },
                                                { image: 'hline', width: 120, height: 1, alignment: 'center' },
                                            ],
                                            [
                                                { text: "Date", fontSize: 20, alignment: 'center', font: 'CalibriLight', characterSpacing: 3 },
                                                { text: "Signature", fontSize: 20, alignment: 'center', font: 'CalibriLight', characterSpacing: 3 },
                                            ]
                                        ]
                                    },
                                    layout: 'noBorders',
                                    width: 500,
                                    absolutePosition: { x: 64, y: 600 },
                                },

                            ]
                        },
                        { image: 'dividerLine', height: 570, alignment: 'center' },
                        {
                            table: {
                                widths: ['*'],
                                heights: ['*'],
                                body: [
                                    [
                                        { image: 'logo', width: 200, alignment: 'center', margin: [0, 0, 0, 50] },
                                    ],
                                    [
                                        { image: 'globe', width: 250, alignment: 'center' },
                                    ]
                                ]
                            },
                            alignment: 'center',
                            margin: [0, 100, 0, 0],
                            layout: 'noBorders',
                        },
                    ]
                ]
            },
            layout: 'noBorders',
        });
        return result;
    }

    return (
        <Button variant="success" className="btn-sm" onClick={downloadCertificate}>
            <nobr>
                <i className="fa fa-certificate me-2" aria-hidden="true"></i>
                {props.text}
            </nobr>
        </Button>
    )

}

export default ProgramCertificate;