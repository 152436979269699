import React, { useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import UserContext from "../../UserContext";

const PointAction = (props) => {
    const user = useContext(UserContext);
    const [offeringPointsData, setOfferingPointsData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [editData, setEditData] = React.useState({});
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        setLoaderShow(true);
        getOfferingPointList();
    }, [props.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [offeringPointsData]);


    const getOfferingPointList = () => {
        ProgramSetupAxios.get("/OfferingPoints/getofferingpoints/" + user.selectedOffering)
            .then(function (response) {
                setOfferingPointsData(response.data);
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    };

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'number',
            },
            {
                label: "ActionName",
                field: 'actionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'ActionName',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Minimum Requirement",
                field: 'minRequirement',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Minimum Requirement',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Individual - Experience Points",
                field: 'experiencePoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Individual - Experience Points',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Individual - Bonus Points",
                field: 'bonusPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Individual - Bonus Points',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Team Points",
                field: 'teamPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Points',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: user.isPublished ? 'text-center locked' : 'text-center',
                }
            },
        ];

        let rows = [];
        offeringPointsData.filter(x => x.activityId == 0).forEach((rowItem, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.actionName = { value: rowItem.actionName, label: rowItem.actionName, export: rowItem.actionName };
            row.minRequirement = { value: rowItem.targetValue, label: rowItem.targetValue, export: rowItem.targetValue };
            row.experiencePoints = { value: rowItem.pointsType == 1 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0, label: rowItem.pointsType == 1 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0, export: rowItem.pointsType == 1 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0 };
            row.bonusPoints = { value: rowItem.pointsType == 0 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0, label: rowItem.pointsType == 0 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0, export: rowItem.pointsType == 0 && rowItem.isTeamPoints == false ? rowItem.pointsValue : 0 };
            row.teamPoints = { value: rowItem.isTeamPoints == true ? rowItem.pointsValue : 0, label: rowItem.isTeamPoints == true ? rowItem.pointsValue : 0, export: rowItem.isTeamPoints == true ? rowItem.pointsValue : 0 };
            row.action = {
                value: "", label: <Button onClick={() => { setShow(true); setEditData(rowItem); }
                } variant="outline-primary" size="sm"  >
                    <i className="fa fa-pencil " />
                </Button >
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    const updateActivitiesPoint = (e) => {
        e.preventDefault();
        let data = [
            {
                "id": editData.id,
                "offeringId": user.selectedOffering,
                "actionName": editData.actionName,
                "actionKey": editData.actionKey,
                "targetValue": editData.targetValue,
                "pointsValue": editData.pointsValue,
                "pointsType": editData.pointsType,
                "description": editData.actionName,
                "activityId": editData.activityId,
                "isTeamPoints": editData.isTeamPoints
            }
        ];
        ProgramSetupAxios.post("/OfferingPoints/offeringpoints/", data)
            .then(function (response) {
                getOfferingPointList();
                setShow(false);
                setLoaderShow(true);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Offering Levels</h4>
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} {...tableData} loaderShow={loaderShow} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={updateActivitiesPoint}>
                    <Modal.Header>
                        <Modal.Title>Edit Points</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        {/* {JSON.stringify(props.user)} */}
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="action">Action</label>
                                <input id="action" type="text" value={editData.actionName || ""} maxLength={200} className="form-control" disabled />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="points">Points Type<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={editData.pointsType || ""}
                                        onChange={(e) => { setEditData({ ...editData, pointsType: e.target.value }); }}
                                        required
                                    >
                                        <option value="">Select PointsType</option>
                                        <option value="1">Experience</option>
                                        <option value="2">Bonus</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Minimum Requirement<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={editData.targetValue || 0} className="form-control"
                                    onChange={(e) => { setEditData({ ...editData, targetValue: e.target.value }); }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Points<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={editData.pointsValue || 0} className="form-control"
                                    onChange={(e) => { setEditData({ ...editData, pointsValue: e.target.value }); }} required />
                            </div>
                            <div className="col-6 checkbox-alignment">
                                <input type="checkbox" className="form-check-input me-2" defaultChecked={editData.isTeamPoints} id="checkbox" onChange={(e) => setEditData({ ...editData, isTeamPoints: e.target.checked })} />
                                <label className="form-check-label" htmlFor="checkbox">Team Points?</label>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" >Update</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default PointAction;