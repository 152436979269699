import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";

const Badges = (props) => {
    const user = useContext(UserContext);
    const [offeringLevelList, setOfferingLevelList] = React.useState([]);
    const [pointTypeList, SetPointTypeList] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localLevel, setLocalLevel] = React.useState([]);

    useEffect(() => {
        setLoaderShow(true);
        getPoint();
    }, [user.selectedOffering]);

    useEffect(() => {

        generateTableData();
    }, [offeringLevelList, pointTypeList]);


    const getPoint = () => {

        ProgramSetupAxios.get("/OfferingLevel/getofferinglevelsbyofferingid/" + user.selectedOffering)
            .then(function (response) {
                setOfferingLevelList(response.data);
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

        ProgramSetupAxios.get("/OfferingPointType/getofferingpointtypes/" + user.selectedOffering)
            .then(function (response) {
                SetPointTypeList(response.data.sort((a, b) => a.sequence - b.sequence));
            })
            .catch(function (error) {
                console.log(error);
            });

    };

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'number',
            },
            {
                label: "Level Name",
                field: 'levelName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Level Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',


            },

            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Point Value",
                field: 'pointValue',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Point Value',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Point Type",
                field: 'pointType',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Point Type',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: '-' },
                    ...pointTypeList.map((x) => {
                        return { value: x.typeKey, label: x.name };
                    })
                ]

            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                },

            },
        ];
        let rows = [];
        offeringLevelList.forEach((level, index) => {
            let row = {};
            let pointType = pointTypeList.find(x => x.typeKey === level.pointsType);
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.levelName = { value: level.levelName, label: level.levelName, export: level.levelName };
            row.sequence = { value: level.sequence, label: level.sequence, export: level.sequence };
            row.pointValue = { value: level.pointsValue, label: level.pointsValue, export: level.pointsValue };
            row.pointType = {
                value: level.pointsType,
                label: pointType
                    ? pointType.name
                    : '-',
                export: pointType && pointType.name
            };
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button onClick={() => { setShow(true); setLocalLevel(level); }} variant="outline-primary" size="sm" >
                            <i className="fa fa-pencil " />

                        </Button >
                        {/* <DeleteLink {...level} /> */}
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addLevel = (e) => {
        e.preventDefault();
        ProgramSetupAxios.post(`/OfferingLevel/offeringlevels`, [localLevel])
            .then(() => {
                getPoint();
                setShow(false);
            }).catch(err => {
                console.log("AddLevel: err", err);
            });


    }


    const updateLevel = (e) => {
        e.preventDefault();
        ProgramSetupAxios.put(`/OfferingLevel/updateofferinglevel`, localLevel)
            .then(() => {
                getPoint();
                setShow(false);
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    };

    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = (level) => {

            let data = {
                id: level.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingLevel/deleteofferinglevels", [data.id])
                .then(function () {
                    getPoint();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Level</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this level?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Offering Levels</h4>
                {/* <Button onClick={() => {
                    setShow(true); setLocalLevel({
                        offeringId: user.selectedOffering,
                        levelName: "",
                        levelKey: 0,
                        sequence: 0,
                        pointsValue: 0,
                        pointsType: 0
                    });
                }} className="btn-sm">
                    + Add Level
                </Button> */}
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} {...tableData} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localLevel.id != 0 && localLevel.id ? updateLevel(e) : addLevel(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localLevel.id != 0 && localLevel.id ? "Edit" : "Add"} Level </Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        {/* {JSON.stringify(props.user)} */}
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="levelName">Level Name<span className="required-star">*</span></label>
                                <input type="text" value={localLevel.levelName || ''} maxLength={200} className="form-control" onChange={(e) => {
                                    setLocalLevel({ ...localLevel, levelName: e.target.value });
                                }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Points Value<span className="required-star">*</span></label>
                                <input type="number" value={localLevel.pointsValue || ''} min={0} max={9999} className="form-control"
                                    onChange={(e) => {
                                        setLocalLevel({ ...localLevel, pointsValue: e.target.value });
                                    }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Points Type<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localLevel.pointsType}
                                        onChange={(e) => {
                                            setLocalLevel({ ...localLevel, pointsType: e.target.value });
                                        }}
                                        required>
                                        <option value="">Select points type</option>
                                        {
                                            pointTypeList.map((type, index) => {
                                                return <option key={index} value={type.typeKey} >{type.name}</option>;

                                            })
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="sequence">Sequence<span className="required-star">*</span></label>
                                <input type="number" value={localLevel.sequence || ''} min={0} max={9999} className="form-control" onChange={(e) => {
                                    setLocalLevel({ ...localLevel, sequence: e.target.value });
                                }} required />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" >{localLevel.id != 0 && localLevel.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default Badges;