import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../../API";
import DataTable from "../../DataTable/DataTable";
import { LINK_TYPE } from "../../../shared/Enum";
import UserContext from "../../../UserContext";
import FileInput from "../../Inpsuts/FileInput";
import { uploadFile } from "../../../util";


const Links = (props) => {
    const user = useContext(UserContext);
    const [offeringLinkList, setOfferingLinkList] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localLink, setLocalLink] = React.useState([]);
    const [linkTypeList, setLinkTypeList] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [fileUploadError, setFileUploadError] = React.useState(null);

    const [onboardingLinkTypeList, setOnboardingLinkTypeList] = useState([
        //{ label: "Not Specified", value: LINK_TYPE.notSpecified },
        { label: "Connect", value: LINK_TYPE.connect },
        { label: "Own", value: LINK_TYPE.own },
        { label: "Solve", value: LINK_TYPE.solve },
        { label: "Communicate", value: LINK_TYPE.communicate },
        // { label: "Onboarding Video", value: LINK_TYPE.onboardingVideo },
    ]);
    const [journeyLinkTypeList, setJourneyLinkTypeList] = useState([
        //{ label: "Not Specified", value: LINK_TYPE.notSpecified },
        { label: "Reference Material", value: LINK_TYPE.referenceMaterial },
        // { label: "Journey Video Path", value: LINK_TYPE.videoPath },
        // { label: "Image Path", value: LINK_TYPE.imagePath },
        { label: "Coach Only Reference Material", value: LINK_TYPE.coachOnlyReferenceMaterial }
    ]);
    const [importantLinksLinkTypeList, setImportantLinksLinkTypeList] = useState([
        // { label: "Not Specified", value: LINK_TYPE.notSpecified },
        { label: "Quick Link", value: LINK_TYPE.quickLink }
    ]);
    const [missionList, setMissionList] = useState([]);

    useEffect(() => {
        setLoaderShow(true);
        getMissionByOfferingId();
        getLinks();
    }, [user.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [offeringLinkList, missionList]);

    useEffect(() => {
        if (props.group === "onboarding") {
            setLinkTypeList(onboardingLinkTypeList);
        } else if (props.group === "journey") {
            setLinkTypeList(journeyLinkTypeList);
        } else if (props.group === "importantLinks") {
            setLinkTypeList(importantLinksLinkTypeList);
        }
    }, [props.group])


    const getLinks = () => {
        ProgramSetupAxios.get("/OfferingLink/getofferinglinks/" + user.selectedOffering)
            .then(function (response) {
                // setOfferingLinkList(response.data);
                if (response.data != "") {
                    if (props.group === "onboarding") {
                        setOfferingLinkList(response.data.filter(x => x.linkType == LINK_TYPE.connect || x.linkType == LINK_TYPE.own || x.linkType == LINK_TYPE.solve || x.linkType == LINK_TYPE.communicate).sort((a, b) => a.sequence - b.sequence));
                    } else if (props.group === "journey") {
                        setOfferingLinkList(response.data.filter(x => x.linkType == LINK_TYPE.referenceMaterial || x.linkType == LINK_TYPE.coachOnlyReferenceMaterial).sort((a, b) => a.sequence - b.sequence));
                    } else if (props.group === "importantLinks") {
                        setOfferingLinkList(response.data.filter(x => x.linkType == LINK_TYPE.quickLink).sort((a, b) => a.sequence - b.sequence));
                    }
                } else {
                    setOfferingLinkList([]);
                }
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    };

    const getMissionByOfferingId = () => {
        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setMissionList(response.data);
            })
            .catch(function (error) {
                console.log(error);
                // setMissionData([]);
            });
    };
    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'number',
            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'missionId',
                    className: (props.group === "importantLinks" || props.group === "journey") ? "" : "hidden",
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...missionList.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]
            },
            {
                label: "Link Name",
                field: 'linkName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Link Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Link",
                field: 'link',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Link',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Link Type",
                field: 'linkType',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Link Type',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: '-' },
                    ...linkTypeList.map((x) => {
                        return { value: x.value, label: x.label };
                    }),
                ]

            },
            {
                label: "Target",
                field: 'target',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Target',
                },
                sort: true,
                filter: false,
                filterType: 'text'

            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },

            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                }
            },
        ];
        let rows = [];
        offeringLinkList.forEach((link, index) => {
            let row = {};
            let linkType = linkTypeList.find(x => x.value === link.linkType);
            let mission = missionList.find(x => x.id === link.missionId);
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.linkName = { value: link.displayName, label: link.displayName, export: link.displayName };
            row.missionId = {
                value: link.missionId,
                label: mission ? mission.missionName : '-',
                export: mission ? mission.missionName : '-'
            };
            row.link = { value: link.link, label: link.link, export: link.link };

            row.target = { value: link.target, label: link.target == "_blank" ? "New Tab" : "Same Tab", export: link.target };
            row.sequence = { value: link.sequence, label: link.sequence, export: link.sequence };
            row.linkType = {
                value: link.linkType,
                label: linkType
                    ? linkType.label
                    : '-',
                export: linkType && linkType.label
            };
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button className="btn-text btn-sm" variant="outline-primary" onClick={() => { setShow(true); setLocalLink(link); }}><i className="fa fa-pencil" /></Button>
                        <DeleteLink {...link} />
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addLink = (e) => {
        e.preventDefault();
        var sequence = offeringLinkList.filter(x => x.linkType == localLink.linkType).length;
        var data = [{
            offeringId: user.selectedOffering,
            missionId: localLink.missionId ? localLink.missionId : 0,
            stageId: 0,
            activityId: 0,
            displayName: localLink.displayName,
            link: localLink.link,
            linkType: localLink.linkType,
            target: localLink.target,
            sequence: sequence + 1,
            isActive: true
        }]
        ProgramSetupAxios.post(`/OfferingConfiguration/offeringlinks`, data)
            .then(() => {
                getLinks();
                setShow(false);
            }).catch(err => {
                console.log("AddLink: err", err);
            });
    }


    const updateLink = (e) => {
        e.preventDefault();
        ProgramSetupAxios.put(`/OfferingLink/updateofferinglinks`, localLink)
            .then(() => {
                getLinks();
                setShow(false);
            }).catch(err => {
                console.log("updateLink: err", err);
            });
    };

    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: link.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingLink/deleteofferinglinks", [data.id])
                .then(function () {
                    getLinks();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Link</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this link?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const handleFileChange = (e) => {

        const validations = {
            //file size in 10 MB
            fileSize: 10485760,
            fileTypes: ['png', 'gif', 'jpeg', 'docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls']
        };

        if (props.group === "onboarding") {
            validations.fileSize = null;
            validations.fileTypes = ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf'];
        };
        if (props.group === "journey") {
            validations.fileSize = null;
            validations.fileTypes = ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf'];
        }

        if (props.group === "importantLinks") {
            validations.fileSize = null;
            validations.fileTypes = ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf'];
        }

        setUploading(true);
        uploadFile(e.target.files[0], null, handleLinkFileUpload)
    }

    const handleLinkFileUpload = (err, link) => {

        setUploading(false);
        if (err) {
            console.log("handleLinkFileUpload: err", err);
            setFileUploadError(err);
            return;
        }


        setFileUploadError(null);
        setLocalLink({ ...localLink, link: link });
    }



    return (
        <>
            <div className="page-header mb-4">
                <h4>{props.label}</h4>
                <Button onClick={() => {
                    setShow(true); setLocalLink({
                        offeringId: user.selectedOffering,
                        missionId: 0,
                        stageId: 0,
                        activityId: 0,
                        displayName: "",
                        link: "",
                        linkType: 0,
                        target: "_blank",
                        sequence: 0
                    });
                }} className="btn-sm">
                    + Add Link
                </Button>
            </div>
            <div className="data-table">
                <DataTable {...tableData} multiSelect={false} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localLink.id != 0 && localLink.id ? updateLink(e) : addLink(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localLink.id != 0 && localLink.id ? "Edit" : "Add"} Link </Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="levelName">Link Name<span className="required-star">*</span></label>
                                <input type="text" value={localLink.displayName || ''} maxLength={200} className="form-control" onChange={(e) => {
                                    setLocalLink({ ...localLink, displayName: e.target.value });
                                }} required />
                            </div>


                            <div className="form-group col-6">
                                <label htmlFor="role">Link Type<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localLink.linkType}
                                        onChange={(e) => {
                                            setLocalLink({ ...localLink, linkType: e.target.value });
                                        }}
                                        required
                                    >
                                        <option value="" >Select</option>
                                        {linkTypeList.map((linkType, index) => {
                                            return (
                                                <option key={index} value={linkType.value}>{linkType.label}</option>
                                            );
                                        })}
                                        {/* {
                                            props.group === "onboarding" && onboardingLinkTypeList.map((type, index) => {
                                                return <option key={index} value={type.value} >{type.label}</option>;

                                            })
                                        }
                                        {
                                            props.group === "journey" && journeyLinkTypeList.map((type, index) => {
                                                return <option key={index} value={type.value} >{type.label}</option>;

                                            })
                                        }
                                        {
                                            props.group === "importantLinks" && importantLinksLinkTypeList.map((type, index) => {
                                                return <option key={index} value={type.value} >{type.label}</option>;

                                            })
                                        } */}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-6">
                                <label htmlFor="role">Target<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localLink.target}
                                        onChange={(e) => {
                                            setLocalLink({ ...localLink, target: e.target.value });
                                        }}
                                        required>
                                        <option key={1} value="_blank" >New Tab</option>
                                        <option key={2} value="_self" >Same Tab</option>

                                    </select>

                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="sequence">Sequence<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={localLink.sequence || ''} className="form-control" onChange={(e) => {
                                    setLocalLink({ ...localLink, sequence: e.target.value });
                                }} required />
                            </div>
                            {(props.group === "importantLinks" || props.group === "journey") && <div className="form-group col-6">
                                <label htmlFor="role">Mission<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localLink.missionId}
                                        onChange={(e) => {
                                            setLocalLink({ ...localLink, missionId: e.target.value });
                                        }}
                                        required
                                    >
                                        <option value="" >Select</option>
                                        {
                                            missionList.map((mission, index) => {
                                                return <option key={index} value={mission.id} >{mission.missionName}</option>;

                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            }
                            <div className="form-group col-12">
                                <label htmlFor="levelKey">Link<span className="required-star">*</span>
                                    <small className="ps-2">You can add a link manually or select a file.</small>
                                    <FileInput
                                        uploading={uploading}
                                        value={localLink.link}
                                        // label="Upload File"
                                        linkOnly={true}
                                        onChange={(e) => { handleFileChange(e) }}
                                        required
                                    />
                                    {fileUploadError && <div className="text-danger">{fileUploadError}</div>}

                                </label>
                                <div>
                                    <input type="text" value={localLink.link || ''} className="form-control" onChange={(e) => { setLocalLink({ ...localLink, link: e.target.value }); }} required />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" >{localLink.id != 0 && localLink.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </form >
            </Modal >

        </>
    )
}

export default Links;