import React, { useContext, useEffect } from "react";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import { Button, Modal } from "react-bootstrap";
import ExportExcel from "../Exports/ExportExcel";
import UserContext from "../../UserContext";
import ExportExcelAPI from "../Exports/ExportExcelAPI";
import axios from "axios";

let cancelToken = axios.CancelToken.source();
const Registrations = (props) => {
    const localUserContext = useContext(UserContext);
    const [usersList, setUsersList] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [teams, setTeams] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const roleLabels = { 3: 'Admin', 4: 'Learner', 5: 'Coach' };
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [pageNo, setPageNo] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [usersListExport, setUsersListExport] = React.useState([]);
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const [selectedOfferingName, setSelectedOfferingName] = React.useState();


    useEffect(() => {
        setLoaderShow(true);
        if (localUserContext.offeringList && localUserContext.selectedOffering) {
            var tempName = localUserContext.offeringList.filter(x => x.id == localUserContext.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getSAPUsers();
        }
    }, [localUserContext.selectedOffering]);

    useEffect(() => {
        setPreferredMission(props.preferredMission);
    }, [props.preferredMission]);

    useEffect(() => {
        var userData = generateTableData(usersList);
        setTableData(userData);
    }, [usersList, teams, preferredMission]);

    useEffect(() => {
        getSAPUsers();
    }, [pageNo, pageSize]);

    const getSAPUsers = () => {
        setLoaderShow(true);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        let filterOptions = {
            offeringId: localUserContext.selectedOffering,
            pageNumber: pageNo,
            pageSize: pageSize,
            ...options
        }


        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions)
            .then(function (response) {
                setUsersList(response.data);
                if (response.data.length > 0) {
                    let totalCount = response.data[0].totalCount;
                    console.log('totalCount', totalCount);
                    setTotalCount(totalCount);
                }
                setLoaderShow(false);
                if (pageNo == 1) {
                    getSAPUsersExport();
                }
            })
            .catch(function (error) {
                console.log(error);
                setUsersList([]);
                setLoaderShow(false);
            });

        getTeams();
    };


    const getSAPUsersExport = () => {
        setIsLoadingExport(true);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        let filterOptions = {
            offeringId: localUserContext.selectedOffering,
            pageNumber: 0,
            pageSize: 0,
            ...options
        }

        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();
        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions, { cancelToken: cancelToken.token })
            .then(function (response) {
                setIsLoadingExport(false);
                var userListData = generateTableData(response.data);
                setUsersListExport(userListData?.rows);
            })
            .catch(function (error) {
                console.log(error);
                //setIsLoadingExport(false);
                setUsersListExport([]);
            });
    };


    const getTeams = () => {
        OnboardingAxios.get("/Team?includeConclaves=true&onlyConclaves=false")
            .then(function (response) {
                setTeams(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const generateTableData = (rawData) => {

        let roles = usersList.map((x) => {
            let label = roleLabels[x.role];
            return { value: x.role, label: label };
        });
        const ids = roles.map(o => o.value);
        roles = roles.filter(({ value }, index) => !ids.includes(value, index + 1));

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering Name",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "First Name",
                field: 'firstName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Last Name",
                field: 'lastName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Email",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Offering Portfolio",
                field: 'offeringPortfolio',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering Portfolio',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Offering",
                field: 'offering',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Location",
                field: 'location',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Location',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Industry",
                field: 'industry',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'industry',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Region/Geography",
                field: 'geography',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Region/Geography',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Job Level",
                field: 'jobLevel',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Job Level',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Role",
                field: 'role',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Role',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: 5, label: 'Coach' },
                    { value: 4, label: 'Learner' },
                    // ...roles,
                ],

            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'PreferredMission',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Team",
                field: 'teamId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'TeamId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...teams.filter(obj => obj.isConclave === false)
                        .map((x) => {
                            return { value: x.id, label: x.teamName };
                        }),
                    { value: 0, label: 'Blank' },
                ]
            },
        ];
        let rows = [];
        rawData.forEach((user) => {
            let row = {};
            let mission = preferredMission.find(x => x.id === user.preferredMission);
            let team = teams.find(x => x.id === user.teamId);
            let offeringName = selectedOfferingName;

            row.id = { value: user.id, label: user.id, export: user.id };
            row.offeringName = { value: offeringName, label: offeringName, export: offeringName };
            row.firstName = { value: user.firstName, label: user.firstName, export: user.firstName };
            row.lastName = { value: user.lastName, label: user.lastName, export: user.lastName };
            row.email = { value: user.email, label: user.email, export: user.email };
            row.offeringPortfolio = { value: user.portfolio, label: user.portfolio, export: user.portfolio };
            row.offering = { value: user.offering, label: user.offering, export: user.offering };
            row.location = { value: user.location, label: user.location, export: user.location };
            row.industry = { value: user.industry, label: user.industry, export: user.industry };
            row.geography = { value: user.geography, label: user.geography, export: user.geography };
            row.jobLevel = { value: user.jobLevel, label: user.jobLevel, export: user.jobLevel };
            row.role = { value: user.role, label: roleLabels[user.role], export: roleLabels[user.role] };
            row.missionId = {
                value: user.preferredMission,
                label: mission
                    ? mission.missionName
                    : '-',
                export: mission && mission.missionName
            };
            row.teamId = {
                value: user.teamId,
                label: team
                    ? team.teamName
                    : '-',
                export: team && team.teamName
            };
            rows.push(row);
        });

        return { columns, rows }
        //setTableData({ columns, rows });
    };


    return (
        <>
            <div className="page-header mb-4">
                <h4>Registrations Report</h4>
                <div className="page-actions">
                    <ExportExcel jsonObject={usersListExport} isLoading={isLoadingExport} columns={tableData.columns} fileName="Registration.csv" />

                </div>
            </div>
            <div className="data-table">
                <DataTable apiPagination={true} loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} multiSelect={false} applyFilters={getSAPUsers} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} totalCount={totalCount} />
            </div>
        </>
    );
};

export default Registrations;
