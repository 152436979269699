import { Button, Tab, Tabs } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "./Schedule.css";
import { ProgramSetupAxios } from "../../API";
import moment from "moment-timezone";
import UserContext from "../../UserContext";

const Schedule = (props) => {
    const user = useContext(UserContext);
    const [timeZone, setTimeZone] = useState("Asia/Kolkata");
    const [scheduleData, setScheduleData] = useState([]);
    const [key, setKey] = React.useState();

    useEffect(() => {
        getScheduleData();
    }, [user.selectedOffering])

    useEffect(() => {
        if (scheduleData.length > 0) {
            setKey("mission-" + scheduleData?.missions ? scheduleData?.missions[1]?.id : "")
        }

    }, [scheduleData])
    const getScheduleData = () => {

        ProgramSetupAxios.get("/Offering/getofferingdates/" + user.selectedOffering)
            .then(function (response) {


                let scheduleTempData = response.data;
                let missionData = scheduleTempData.missions
                scheduleTempData.timezone = scheduleTempData.timezone ? scheduleTempData.timezone : timeZone
                missionData.forEach(mission => {
                    let stageData = mission.stages.sort((a, b) => a.sequence - b.sequence);
                    if (stageData.length > 0) {
                        stageData.forEach(stage => {
                            stage.endDate = moment.utc(stage.endDate).tz(scheduleTempData.timezone);
                            stage.startDate = moment.utc(stage.startDate).tz(scheduleTempData.timezone);
                            if (stage.activities.length > 0) {
                                stage.activities.forEach(activity => {
                                    activity.endDate = moment.utc(activity.endDate).tz(scheduleTempData.timezone);
                                    activity.startDate = moment.utc(activity.startDate).tz(scheduleTempData.timezone);
                                });
                            }
                        });
                    }
                    mission.stages = stageData;
                })
                let data = {
                    coachRegEndDate: moment.utc(scheduleTempData.coachRegEndDate).tz(scheduleTempData.timezone),
                    coachRegStartDate: moment.utc(scheduleTempData.coachRegStartDate).tz(scheduleTempData.timezone),
                    endDate: moment.utc(scheduleTempData.endDate).tz(scheduleTempData.timezone),
                    learnerRegEndDate: moment.utc(scheduleTempData.learnerRegEndDate).tz(scheduleTempData.timezone),
                    learnerRegStartDate: moment.utc(scheduleTempData.learnerRegStartDate).tz(scheduleTempData.timezone),
                    offeringId: scheduleTempData.offeringId,
                    programName: scheduleTempData.programName,
                    startDate: moment.utc(scheduleTempData.startDate).tz(scheduleTempData.timezone),
                    timezone: scheduleTempData.timezone,
                    missions: missionData
                }
                setScheduleData(data);
                if (response.data.timezone) {
                    setTimeZone(response.data.timezone);
                    moment.tz.setDefault(response.data.timezone);
                } else {
                    moment.tz.setDefault(timeZone);
                }


            })
            .catch(function (error) {
                console.log(error);

            });
    }


    const saveSchedule = (e) => {
        e.preventDefault();
        let missionData = scheduleData.missions
        missionData.forEach(mission => {
            if (mission.saveAs == 0) {
                let stageData = mission.stages.sort((a, b) => a.sequence - b.sequence);
                stageData.forEach(stage => {
                    stage.endDate = moment(stage.endDate).utc();
                    stage.startDate = moment(stage.startDate).utc();

                    stage.activities.forEach(activity => {
                        activity.endDate = moment(activity.endDate).utc();
                        activity.startDate = moment(activity.startDate).utc();
                    });
                });
                mission.stages = stageData;
            } else {
                var tempMissionToCopy = missionData.filter(x => x.id == mission.saveAs);
                if (tempMissionToCopy && tempMissionToCopy.length > 0 && tempMissionToCopy[0].stages.length > 0) {

                    var tempStageToCopy = tempMissionToCopy[0].stages.sort((a, b) => a.sequence - b.sequence);

                    let stageData = mission.stages.sort((a, b) => a.sequence - b.sequence);
                    stageData.forEach((stage, stageIndex) => {
                        stage.endDate = moment(tempStageToCopy[stageIndex].endDate).utc();
                        stage.startDate = moment(tempStageToCopy[stageIndex].startDate).utc();

                        stage.activities.forEach((activity, activityIndex) => {
                            activity.endDate = moment(tempStageToCopy[stageIndex].activities[activityIndex].endDate).utc();
                            activity.startDate = moment(tempStageToCopy[stageIndex].activities[activityIndex].startDate).utc();
                        });

                    });
                    mission.stages = stageData;
                }
            }
        })
        let data = {
            coachRegEndDate: moment(scheduleData.coachRegEndDate).utc(),
            coachRegStartDate: moment(scheduleData.coachRegStartDate).utc(),
            endDate: moment(scheduleData.endDate).utc(),
            learnerRegEndDate: moment(scheduleData.learnerRegEndDate).utc(),
            learnerRegStartDate: moment(scheduleData.learnerRegStartDate).utc(),
            offeringId: scheduleData.offeringId,
            programName: scheduleData.programName,
            startDate: moment(scheduleData.startDate).utc(),
            timezone: timeZone,
            missions: missionData
        }
        ProgramSetupAxios.put(`/Offering/updateofferingdates`, data)
            .then(() => {
                getScheduleData();
                user.createToast({
                    title: "Schedule",
                    message: "Schedule saved successfully",
                    variant: "success" // "success", "warning", "danger", "info"
                }
                )
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    }

    const cancelSchedule = () => {
        getScheduleData();
    }

    const stageHandel = (missionIndex, index, dateType, value) => {
        var temp = JSON.parse(JSON.stringify(scheduleData));
        if (dateType == "startDate") {
            temp.missions[missionIndex].stages[index].startDate = value;
        }

        if (dateType == "endDate") {
            temp.missions[missionIndex].stages[index].endDate = value;
        }
        setScheduleData(temp);
    }

    const activityHandel = (missionIndex, stageIndex, activityIndex, dateType, value) => {
        var temp = JSON.parse(JSON.stringify(scheduleData));
        if (dateType == "startDate") {
            temp.missions[missionIndex].stages[stageIndex].activities[activityIndex].startDate = value;
        }

        if (dateType == "endDate") {
            temp.missions[missionIndex].stages[stageIndex].activities[activityIndex].endDate = value;
        }
        setScheduleData(temp);
    }


    return <>
        <form onSubmit={(e) => saveSchedule(e)}>
            <div className="page-header mb-4">
                <h1>Program Schedule</h1>
                <div className="page-actions">
                    <Button variant="success" type="submit" className="btn-sm" disabled={user.isPublished}>
                        <i className="fa fa-check-circle me-2" />Save
                    </Button>
                    <Button variant="" className="btn-sm  btn-secondary" onClick={() => cancelSchedule()}>Cancel</Button>

                </div>
            </div>

            <div className="row ms-0 me-0">
                <div className="col-3 label">
                    Time Zone
                </div>
                <div className="col-3">
                    <select className="form-control" value={timeZone} onChange={(e) => { setTimeZone(e.target.value); moment.tz.setDefault(e.target.value); }} required disabled={user.isPublished}>
                        <option value="Asia/Kolkata">IST</option>
                        <option value="PST8PDT">PST</option>
                        <option value="US/Mountain">MT</option>
                        <option value="CST6CDT">CST</option>
                        <option value="EST">EST</option>
                    </select>
                </div>
            </div>
            <hr />
            <div className="row ms-0 me-0 mb-2">
                <div className="col-3 schedule-label">
                </div>
                <div className="col-3">Start Date and Time</div>
                <div className="col-3">End Date and Time</div>
            </div>
            <hr />
            <div className="row ms-0 me-0 mb-2">
                <div className="col-3 schedule-label">
                    Coach Registration
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.coachRegStartDate ? moment(scheduleData.coachRegStartDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, coachRegStartDate: e.target.value })} required disabled={user.isPublished} />
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.coachRegEndDate ? moment(scheduleData.coachRegEndDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, coachRegEndDate: e.target.value })} required disabled={user.isPublished} />
                </div>
            </div>
            <div className="row ms-0 me-0 mb-2">
                <div className="col-3 schedule-label">
                    Participant Registration
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.learnerRegStartDate ? moment(scheduleData.learnerRegStartDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, learnerRegStartDate: e.target.value })} required disabled={user.isPublished} />
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.learnerRegEndDate ? moment(scheduleData.learnerRegEndDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, learnerRegEndDate: e.target.value })} required disabled={user.isPublished} />
                </div>
            </div>
            <hr />
            <div className="row ms-0 me-0 mb-2">
                <div className="col-3 schedule-label">
                    Program Offering
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.startDate ? moment(scheduleData.startDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, startDate: e.target.value })} required disabled={user.isPublished} />
                </div>
                <div className="col-3">
                    <input type="datetime-local" className="form-control" value={scheduleData.endDate ? moment(scheduleData.endDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => setScheduleData({ ...scheduleData, endDate: e.target.value })} required disabled={user.isPublished} />
                </div>
            </div>
            <hr />

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {scheduleData.missions && scheduleData.missions.map((mission, missionIndex) => {
                    return mission.saveAs == 0 &&
                        <Tab eventKey={"mission-" + mission.id} title={mission.missionName}>
                            {
                                mission.stages && mission.stages.map((stage, index) => {
                                    return <div key={"stage-" + stage.id}><div className="row ms-0 me-0" >
                                        <div className="col-3  mb-2 schedule-label">
                                            {stage.stageName}
                                        </div>
                                        <div className="col-3 mb-2">
                                            <input type="datetime-local" className="form-control" value={stage.startDate ? moment(stage.startDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => stageHandel(missionIndex, index, "startDate", e.target.value)} disabled={user.isPublished} />
                                        </div>
                                        <div className="col-3 mb-2">
                                            <input type="datetime-local" className="form-control" value={stage.endDate ? moment(stage.endDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => stageHandel(missionIndex, index, "endDate", e.target.value)} disabled={user.isPublished} />
                                        </div>


                                        <div className="col-12 ">
                                            {stage.activities && stage.activities.sort((a, b) => a.sequence - b.sequence).map((activity, childIndex) => {
                                                return <div className="row mb-2 ms-0 me-0" key={"activity-" + activity.id}>
                                                    <div className="col-3 schedule-label ps-4">
                                                        {activity.activityName}
                                                    </div>
                                                    <div className="col-3 ps-5px">
                                                        <input type="datetime-local" className="form-control" value={activity.startDate ? moment(activity.startDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => activityHandel(missionIndex, index, childIndex, "startDate", e.target.value)} disabled={user.isPublished} />
                                                    </div>
                                                    <div className="col-3 pe-5px">
                                                        <input type="datetime-local" className="form-control" value={activity.endDate ? moment(activity.endDate).format("YYYY-MM-DDTHH:mm") : ""} onChange={(e) => activityHandel(missionIndex, index, childIndex, "endDate", e.target.value)} disabled={user.isPublished} />
                                                    </div>
                                                </div>
                                            })

                                            }
                                        </div>
                                    </div>
                                        <hr />
                                    </div>
                                })

                            }
                        </Tab>


                })}
            </Tabs >



        </form>
    </>
}

export default Schedule;