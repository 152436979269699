import React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import CodeEditor from "./CodeEditor";

const EmailTemplate = () => {
    const [templateTabs, setTemplateTabs] = React.useState([
        { id: 1, name: "Onboarding", },
        { id: 2, name: "Connection", },
    ]);

    const [key, setKey] = React.useState('1');

    const TabContent = ({ tabContent }) => {
        const { id, name } = tabContent;
        const [tabData, setTabData] = React.useState("<div>Tab Content</div>");

        const handleEditorChange = (data) => {
            setTabData(data);
        }


        return (<>

            <div className="page-header mb-3">
                <h1>Template - {tabContent.name}</h1>
                <div className="page-actions">
                    <Button variant="primary" className="btn-sm"><i className="fa fa-floppy-o me-2" />Update</Button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4 className="mb-2">HTML</h4>
                    <CodeEditor html={tabData} onChange={handleEditorChange} />
                </div>
                <div className="col-md-6 border-left">
                    <h4 className="mb-2">Output</h4>
                    <div className="preview-wrapper" dangerouslySetInnerHTML={{ __html: tabData }} />
                </div>
            </div>
        </>
        )
    };




    return (
        <>
            <div className="page-header mb-3">
                <h1>Email Template</h1>
            </div>
            <div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    {templateTabs.map((tab) => (
                        <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                            <TabContent tabContent={tab} />
                        </Tab>
                    ))}
                </Tabs >
            </div>
        </>
    );
}

export default EmailTemplate;