import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import ToastMessage from "../Layout/ToastMessage";

const PublishOffering = (props) => {
    const user = useContext(UserContext);
    const [loaderShow, setLoaderShow] = React.useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [missionsList, setMissionsList] = React.useState([]);
    const [teamsList, setTeamsList] = React.useState([]);
    const [isUserApproved, setIsUserApproved] = React.useState(false);

    useEffect(() => {
        getOfferingData();
    }, [user.selectedOffering]);

    useEffect(() => {
        if (usersList.length > 0 && missionsList.length > 0 && teamsList.length > 0) {
            generateTableData();
        }
    }, [usersList, missionsList, teamsList]);

    const getOfferingData = () => {
        let filterOptions = {
            offeringId: user.selectedOffering,
            pageNumber: 0,
            pageSize: 0
        };
        ProgramSetupAxios.post("/EligiblePool/getisapprovedeligiblebyoffering", filterOptions)
            .then(function (response) {
                setUsersList(response.data);
                setIsUserApproved(response.data.some(x => x.isApprovedUserPresent === true));
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setMissionsList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        OnboardingAxios.get("/Team?includeConclaves=false&onlyConclaves=false")
            .then(function (response) {
                setTeamsList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });


    }


    const generateTableData = () => {

        let columns = [
            {
                label: "Team Name",
                field: 'teamName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Name',
                }
            },
            {
                label: "Mission",
                field: 'missionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission Name',
                }
            },
            {
                label: "Coach",
                field: 'coachName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Coach Name',
                }
            },
            {
                label: "Team Members",
                field: 'teamMembers',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Members',
                    'className': 'wrap-text'
                }
            },
            {
                label: "Team Size",
                field: 'teamSize',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Size',
                    className: 'text-center',
                }
            }
        ];

        let rows = [];

        teamsList.forEach(team => {
            let teamData = {
                id: { value: team.id, label: team.id, export: team.id },
                teamName: { value: team.teamName, label: team.teamName, export: team.teamName },
                missionName: { value: '', label: "", export: '' },
                coachName: { value: '', label: '-', export: '' },
                teamMembers: { value: '', label: '', export: '' },
                teamSize: { value: 0, label: 0, export: 0 },
                action: { value: '', label: <Button variant="outline-primary" size="sm"><i className="fa fa-pencil" /></Button> }
            };

            // let element = document.createElement("div");
            let userArray = [];
            usersList.filter(user => {
                if (user.teamId === team.id) {
                    let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " "
                        + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);

                    if (user.role === 5) {
                        teamData.coachName['value'] = user.firstName + " " + user.lastName;
                        teamData.coachName['label'] = <div className="user-name" title={user.email}><nobr>{name}</nobr></div>;
                        teamData.coachName['export'] = name;
                    } else {
                        teamData.teamMembers['value'] += user.firstName + " " + user.lastName + ", ";
                        teamData.teamMembers['label'] += name + " (" + user.email + "), ";
                        teamData.teamMembers['export'] += name + ", ";

                        // teamData.teamMembers['label'] += <div className="user-name" title={user.email}><nobr>{user.firstName + " " + user.lastName}</nobr></div>;
                        // let div = document.createElement("div");
                        // div.className = "user-name";
                        // div.title = user.email;
                        // div.innerHTML = user.firstName + " " + user.lastName;
                        // element.append(div);

                        // // console.log(element.innerHTML);
                        // teamData.teamMembers['label'] = <div>{element}</div>;

                        teamData.teamSize['value'] += 1;
                        teamData.teamSize['label'] += 1;
                        teamData.teamSize['export'] += 1;
                    }
                }
            });

            missionsList.filter(mission => {
                if (mission.id === team.missionId) {
                    teamData.missionName['value'] = mission.missionName;
                    teamData.missionName['label'] = mission.missionName;
                    teamData.missionName['export'] = mission.missionName;
                }
            });
            if (team.offeringId === user.selectedOffering) {
                rows.push(teamData);
            }
        });
        setTableData({ columns, rows });
    }

    const PublishOffering = (props) => {
        const [show, setShow] = React.useState(false);
        const [showLoad, setShowLoad] = React.useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const publishOffering = () => {
            setShowLoad(true);
            ProgramSetupAxios.put(`/Offering/publishoffering/` + user.selectedOffering)
                .then(res => {
                    setShowLoad(false);
                    handleClose();
                    user.setIsPublished(true);
                    user.createToast({ title: "Offering Published", message: "Offering has been published successfully", variant: "success" });
                    props.getOfferingData();
                })
                .catch(err => {
                    setShowLoad(false);
                    handleClose();
                    user.createToast({ title: "Error", message: "Error publishing offering", variant: "danger" });
                    console.log(err);
                });
        };

        return (
            <div>
                <Button variant="success" className="btn-sm" onClick={handleShow} disabled={!isUserApproved}>
                    <i className="fa fa-check-circle me-2" />Publish
                </Button>
                <Modal
                    show={show}
                    centered
                    animation={false}
                    className="admin-modal"
                    size="lg"
                >
                    <Modal.Header >
                        <Modal.Title className="h5">Publish Program Offering</Modal.Title>
                        {!showLoad && <div className="close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </div>}
                    </Modal.Header>
                    <Modal.Body className="text-center py-4">
                        <h5>Are you sure you want to publish the Offering?</h5>
                        <p>After publishing the offering there will be limitations on the making change in the program.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" className="btn-text" onClick={handleClose} disabled={showLoad}>
                            Cancel
                        </Button>

                        {showLoad ?
                            <Button variant="outline-primary" size="sm" >
                                <Spinner animation="border" variant="primary" size="sm" /> Publishing...
                            </Button>
                            : <Button variant="primary" size="sm" onClick={publishOffering} disabled={showLoad}>Yes, Publish</Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    const UpdateMembers = (props) => {
        const [show, setShow] = React.useState(false);
        const [teamOptions, setTeamOptions] = React.useState([]);
        const [userOptions, setUserOptions] = React.useState([]);
        const [replaceUserOptions, setReplaceUserOptions] = React.useState([]);
        const [selectedTeam, setSelectedTeam] = React.useState(null);
        const [selectedUser, setSelectedUser] = React.useState(null);
        const [selectedReplaceUser, setSelectedReplaceUser] = React.useState(null);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        useEffect(() => {
            props.teamsList.forEach(team => {
                if (team.offeringId === user.selectedOffering) {
                    setTeamOptions(prevState => [...prevState, { value: team.id, label: team.teamName }]);
                }
            });

            props.usersList.forEach(user => {
                let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
                setUserOptions(prevState => [...prevState, { value: user.email, label: name, role: user.role }]);
            });

            // props.usersList.forEach(user => {
            //     if (user.isOfferingUserCreated == false && user.userId != 0) {
            //         let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
            //         setReplaceUserOptions(prevState => [...prevState, { value: user.email, label: name, role: user.role }]);
            //     }
            // });

        }, [props.usersList, props.teamsList]);

        // useEffect(() => {
        //     setUserOptions([]);
        //     props.usersList.forEach(user => {
        //         if (user.teamId == selectedTeam) {
        //             // capital first letter of first name and last name 
        //             let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
        //             setUserOptions(prevState => [...prevState, { value: user.email, label: name }]);
        //         }
        //     });
        // }, [selectedTeam]);

        useEffect(() => {
            if (selectedTeam) {
                OnboardingAxios.get("/OfferingUsers/getusersbyteam/" + selectedTeam)
                    .then(function (response) {
                        // setTeamsUserList(response.data);
                        setUserOptions([]);
                        if (response.data.length > 0) {
                            response.data.forEach(user => {
                                // capital first letter of first name and last name 
                                let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
                                setUserOptions(prevState => [...prevState, { value: user.email, label: name }]);
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }, [selectedTeam]);

        const handleReplace = (e) => {
            e.preventDefault();
            OnboardingAxios.get(`/OfferingUsers/replaceuser/` + selectedUser + "/" + selectedReplaceUser + "/" + user.selectedOffering)
                .then(res => {


                    var selecteduserlocal = props.usersList.find(x => x.email == selectedUser)
                    var replaceuserlocal = props.usersList.find(x => x.email == selectedReplaceUser)
                    let updatedUsers=[];
                    updatedUsers.push({ id: replaceuserlocal.id, teamId: selecteduserlocal.teamId, preferredMission: selecteduserlocal.preferredMission, isApproved: selecteduserlocal.isApproved, isActive: selecteduserlocal.isActive, conclaveId: selecteduserlocal.conclaveId,isOfferingUserCreated: true })
                    updatedUsers.push({ id: selecteduserlocal.id, teamId: 0, preferredMission: 0, isApproved: false, isActive: selecteduserlocal.isActive, conclaveId: 0, isOfferingUserCreated: false })

                    ProgramSetupAxios.put(`/EligiblePool/updateeligibleusers`, updatedUsers)
                        .then(() => {
                            handleClose();
                            user.createToast({ title: "User Replaced", message: "User has been replaced successfully", variant: "success" });
                        }).catch(err => {
                            console.log("updateUser: err", err);
                        });

                })
                .catch(err => {
                    handleClose();
                    user.createToast({ title: "Error", message: "Error replacing user", variant: "danger" });
                    console.log(err);
                }
                );
        }


        const hanleselectmemberchange = (e) => {

            setSelectedUser(e.target.value);
            var temparr = props.usersList.filter(x => x.role == props.usersList.filter(k => k.email == e.target.value)[0].role && x.preferredMission == props.usersList.filter(k => k.email == e.target.value)[0].preferredMission);

            setReplaceUserOptions([]);
            temparr.forEach(user => {
                if (user.isOfferingUserCreated == false && user.userId != 0) {
                    let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
                    setReplaceUserOptions(prevState => [...prevState, { value: user.email, label: name }]);
                }
            });

        }
        return (
            <div>
                <Button variant="primary" className="btn-sm" onClick={handleShow} disabled={!user.isPublished}>
                    <i className="fa fa-user me-2" />Replace Member
                </Button>
                <Modal
                    show={show}
                    centered
                    animation={false}
                    className="admin-modal"
                    size="md"
                >
                    <form onSubmit={handleReplace}>
                        <Modal.Header >
                            <Modal.Title className="h5">Replace Member</Modal.Title>
                            <div className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="text-center py-4">
                            <div><span>Replace a team member with another registered member.</span></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label text-left">Select Team</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Team"
                                            value={selectedTeam}
                                            onChange={(e) => setSelectedTeam(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Team</option>
                                            {teamOptions.map((team, index) => (
                                                <option key={team.value} value={team.value}>{team.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label text-left">Select Member</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Members"
                                            value={selectedUser}
                                            onChange={(e) => { hanleselectmemberchange(e); }}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {userOptions.map((user, index) => (
                                                <option key={index} value={user.value}>{user.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label text-left">Replace Member with</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Members"
                                            value={selectedReplaceUser}
                                            onChange={(e) => setSelectedReplaceUser(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Member</option>
                                            {replaceUserOptions.map((user, index) => (
                                                <option key={index} value={user.value}>{user.label}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" size="sm" className="btn-text" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" size="sm" type="submit">Replace Member</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        )
    }

    const UpdateTeam = (props) => {
        const [show, setShow] = React.useState(false);
        const [teamOptions, setTeamOptions] = React.useState([]);
        const [userOptions, setUserOptions] = React.useState([]);
        const [selectedTeam, setSelectedTeam] = React.useState("");
        const [selectedUser, setSelectedUser] = React.useState("");
        const [selectedReplaceTeam, setSelectedReplaceTeam] = React.useState("");
        const [replaceTeamOptions, setReplaceTeamOptions] = React.useState([]);

        useEffect(() => {
            setTeamOptions([]);
            props.teamsList.forEach(team => {
                if (team.offeringId === user.selectedOffering) {
                    setTeamOptions(prevState => [...prevState, { value: team.id, label: team.teamName }]);
                }
            });
        }, [props.teamsList]);

        useEffect(() => {
            if (selectedTeam) {
                OnboardingAxios.get("/OfferingUsers/getusersbyteam/" + selectedTeam)
                    .then(function (response) {
                        // setTeamsUserList(response.data);

                        setUserOptions([]);
                        if (response.data.length > 0) {
                            response.data.forEach(user => {
                                // capital first letter of first name and last name 
                                let name = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " " + user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
                                setUserOptions(prevState => [...prevState, { value: user.email, label: name }]);
                            });
                            // setReplaceTeamOptions([]);
                            // props.teamsList.forEach(team => {
                            //     if (team.id != selectedTeam && team.offeringId === user.selectedOffering) {
                            //         setReplaceTeamOptions(prevState => [...prevState, { value: team.id, label: team.teamName }]);
                            //     }
                            // });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }, [selectedTeam]);


        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const handleReplace = (e) => {
            e.preventDefault();
            if (selectedTeam === "" || selectedUser === "" || selectedReplaceTeam === "") {
                user.createToast({ title: "Error", message: "Please select all fields", variant: "danger" });
                return;
            }

            OnboardingAxios.get(`/OfferingUsers/changeuserteam/` + selectedUser + '/' + selectedReplaceTeam + '/' + user.selectedOffering)
                .then((res) => {


                    var tempUsers = props.usersList.filter(x => x.email == selectedUser);

                    let updatedUsers = tempUsers.map((user) => {
                        return { id: user.id, teamId: selectedReplaceTeam, preferredMission: user.preferredMission, isApproved: user.isApproved, isActive: user.isActive, conclaveId: user.conclaveId };
                    });

                    ProgramSetupAxios.put(`/EligiblePool/updateeligibleusers`, updatedUsers)
                        .then(() => {
                            user.createToast({ title: "Success", message: "User updated successfully", variant: "success" });
                            handleClose();
                        }).catch(err => {
                            console.log("updateUser: err", err);
                        });


                })
                .catch((err) => {
                    console.log(err);
                    user.createToast({ title: "Error", message: "Something went wrong", variant: "danger" });
                });
        }

        const hanlereplaceteamchange = (e) => {
            setSelectedTeam(e.target.value)
            var temparr = props.teamsList.filter(x => x.offeringId === user.selectedOffering && x.missionId == props.teamsList.filter(k => k.id == e.target.value)[0].missionId && x.id != e.target.value);

            setReplaceTeamOptions([]);
            temparr.forEach(team => {
                setReplaceTeamOptions(prevState => [...prevState, { value: team.id, label: team.teamName }]);
            });

        }

        return (
            <div>
                <Button variant="primary" className="btn-sm" onClick={handleShow} disabled={!user.isPublished}>
                    <i className="fa fa-users me-2" />Change Team
                </Button>
                <Modal
                    show={show}
                    centered
                    animation={false}
                    className="admin-modal"
                    size="md"
                >
                    <form onSubmit={handleReplace}>
                        <Modal.Header >
                            <Modal.Title className="h5">Change Team</Modal.Title>
                            <div className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="text-center py-4">
                            <div><span>Move a member from one team to another.</span></div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label text-left">Current Team</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Team"
                                            value={selectedTeam}
                                            onChange={(e) => { hanlereplaceteamchange(e) }}
                                            required
                                        >
                                            <option value="">Select Team</option>
                                            {teamOptions.map((team, index) => (
                                                <option key={team.value} value={team.value}>{team.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label text-left">Select Team Member</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Members"
                                            value={selectedUser}
                                            onChange={(e) => setSelectedUser(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Members</option>
                                            {userOptions.map((user, index) => (
                                                <option key={index} value={user.value}>{user.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label text-left">New Team</label>
                                        <select
                                            className="form-select"
                                            placeholder="Select Team"
                                            value={selectedReplaceTeam}
                                            onChange={(e) => setSelectedReplaceTeam(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Team</option>
                                            {replaceTeamOptions.map((team, index) => (
                                                <option key={team.value} value={team.value}>{team.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" size="sm" className="btn-text" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" size="sm" type="submit">Move To New Team</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        )
    }


    return (
        <>
            <div className="page-header mb-3">
                <h1>Publish Program Offering</h1>
                <div className="page-actions">
                    <UpdateMembers teamsList={teamsList} usersList={usersList} />
                    <UpdateTeam teamsList={teamsList} usersList={usersList} />
                    <PublishOffering selectedOffering={user.selectedOffering} getOfferingData={getOfferingData} />

                </div>
            </div>
            {isUserApproved && user.isPublished && <Alert variant="warning" >
                The offering is published. You have new approved user publish offering to make changes.
            </Alert>}

            <div className="mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
            </div>

            <div className="data-table">
                <DataTable multiSelect={false} loaderShow={loaderShow} {...tableData} />
            </div>


        </>
    );
}

export default PublishOffering;