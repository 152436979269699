import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { IAMAxios, ProgramSetupAxios, UserActivityAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import ConfirmDialog from "../Layout/ConfirmDialog";

const UserManagement = (props) => {
    const user = useContext(UserContext);
    const [isOffering, setIsOffering] = React.useState(props.isOffering);
    const [users, setUsers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [offeringList, setOfferingList] = React.useState([]);
    const [tempUsers, setTempUsers] = React.useState([]);
    const [allAdminUsers, setAllAdminUsers] = React.useState([]);

    useEffect(() => {
        getUsers();
    }, [user]);

    useEffect(() => {
        if (users.length > 0) {
            generateTableData(users);
        }
    }, [users]);


    useEffect(() => {
        let usersList = [];
        let offering = offeringList.find(o => o.id === user.selectedOffering);
        let userIds = [];
        if (offering) {
            userIds = offering.programAdminUsers && offering.programAdminUsers.map(u => u && u.id);
        }
        usersList = tempUsers.filter(x => userIds.includes(x.id));
        setUsers(usersList);

    }, [offeringList]);


    const getUsers = () => {
        IAMAxios.get("/AdminUser/getadminusers")
            .then(function (response) {
                setTempUsers(response.data);
                setIsLoading(true);
                setAllAdminUsers(response.data);

                if (isOffering) {
                    ProgramSetupAxios.get("/OfferingAdmin/getadminofferings/" + user.adminId)
                        .then((response) => {
                            setOfferingList(response.data);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    setUsers(response.data);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const generateTableData = () => {

        let columns = [
            {
                label: "First Name", field: 'firstName', attributes: { 'aria-controls': 'DataTable', 'aria-label': 'First Name', className: 'text-center', },
                filter: false, filterType: 'text',
            },
            {
                label: "Last Name", field: 'lastName', attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Last Name', },
                filter: false, filterType: 'text',
            },
            {
                label: "Email", field: 'email', attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Email', },
                filter: false, filterType: 'text',
            },
            {
                label: "Role", field: 'roleId', attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Role', },
                filter: false, filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: 1, label: 'Super Admin' },
                    { value: 2, label: 'Program Manager' },
                    { value: 3, label: 'Program Administrator' }
                ],
            },

            { label: "Action", field: 'action', attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Action', className: 'text-center ' }, filterType: 'action ', },
        ];

        let rows = [];
        let managerCount = users.filter(x => x.roleId === 2).length;
        // sort users by role
        let sortedUsers = users.sort((a, b) => {
            if (a.roleId === b.roleId)
                return a.firstName.localeCompare(b.firstName);
            else
                return a.roleId - b.roleId;
        });

        users.length > 0 && sortedUsers.forEach((u, index) => {
            let row = {};
            row.id = { value: u.id, label: u.id, export: u.id };
            row.firstName = { value: u.firstName, label: u.firstName, export: u.firstName };
            row.lastName = { value: u.lastName, label: u.lastName, export: u.lastName };
            row.email = { value: u.email, label: u.email, export: u.email };

            if (u.roleId === 1) {
                row.roleId = { value: 'Super Admin', label: 'Super Admin', export: 'Super Admin' };
            }
            if (u.roleId === 2) {
                row.roleId = { value: 'Program Manager', label: 'Program Manager', export: 'Program Manager' };
            }
            if (u.roleId === 3) {
                row.roleId = { value: 'Program Administrator', label: 'Program Administrator', export: 'Program Administrator' };
            }

            if (isOffering) {
                if ((u.roleId === 2 || u.roleId === 1) && managerCount <= 1) {
                    row.action = { value: '', label: <div className="text-muted">Default</div>, export: '' };
                } else {
                    row.action = { value: "", label: <RemoveUser {...u} /> };
                }
            } else if (u.roleId !== 1 || user.id === u.id) {
                row.action = { value: "", label: <RemoveUser {...u} /> };
            } else {
                row.action = { value: "", label: <div className="text-muted">Default</div> };
            }
            if (u.isActive) {
                rows.push(row);
            }
        });

        setTableData({ columns, rows });
    }

    const RemoveUser = (props) => {
        const [show, setShow] = React.useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const handleRemove = () => {
            let mapping = {
                adminUserId: props.id,
                offeringId: user.selectedOffering,
            };

            if (isOffering) {
                console.log("Removing from offering");
                ProgramSetupAxios.post("/OfferingAdmin/deleteadminoffering", mapping)
                    .then(() => {
                        getUsers();
                        user.createToast({ title: "Success", message: "User deleted Successfully", variant: "success" });
                    })
                    .catch(error => {
                        console.log(error);
                        user.createToast({ title: "Error", message: "Error removing User", variant: "danger" });
                    });
            } else {
                console.log("Removing from system");
                ProgramSetupAxios.post("/OfferingAdmin/deleteadminofferingmapping/" + props.id,)
                    .then(() => {
                        IAMAxios.post("/AdminUser/deleteadminuser/" + props.id)
                            .then(() => {
                                getUsers();
                                user.createToast({ title: "Success", message: "User deleted Successfully", variant: "success" });
                            }).catch(error => {
                                console.log(error);
                                user.createToast({ title: "Error", message: "Error deleting User", variant: "danger" });
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        user.createToast({ title: "Error", message: "Error deleting User", variant: "danger" });
                    });
            }
        }

        return (
            <>
                <Button variant="outline-danger" size="sm" color="primary" onClick={handleShow}>
                    <i className="fa fa-trash" />
                </Button>
                <ConfirmDialog
                    open={show}
                    onCancel={handleClose}
                    onConfirm={handleRemove}
                    title={"Delete - " + props.firstName + " " + props.lastName}
                    message={<p>Are you sure you want to remove this user?</p>}
                    confirmVarient="danger"
                />
            </>
        )
    }

    const AssignUser = () => {
        const [show, setShow] = React.useState(false);
        const [selectedUser, setSelectedUser] = React.useState('');
        const [assignedUsersIds, setAssignedUsersIds] = React.useState([]);

        useEffect(() => {
            let ids = users.map(u => u.id);
            setAssignedUsersIds(ids);
        }, []);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const handleSubmit = () => {
            let mapping = {
                adminUserId: selectedUser,
                offeringId: user.selectedOffering,
            };

            ProgramSetupAxios.post("/OfferingAdmin/assignoffering/" + user.selectedOffering, mapping)
                .then(function (response) {
                    console.log(response);
                    handleClose();
                    getUsers();
                    user.createToast({ title: "Success", message: "User Assigned Successfully", variant: "success" });
                })
                .catch(function (error) {
                    console.log(error);
                    handleClose();
                    user.createToast({ title: "Error", message: "Error Assigning User", variant: "error" });
                });
        }

        return (
            <>
                <Button variant="primary" className="btn-sm" onClick={handleShow}>
                    <i className="fa fa-user me-2" />Assign User
                </Button>

                <Modal
                    show={show}
                    onHide={() => setShow(false)}

                    centered>
                    <Modal.Header >
                        <Modal.Title>Assign User</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Select User</label>
                            <select className="form-control" value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="" disabled>Select User</option>
                                {allAdminUsers.filter(x => !assignedUsersIds.includes(x.id) && x.roleId !== 1).map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.firstName + " " + user.lastName} - {user.email}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>Close</Button>
                        <Button variant="primary" size="sm" onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    const AddUser = () => {
        const [show, setShow] = React.useState(false);
        const [firstName, setFirstName] = React.useState("");
        const [lastName, setLastName] = React.useState("");
        const [userEmail, setUserEmail] = React.useState("");
        const [userRole, setUserRole] = React.useState("");

        const handleSubmit = (e) => {
            e.preventDefault();
            const userData = {
                firstName: firstName,
                lastName: lastName,
                email: userEmail,
                roleId: userRole,
                isActive: true,
            }

            IAMAxios.post("/AdminUser/addadminuser", userData)
                .then(function (response) {
                    console.log(response);
                    if (isOffering && response.data.id) {
                        let mapping = {
                            adminUserId: response.data.id,
                            offeringId: user.selectedOffering,
                        }

                        ProgramSetupAxios.post("/OfferingAdmin/assignoffering/" + user.selectedOffering, mapping)
                            .then(function (response) {
                                console.log(response);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                    user.createToast({ title: "User Added", message: "User added successfully", variant: "success" });
                    getUsers();
                    setShow(false);
                })
                .catch(function (error) {
                    user.createToast({ title: "Error", message: "Error adding user", variant: "danger" });
                    console.log(error);
                });

        }

        return (
            <>
                <Button variant="success" className="btn-sm" onClick={() => setShow(true)}>
                    <i className="fa fa-user-plus me-2" />Add New User
                </Button>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>Add New User</Modal.Title>
                            <div className="close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>First Name <span className="danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Last Name <span className="danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                </div>
                                <div className="form-group">
                                    <label>Email ID <span className="danger">*</span></label>
                                    <input type="email" className="form-control" placeholder="Email ID" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Role <span className="danger">*</span></label>
                                    <select className="form-control" value={userRole} onChange={(e) => setUserRole(e.target.value)} required>
                                        <option value="" disabled>Select Role</option>
                                        <option value="2">Program Manager</option>
                                        <option value="3">Program Administrator</option>
                                        {!isOffering &&
                                            <>
                                                <option value="1" disabled>Super Admin</option>
                                                <option value="6" disabled>Cast Member</option>
                                                <option value="7" disabled>Guest</option>
                                            </>
                                        }
                                    </select>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link btn-text" onClick={() => setShow(false)}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" size="sm">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="page-header mb-4">
                <h1>User Management</h1>
                <div className="page-actions">
                    {isOffering && <AssignUser />}
                    <AddUser />
                    {/* <Button variant="success" className="btn-sm"><i className="fa fa-user-plus me-2" />Add User</Button> */}
                </div>
            </div>
            <DataTable multiSelect={false} {...tableData} loaderShow={isLoading} setFilters={setFilters} filters={filters} setFilterData={setFilterData} setSelectedRows={setSelectedRows} />
        </>
    );
};

export default UserManagement;