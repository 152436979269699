import { UserActivityAxios } from "./API";

const generalDateFormat = (date) => {
    if (date) {
        var d = new Date(date),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (day.length < 2) day = '0' + day;
        let shortMonthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return [day, shortMonthNameList[d.getMonth()], year].join(' ');
    }
    return '-';

}

const uploadFile = (file, validations, callback) => {
    const formData = new FormData();
    formData.append("file", file);

    // if validations are provided, validate file before uploading
    if (validations) {
        if (validations.fileSize && file.size > validations.fileSize) {
            let fileSize = (validations.fileSize / 1024 / 1024).toFixed(0);
            callback("File size should be less than " + fileSize + " MB", null);
            return;
        }

        if (validations.fileTypes && !validations.fileTypes.includes(file.type)) {
            let allowed = false;
            validations.fileTypes.forEach((type, index) => {
                if (file.type.includes(type)) {
                    allowed = true;
                    return;
                }
            });

            if (!allowed) {
                callback("File type should be " + validations.fileTypes.join(", "), null);
                return;
            }
        }
    }

    UserActivityAxios.put("/Solution/fileupload", formData)
        .then((response) => {
            callback(null, response.data);
        })
        .catch((error) => {
            callback(error.message, null);
            console.log(error);
        });
}


const inputDateFormat = (date) => {
    if (date) {
        let d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        return year + "-" + month + "-" + day;
    }

}

export { generalDateFormat, inputDateFormat, uploadFile };