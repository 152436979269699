import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios, OnboardingAxios } from "../../API";
import DataTable from "../DataTable/DataTable"; import UserContext from "../../UserContext";


const AutoMaticTeamAlloation = (props) => {
    const user = useContext(UserContext);

    const [show, setShow] = React.useState(false);
    const [usersList, setUsersList] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [teams, setTeams] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const roleLabels = { 3: 'Admin', 4: 'Learner', 5: 'Coach' };
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const [pageNo, setPageNo] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [isPublished, setIsPublished] = React.useState(false);

    const [error, setError] = React.useState("");


    const [selectedOfferingName, setSelectedOfferingName] = React.useState();

    useEffect(() => {
        var usersListData = generateTableData(usersList);
        setTableData(usersListData);
    }, [usersList, teams, preferredMission]);

    useEffect(() => {
        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setPreferredMission(response.data);
                OnboardingAxios.get("/Team?includeConclaves=true&onlyConclaves=false")
                    .then(function (response1) {
                        setTeams(response1.data);
                        console.log(response1.data)
                        ProgramSetupAxios.get("/EligiblePool/allocateteams/" + user.selectedOffering)
                            .then(function (response2) {
                                if (!Array.isArray(response2.data)) {
                                    setError(response2.data)

                                }
                                else {
                                    setUsersList(response2.data)
                                    var UsersListData = generateTableData(response2.data);
                                    setTableData(UsersListData);
                                }

                            })
                    })
                    .catch(function (error) {
                    });

            })
            .catch(function (error) {
                console.log(error);
            });


    }, [])

    const automaticTeamAllocation = () => {
        setIsLoadingExport(false);
        ProgramSetupAxios.get("/EligiblePool/allocateteams/" + user.selectedOffering)
            .then(function (response2) {
                if (!Array.isArray(response2.data)) {
                    setIsLoadingExport(true);
                }
                else {
                    setUsersList(response2.data)
                    var UsersListData = generateTableData(response2.data);
                    setTableData(UsersListData);
                }
            })
    }

    const UpdateTeams = () => {
        ProgramSetupAxios.post("/EligiblePool/updateteamallocation/", usersList)
            .then(function (response2) {
                setShow(false);
                props.getSAPUsers();
            });
    };

    const generateTableData = (rawData) => {

        let roles = usersList.map((x) => {
            let label = roleLabels[x.role];
            return { value: x.role, label: label };
        });
        const ids = roles.map(o => o.value);
        roles = roles.filter(({ value }, index) => !ids.includes(value, index + 1));
        console.log(teams);
        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering Name",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'hidden'
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Name",
                field: 'name',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Email ID",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Portfolio",
                field: 'portfolio',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Portfolio',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Industry",
                field: 'industry',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Industry',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Location",
                field: 'location',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Location',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Role",
                field: 'role',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Role',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: 5, label: 'Coach' },
                    { value: 4, label: 'Learner' },
                    // ...roles,
                ],

            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'missionId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Team",
                field: 'teamId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'TeamId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: 0, label: 'All' },
                    ...teams.filter(obj => obj.isConclave === false && obj.offeringId === user.selectedOffering)
                        .map((x) => {
                            return { value: x.id, label: x.teamName };
                        }),
                    { value: 0, label: 'Blank' },
                ]
            }
        ];

        let rows = [];
        rawData.forEach((user) => {
            let row = {};
            let mission = preferredMission.find(x => x.id === user.preferredMission);
            let team = teams.find(x => x.id === user.teamId);
            let conclave = teams.find(x => x.id === user.conclaveId);

            row.id = { value: user.id, label: user.id };
            row.offeringName = { value: selectedOfferingName, label: selectedOfferingName, export: selectedOfferingName }
            row.name = { value: user.firstName + " " + user.lastName, label: user.firstName + " " + user.lastName, export: user.firstName + " " + user.lastName };
            row.portfolio = { value: user.portfolio, label: user.portfolio, export: user.portfolio };
            row.industry = { value: user.industry, label: user.industry, export: user.industry };
            row.location = { value: user.location, label: user.location, export: user.location };

            row.email = { value: user.email, label: user.email, export: user.email };
            row.role = { value: user.role, label: roleLabels[user.role], export: roleLabels[user.role] };
            row.missionId = {
                value: user.preferredMission,
                label: mission ? mission.missionName : '-',
                export: mission ? mission.missionName : '-'
            };
            row.teamId = {
                value: user.teamId,
                label: team ? team.teamName : '-',
                export: team ? team.teamName : '-'
            };

            row.selectable = user.isOfferingUserCreated ? false : true;

            if (user.userId > 0) {
                rows.push(row);
            }
        });

        // setTotalCount(rows.length);
        return { columns, rows };
    };
    return (
        <>
            <Button variant="primary" className="btn-sm" onClick={() => setShow(true)} >
                <i className="fa fa-pencil-square-o me-2" /> Allocate Teams
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                animation={false}
                className="admin-modal"
                size="xl"
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Allocate Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {!error &&
                        <div className="data-table">
                            <DataTable multiSelect={false} apiPagination={false} loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} setSelectedRows={setSelectedRows} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} totalCount={totalCount} />
                        </div>
                    }
                    {error && <div>{error}</div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" type="eka " onClick={() => { UpdateTeams() }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AutoMaticTeamAlloation;