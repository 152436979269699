import React, { useEffect } from "react";
import { Button, Dropdown, Form, Pagination } from "react-bootstrap";
import Loader from "../Loader/Loader";
import './DataTable.css';

const DataTable = (props) => {
    const [multiSelect, setMultiSelect] = React.useState(true);
    const [selectAll, setSelectAll] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [filters, setFilters] = React.useState(props.filters || {});
    const [pageNo, setPageNo] = React.useState(props.pageNo || 1);
    const [pageSize, setPageSize] = React.useState(props.pageSize || 25);
    const [totalCount, setTotalCount] = React.useState(props.totalCount || 0);
    const [pages, setPages] = React.useState([]);
    const [apiPagination, setApiPagination] = React.useState(props.apiPagination || false);
    const [filterCleared, setFilterCleared] = React.useState(false);
    const [countMessage, setCountMessage] = React.useState("showing 0 to 0 of 0 entries");

    useEffect(() => {
        let rawData = props.rows || [];
        if (props.totalCount) {
            setTotalCount(props.totalCount);
        }
        else {
            setTotalCount(rawData.length);
        }

        setData(rawData);
        gerneratePagination();
    }, [props.rows, props.totalCount]);

    useEffect(() => {
        if (props.multiSelect != undefined) {
            setMultiSelect(props.multiSelect);
        }
    }, [props.multiSelect]);


    useEffect(() => {
        let filter = { ...filters };
        if (props.columns) {
            props.columns.map((column) => {
                if (!filter[column.field]) {
                    filter[column.field] = '';
                }
            });
        }

        setFilters(filter);
    }, [props.columns]);


    useEffect(() => {
        filterData();
        setSelectAll(false);
        setSelectedRows([]);
    }, [filters]);

    useEffect(() => {
        //console.log('selectedRows', selectedRows);
        props.setSelectedRows && props.setSelectedRows(selectedRows);
    }, [selectedRows]);

    useEffect(() => {
        gerneratePagination();

        // if (!props.apiPagination && props.rows) {
        //     let rawData = props.rows || [];
        //     let tempData = rawData.slice((pageNo - 1) * pageSize, pageNo * pageSize);
        //     console.log('tempData', tempData);
        //     setData(tempData);
        // }

        let message = "showing {from} to {to} of {total}";
        if (apiPagination) {
            let from = (pageNo - 1) * pageSize + 1;
            let to = pageNo * pageSize;
            if (to > totalCount) {
                to = totalCount;
            }
            setCountMessage(message.replace('{from}', from).replace('{to}', to).replace('{total}', totalCount));
        }
        else {
            setCountMessage(message.replace('{from}', 1).replace('{to}', totalCount).replace('{total}', totalCount));
        }

    }, [pageNo, pageSize, totalCount]);

    const handlePageChange = (pageNo) => {
        setPageNo(pageNo);
        props.setPageNo && props.setPageNo(pageNo);
        // if (apiPagination) {
        //     data.slice((pageNo - 1) * pageSize, pageNo * pageSize);
        // }
    }

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPageNo(1);
        props.setPageSize && props.setPageSize(pageSize);
    }

    const gerneratePagination = () => {
        let totalPages = Math.ceil(totalCount / pageSize);
        // let pages = [];
        // for (let i = 1; i <= totalPages; i++) {
        //     pages.push(i);
        // }
        // console.log('pages', pages, totalCount);
        // setPages(pages);

        let current = pageNo;
        let last = totalPages;
        let left = current - 2;
        let right = current + 2 + 1;
        let range = [];
        let rangeWithDots = [];
        let l;

        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
        setPages(rangeWithDots);


        //         {totalCount > 0 ? (pageNo - 1) * pageSize + 1 : 0}
        //         &nbsp;- {data.length < pageSize ? totalCount : (pageNo * pageSize > totalCount ? totalCount : pageNo * pageSize)}
        //         &nbsp;of {totalCount}
        //     </>
        //     :
        //     <>
        //         {data.length > 0 ? (pageNo - 1) * pageSize + 1 : 0}
        //         &nbsp;- {data.length < pageSize ? data.length : (pageNo * pageSize > data.length ? data.length : pageNo * pageSize)}
        //         &nbsp;of {data.length}
        //     </>
        // }
    };


    const tableFilterItems = (item, index) => {

        if (item.filter) {
            if (item.filterType === 'text') {
                return <input
                    type="text"
                    className="form-control py-1 px-2"
                    placeholder={item.label}
                    onChange={(e) => {
                        let filterKey = item.field;
                        setFilters({ ...filters, [filterKey]: e.target.value });
                    }}
                    value={filters[item.field] || ''}
                />;
            }

            if (item.filterType === 'select') {
                return <select
                    className="form-control py-1 px-2"
                    onChange={(e) => {
                        let filterKey = item.field;
                        setFilters({ ...filters, [filterKey]: e.target.value });
                    }}
                    value={filters[item.field]}>
                    {item.filterList.map((option, index) => {
                        return <option key={index} value={option.value}>{option.label}</option>;
                    })}
                </select>;
            }

            if (item.filterType === 'date') {
                return <input
                    type="date"
                    className="form-control py-1 px-2"
                    onChange={(e) => {
                        let filterKey = item.field;
                        setFilters({ ...filters, [filterKey]: e.target.value });
                    }}
                    value={filters[item.field] || ''}
                />;
            }

            if (item.filterType === 'number-range') {
                let filterKey = item.field;
                let rangeKey = filterKey + 'range';
                // console.log('rangeKey', rangeKey);
                return <div className="filter-range">
                    <input
                        type="number"
                        className="form-control py-1 px-2"
                        placeholder="min"
                        onChange={(e) => {
                            setFilters({ ...filters, [filterKey]: e.target.value, [rangeKey]: { ...filters[rangeKey], min: parseInt(e.target.value) } });
                        }}
                        value={filters[rangeKey] && filters[rangeKey].min}
                    />
                    <input
                        type="number"
                        className="form-control py-1 px-2"
                        placeholder="max"
                        onChange={(e) => {
                            setFilters({ ...filters, [filterKey]: e.target.value, [rangeKey]: { ...filters[rangeKey], max: parseInt(e.target.value) } });
                        }}
                        value={filters[rangeKey] && filters[rangeKey].max}
                    />
                </div>
            }

            if (item.filterType === 'action') {
                return item.action;
            }
        }
    };


    const filterData = (data = props.rows) => {

        let filteredData = [];
        // console.log('filters', filters);
        if (Object.keys(filters).length > 0) {
            // console.log('data', data);

            filteredData = data.filter((item) => {
                let isValid = true;

                Object.keys(filters).map((key) => {
                    if (filters[key] && item[key]) {

                        let itemValue = item[key].value;
                        let filterValue = filters[key];

                        // console.log('itemValue', itemValue);
                        // console.log('filterValue', filterValue);

                        if (typeof itemValue === 'string') {
                            // console.log('string', itemValue);

                            if (!itemValue?.toLocaleLowerCase().includes(filterValue?.toLocaleLowerCase())) {
                                isValid = false;
                            }
                        }

                        if (typeof itemValue === 'number') {
                            // console.log('number', itemValue);

                            let filterRange = false;
                            let min = filters[key + 'range'] ? filters[key + 'range'].min : '';
                            let max = filters[key + 'range'] ? filters[key + 'range'].max : '';

                            if (min && max) {
                                filterRange = true;
                            }

                            if (filterRange) {
                                if (itemValue < min || itemValue > max) {
                                    isValid = false;
                                }
                            } else if (itemValue !== parseInt(filterValue)) {
                                isValid = false;
                            }
                        }

                        if (typeof itemValue === 'boolean') {
                            // console.log('boolean', itemValue);

                            filterValue = filterValue === 'true' ? true : false;
                            if (itemValue !== filterValue) {
                                isValid = false;
                            }
                        }

                    }
                });
                return isValid;
            });

            if (!props.applyFilters) {
                setData(filteredData);
                props.setFilterData && props.setFilterData(filteredData);
            }

            props.setFilters && props.setFilters(filters);
            props.setFilterData && props.setFilterData(filteredData);

            if (props.applyFilters && filterCleared === true) {
                props.applyFilters(filteredData);
                setFilterCleared(false);
            }
        }
    };

    const clearFilter = () => {
        let filter = { ...filters };
        if (props.columns) {
            props.columns.map((column) => {
                filter[column.field] = '';
                if (filter[column.field + 'range']) {
                    filter[column.field + 'range'] = { min: '', max: '' };
                }
            });
        }
        setFilters(filter);
        setTimeout(() => {
            setFilterCleared(true);
        }, 500);

    };

    useEffect(() => {
        filterData();
    }, [filters, filterCleared]);


    const CustomPagination = () => {
        return (
            <div className="table-pagination row">
                {/* <div className="right-side gap-5"> */}
                <div className="left-side w-auto">
                    <div className="d-flex align-items-center gap-2">
                        <nobr>Page size</nobr>
                        <Form.Select size='sm' value={pageSize} onChange={(e) => handlePageSizeChange(e.target.value)}>
                            {/* <option value='5'>5</option> */}
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='500'>500</option>
                            <option value='1000'>1000</option>
                        </Form.Select>
                    </div>
                    <div>
                        <nobr>{countMessage}</nobr>
                    </div>
                </div>

                <div className="col right-side">
                    {props.columns && props.columns.some(x => x.filter) &&
                        <>
                            <Button variant="outline-secondary" size="sm" onClick={() => clearFilter()} title="Clear filter">
                                <nobr>Clear Filter</nobr>
                            </Button>
                            {props.applyFilters &&
                                <Button variant="primary" size="sm" onClick={() => {props.setPageNo(1); props.applyFilters()}}>
                                    <nobr>Apply Filter</nobr>
                                </Button>
                            }
                        </>
                    }


                    {data.length > 0 &&
                        <div className="pagination-wrapper">
                            <Pagination className="mb-0" size="sm">
                                <Pagination.Prev onClick={() => handlePageChange(pageNo > 1 ? pageNo - 1 : 1)} disabled={pageNo === 1} />
                                {
                                    pages.map((page, index) => {
                                        if (page === '...') {
                                            return <Pagination.Ellipsis key={'ellipsis-' + index} />
                                        }
                                        return <Pagination.Item key={'page-' + index} active={pageNo === page} onClick={() => handlePageChange(page)}>{page}</Pagination.Item>
                                    })
                                }
                                <Pagination.Next onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === pages[pages.length - 1]} />
                            </Pagination>
                        </div>
                    }
                </div>

                {/* </div> */}

            </div >
        )
    }
    return (
        <>
            <CustomPagination />
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            {multiSelect === true &&
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        className="form-check-input pointer"
                                        checked={selectAll}
                                        onChange={() => {
                                            setSelectAll(!selectAll);
                                            if (selectAll) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(data.filter(x => x.selectable).map(item => item['id'].value));
                                            }

                                        }}
                                    />
                                </th>
                            }

                            {
                                props.columns && props.columns.map((col, index) => {
                                    return <th key={'col-' + index} {...col.attributes}>{col.label}</th>;
                                })
                            }
                        </tr>

                    </thead>
                    <tbody>
                        {props.columns && props.columns.some(x => x.filter) ? "" : <tr></tr>}
                        <tr className={props.columns && props.columns.some(x => x.filter) ? "" : "hidden"}>
                            {multiSelect === true &&
                                <td className="text-center">
                                    <i className="fa fa-filter" ></i>
                                </td>
                            }
                            {
                                props.columns && props.columns.map((col, index) => {
                                    if (index === props.columns.length - 1) {
                                        return <td key={'col-' + index} {...col.attributes}>
                                            <div className="data-table-action">
                                                {col.filter && tableFilterItems(col, index)}
                                                {/* {props.applyFilters &&
                                                    <Button variant="link" className="btn-text" size="xm" onClick={() => props.applyFilters()}>
                                                        <b>Apply</b>
                                                    </Button>
                                                }
                                                <Button variant="link link-danger" size="xm" onClick={() => clearFilter()} title="Clear filter">
                                                    <i className="fa fa-close fa-1" />
                                                </Button> */}
                                            </div>
                                        </td>;
                                    } else {
                                        if (col.filter) {
                                            return <td key={'col-' + index} {...col.attributes}>
                                                {/* {col.label} */}
                                                {tableFilterItems(col, index)}
                                            </td>;
                                        }
                                        else {
                                            return <td key={'col-' + index} ></td>;
                                        }
                                    }
                                })
                            }
                        </tr>

                        {/* {console.log('data', data)} */}

                        {!props.loaderShow && data &&
                            <>
                                {apiPagination ?
                                    data.map((row, index) => {
                                        return (
                                            <tr key={'row-' + row['id'].value + index}>
                                                {multiSelect === true &&
                                                    <td className="text-center">
                                                        {/* {row.selectable} */}
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input pointer"
                                                            checked={selectedRows.includes(row['id'].value)}
                                                            disabled={!row.selectable}
                                                            onChange={() => {
                                                                if (selectedRows.includes(row['id'].value)) {
                                                                    setSelectedRows(selectedRows.filter(id => id !== row['id'].value));
                                                                } else {
                                                                    setSelectedRows([...selectedRows, row['id'].value]);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                }
                                                {props.columns.map((col, index) => {
                                                    return <td key={'col-' + row['id'].value + index} className={col.attributes['className']}>{row[col.field].label}</td>;
                                                })}

                                            </tr>
                                        );
                                    })
                                    : data.slice((pageNo - 1) * pageSize, pageNo * pageSize).map((row, index) => {
                                        return (
                                            <tr key={'row-' + row['id'].value + index}>
                                                {multiSelect === true &&
                                                    <td className="text-center">
                                                        {/* {row.selectable} */}
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input pointer"
                                                            checked={selectedRows.includes(row['id'].value)}
                                                            disabled={!row.selectable}
                                                            onChange={() => {
                                                                if (selectedRows.includes(row['id'].value)) {
                                                                    setSelectedRows(selectedRows.filter(id => id !== row['id'].value));
                                                                } else {
                                                                    setSelectedRows([...selectedRows, row['id'].value]);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                }
                                                {props.columns.map((col, index) => {
                                                    return <td key={'col-' + row['id'].value + index} className={col.attributes['className']}>{row[col.field].label}</td>;
                                                })}

                                            </tr>
                                        );
                                    })
                                }
                            </>}
                    </tbody>
                </table>
                {props.loaderShow &&
                    <Loader />
                }
                {
                    data.length === 0 && !props.loaderShow &&
                    <div className="text-center">
                        <h4>No Data Available</h4>
                    </div>
                }
            </div>
            {/* {pages.length > 1 && */}
            {!props.noPaginaton && <CustomPagination />}
            {/* <CustomPagination /> */}
            {/* } */}
        </>
    );
};

export default DataTable;