import React from "react";
import Editor from 'react-simple-code-editor';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-twilight.css';
import { useEffect } from "react";

const CodeEditor = ({ onChange, html }) => {
    const [code, setCode] = React.useState(html);

    useEffect(() => {
        onChange(code);
    }, [code, onChange]);

    return (
        <div className="code-editor">
            <Editor
                value={code}
                onValueChange={code => setCode(code)}
                highlight={code => highlight(code, languages.js)}
                padding={10}
                style={{
                    fontFamily: 'source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace',
                    fontSize: 14,
                    height: '100%',
                }}
            />
        </div>

    )
}

export default CodeEditor;