import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";


const ActivityFeedbackTypes = (props) => {
    const user = useContext(UserContext);
    const [offeringActivityFeedbackTypeList, setOfferingActivityFeedbackTypeList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localActivityFeedbackType, setLocalActivityFeedbackType] = React.useState([]);
    const [selectedActivityCategory, setSelectedActivityCategory] = React.useState([])

    useEffect(() => {
        setSelectedActivityCategory(props.selectedActivityCategory);
    }, [props.selectedActivityCategory]);

    useEffect(() => {
        generateTableData();
    }, [offeringActivityFeedbackTypeList]);

    useEffect(() => {
        setOfferingActivityFeedbackTypeList(props.offeringActivityFeedbackTypeList);
    }, [props.offeringActivityFeedbackTypeList])


    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Name",
                field: 'name',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',


            },
            {
                label: "Value",
                field: 'typeValue',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Value',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'edit-button-align text-center',
                },

            },
        ];
        let rows = [];
        offeringActivityFeedbackTypeList.forEach((activityFeedbackType, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.name = { value: activityFeedbackType.name, label: activityFeedbackType.name, export: activityFeedbackType.name };
            row.typeValue = { value: activityFeedbackType.value, label: activityFeedbackType.value, export: activityFeedbackType.value };
            row.sequence = { value: activityFeedbackType.sortOrder, label: activityFeedbackType.sortOrder, export: activityFeedbackType.sortOrder };
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button onClick={() => { setShow(true); setLocalActivityFeedbackType(activityFeedbackType); }} variant="outline-primary" size="sm" >
                            <i className="fa fa-pencil " />
                        </Button >
                        {/* <DeleteLink {...activityFeedbackType} /> */}
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addRatingTypes = (e) => {
        e.preventDefault();
        let data = {
            offeringActivityFeedbackId: selectedActivityCategory.id,
            name: localActivityFeedbackType.name,
            sortOrder: localActivityFeedbackType.sortOrder,
            value: localActivityFeedbackType.value
        }

        ProgramSetupAxios.post(`/OfferingActivityFeedback/createtofferingactivityfeedbacktypes`, [data])
            .then(() => {
                props.getActivityFeedbacks();
                setShow(false);
            }).catch(err => {
                console.log("AddLevel: err", err);
            });
    }


    const updateRatingTypes = (e) => {
        e.preventDefault();
        ProgramSetupAxios.put(`/OfferingActivityFeedback/updateactivityfeedbacktype`, localActivityFeedbackType)
            .then(() => {
                props.getActivityFeedbacks();
                setShow(false);
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    };

    const onCategoryChange = (categoryId) => {
        var tempCategory = props.offeringActivityFeedbackList.filter(x => x.id == categoryId)
        setSelectedActivityCategory(tempCategory[0]);
        setOfferingActivityFeedbackTypeList(tempCategory[0].offeringActivityFeedbackTypes.sort((a, b) => a.sortOrder - b.sortOrder))


    }


    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: link.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingActivityFeedback/deleteofferingactivityfeedbacktype", [data.id])
                .then(function () {
                    props.getActivityFeedbacks();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Rating Scale</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this rating scale?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="page-header mb-4">
                <h4>Rating Scale</h4>
                {/* <Button onClick={() => {
                    setShow(true); setLocalActivityFeedbackType({
                        offeringId: user.selectedOffering,
                        name: 0,
                        description: 0,
                        maxRating: 0,
                        sortOrder: 0,
                        isOverallRating: true
                    });
                }} className="btn-sm">
                    + Add Rating Scale
                </Button> */}
            </div>
            <div className="d-flex mb-4 align-items-baseline">
                <h5 className="me-3">Rating Category :</h5>

                <div className="d-inline-block align-items-center  ">

                    <select
                        className="form-control"
                        value={selectedActivityCategory.id}
                        onChange={(e) => {
                            // setLocalActivityFeedback({ ...selectedActivityCategory, isActive: e.target.value });
                            onCategoryChange(e.target.value)

                        }}
                        required>
                        {props.offeringActivityFeedbackList.map(activityFeedback => {
                            return <option key={activityFeedback.id} value={activityFeedback.id} >{activityFeedback.name}</option>
                        })
                        }

                    </select>

                </div>
            </div>
            <div className="data-table">
                <DataTable {...tableData} multiSelect={false} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localActivityFeedbackType.id != 0 && localActivityFeedbackType.id ? updateRatingTypes(e) : addRatingTypes(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localActivityFeedbackType.id != 0 && localActivityFeedbackType.id ? "Edit" : "Add"} Activity Feedback Scale</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="levelName">Name</label>
                                <input type="text" maxLength={200} value={localActivityFeedbackType.name || ''} className="form-control" onChange={(e) => {
                                    setLocalActivityFeedbackType({ ...localActivityFeedbackType, name: e.target.value });
                                }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Sequence</label>
                                <input type="number" min={0} max={9999} value={localActivityFeedbackType.sortOrder || ''} className="form-control"
                                    onChange={(e) => {
                                        setLocalActivityFeedbackType({ ...localActivityFeedbackType, sortOrder: e.target.value });
                                    }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Value</label>
                                <input type="number" min={0} max={9999} value={localActivityFeedbackType.value || ''} className="form-control"
                                    onChange={(e) => {
                                        setLocalActivityFeedbackType({ ...localActivityFeedbackType, value: e.target.value });
                                    }} required />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" >{localActivityFeedbackType.id != 0 && localActivityFeedbackType.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default ActivityFeedbackTypes;