import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { ACTIVITY_TYPE } from "../../../shared/Enum";
import UserContext from "../../../UserContext";


const ActivityItem = (props) => {
    const user = useContext(UserContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tempActivity, setTempActivity] = useState(props.item);

    const deleteActivity = () => {
        if (props.item.id != 0) {
            props.deleteActivity(props.item, props.index);
        }
        else {
            props.deleteNonSavedActivity(props.item, props.index);
            //props.getStagesByOfferingId();
        }
    }

    const addBulletInActivity = () => {
        let tempLong = JSON.parse(tempActivity.longDescription) ? JSON.parse(tempActivity.longDescription) : [];
        var statusCount = tempLong?.length;
        var tempBullet = {
            status: statusCount,
            text: ""
        }
        tempLong.push(tempBullet);

        setTempActivity({ ...tempActivity, longDescription: JSON.stringify(tempLong) });
        props.setActivityData(props.parentIndex, props.index, { ...props.item, longDescription: JSON.stringify(tempLong) });
    }

    const bulletPointOnChange = (value, valueIndex) => {
        let tempLong = JSON.parse(tempActivity.longDescription);
        tempLong[valueIndex].text = value;

        setTempActivity({ ...tempActivity, longDescription: JSON.stringify(tempLong) });
        props.setActivityData(props.parentIndex, props.index, { ...props.item, longDescription: JSON.stringify(tempLong) });
    }

    const deleteBullet = (valueIndex) => {
        let tempLong = JSON.parse(tempActivity.longDescription) ? JSON.parse(tempActivity.longDescription) : [];

        tempLong.splice(valueIndex, 1);

        setTempActivity({ ...tempActivity, longDescription: JSON.stringify(tempLong) });
        props.setActivityData(props.parentIndex, props.index, { ...props.item, longDescription: JSON.stringify(tempLong) });

    }

    const sequenceUp = () => {
        if (tempActivity.sequence > 1) {
            // props.setActivityData(props.parentIndex, props.index, { ...props.item, sequence: tempActivity.sequence - 1 });            
            // setTempActivity({ ...tempActivity, sequence: tempActivity.sequence - 1 });

            props.sequenceUp(props.index);
        }
    }

    const sequenceDown = () => {
        if (props.activityCount > tempActivity.sequence) {
            props.sequenceDown(props.index);

        }
    }

    const onActivityTypeChange = (value) => {

        var isAutoComplete = false;
        var isCoachOnlyActivity = false;
        var isLearnerOnlyActivity = false;
        var isTeamActivity = false;

        if (value == ACTIVITY_TYPE.notSpecified) {
            isAutoComplete = true;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = false;
            isTeamActivity = false;
        } else if (value == ACTIVITY_TYPE.meeting) {
            isAutoComplete = false;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = false;
            isTeamActivity = true;
        } else if (value == ACTIVITY_TYPE.teamDiscussion) {
            isAutoComplete = false;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = false;
            isTeamActivity = true;
        } else if (value == ACTIVITY_TYPE.individualAssignment) {
            isAutoComplete = false;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = true;
            isTeamActivity = false;
        } else if (value == ACTIVITY_TYPE.solutionSubmit) {
            isAutoComplete = false;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = false;
            isTeamActivity = true;
        } else if (value == ACTIVITY_TYPE.selfReflection) {
            isAutoComplete = false;
            isCoachOnlyActivity = false;
            isLearnerOnlyActivity = true;
            isTeamActivity = false;
        } else if (value == ACTIVITY_TYPE.coachOnly) {
            isAutoComplete = false;
            isCoachOnlyActivity = true;
            isLearnerOnlyActivity = false;
            isTeamActivity = false;
        }
        props.setActivityData(props.parentIndex, props.index, { ...props.item, activityType: value, isAutoComplete: isAutoComplete, isCoachOnlyActivity: isCoachOnlyActivity, isLearnerOnlyActivity: isLearnerOnlyActivity, isTeamActivity: isTeamActivity });

    }
    return <>
        <Accordion.Item className="mission-box" eventKey={props.index + 1}>
            <Accordion.Header as="div" className="mission-header" onClick={props.setActiveKey}>Activity: {props.item.activityName || props.item.activityName !== "" ? props.item.activityName : "New Activity"}
                <div className="page-actions me-2">
                    <ButtonGroup className="me-2 gap-1" aria-label="First group">
                        <Button variant="link" className=" p-0" size="sm" onClick={(e) => { sequenceUp(); e.stopPropagation(); }} >
                            <i className="fa fa-arrow-up" />
                        </Button>
                        <Button variant="link" className="btn-sm p-0" onClick={(e) => { sequenceDown(); e.stopPropagation(); }} >
                            <i className="fa fa-arrow-down" />
                        </Button>
                    </ButtonGroup>
                    <Button variant="outline-danger" className="btn-sm" onClick={(e) => { e.stopPropagation(); setShowDeleteModal(true) }} disabled={user.isPublished}><i className="fa fa-trash" /></Button>

                </div>
            </Accordion.Header>
            <Accordion.Body className="mission-body">
                <Form.Group className="mb-3  d-flex">
                    <Form.Label className="mission-label">Activity Type</Form.Label>
                    <Form.Select className="mission-width-name" value={props.item.activityType} onChange={(e) => { onActivityTypeChange(e.target.value) }} >
                        <option value={ACTIVITY_TYPE.notSpecified}>Not Specified</option>
                        <option value={ACTIVITY_TYPE.meeting}>Meeting</option>
                        <option value={ACTIVITY_TYPE.teamDiscussion}>Team Discussion</option>
                        <option value={ACTIVITY_TYPE.individualAssignment}>Individual Assignment</option>
                        <option value={ACTIVITY_TYPE.solutionSubmit}>Solution Submit</option>
                        <option value={ACTIVITY_TYPE.selfReflection}>Self Reflection</option>
                        <option value={ACTIVITY_TYPE.coachOnly}>Coach Only</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3  d-flex">
                    <Form.Label className="mission-label">Activity Name<span className="required-star">*</span></Form.Label>
                    <Form.Control className="mission-width-name" type="text" maxLength={200} value={tempActivity.activityName} onBlur={(e) => { props.setActivityData(props.parentIndex, props.index, { ...props.item, activityName: e.target.value }); }} onChange={(e) => { setTempActivity({ ...tempActivity, activityName: e.target.value }); }} placeholder="Enter value" required />
                </Form.Group>
                {/* <div className="row mb-3">
                    <div className="col-3">
                        <input type="checkbox" className="form-check-input me-2" id="checkbox" checked={tempActivity.isAutoComplete} onChange={() => { setTempActivity({ ...tempActivity, isAutoComplete: !tempActivity.isAutoComplete }); props.setActivityData(props.parentIndex, props.index, { ...props.item, isAutoComplete: !tempActivity.isAutoComplete }); }} />
                        <label className="form-check-label" htmlFor="checkbox">Auto Complete</label>
                    </div>

                    <div className="col-3">
                        <input type="checkbox" className="form-check-input me-2" id="checkbox" checked={tempActivity.isCoachOnlyActivity} onChange={() => { setTempActivity({ ...tempActivity, isCoachOnlyActivity: !tempActivity.isCoachOnlyActivity }); props.setActivityData(props.parentIndex, props.index, { ...props.item, isCoachOnlyActivity: !tempActivity.isCoachOnlyActivity }); }} />
                        <label className="form-check-label" htmlFor="checkbox">Coach Only Activity</label>
                    </div>
                    <div className="col-3">
                        <input type="checkbox" className="form-check-input me-2" id="checkbox" checked={tempActivity.isLearnerOnlyActivity} onChange={(e) => { setTempActivity({ ...tempActivity, isLearnerOnlyActivity: !tempActivity.isLearnerOnlyActivity }); props.setActivityData(props.parentIndex, props.index, { ...props.item, isLearnerOnlyActivity: !tempActivity.isLearnerOnlyActivity }); }} />
                        <label className="form-check-label" htmlFor="checkbox">Learner Only Activity</label>
                    </div>
                    <div className="col-3">
                        <input type="checkbox" className="form-check-input me-2" id="checkbox" checked={tempActivity.isTeamActivity} onChange={(e) => { setTempActivity({ ...tempActivity, isTeamActivity: !tempActivity.isTeamActivity }); props.setActivityData(props.parentIndex, props.index, { ...props.item, isTeamActivity: !tempActivity.isTeamActivity }); }} />
                        <label className="form-check-label" htmlFor="checkbox">Team Activity</label>
                    </div>

                </div> */}
                <Form.Group className="mb-3  d-flex">
                    <Form.Label className="mission-label">Description<span className="required-star">*</span></Form.Label>
                    <div className="mission-full-width">
                        {tempActivity.longDescription && JSON.parse(tempActivity.longDescription).map((bulletPoint, index) => {
                            return <div className="d-flex align-items-center"><Form.Control className="activity-textarea" as="textarea" value={bulletPoint.text} rows={2} onChange={(e) => { bulletPointOnChange(e.target.value, index) }} placeholder="Enter value" required />
                                {JSON.parse(tempActivity.longDescription).length > 1 && <Button variant="danger" className="btn-sm mb-3 ms-3" onClick={() => deleteBullet(index)}><i className="fa fa-trash-o" /></Button>}</div>
                        })
                        }
                        <Button className="btn" onClick={addBulletInActivity}>+Add Bullet</Button>
                    </div>

                </Form.Group>
            </Accordion.Body>
        </Accordion.Item>

        <Modal
            show={showDeleteModal}
            centered
            className="admin-modal"
        >
            <Modal.Header >
                <Modal.Title>Delete Activity</Modal.Title>
                <div className="close" onClick={() => setShowDeleteModal(false)}>
                    <span aria-hidden="true">&times;</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this Activity?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" size="sm" onClick={deleteActivity}>
                    Yes, Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default ActivityItem