import { userAgentPolicy } from "@azure/core-http";
import React, { useEffect } from "react";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { OnboardingAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import { useState } from "react";

const TeamManagement = (props) => {
    const user = useContext(UserContext);
    const [show, setShow] = React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [missionList, setMissionList] = React.useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getTeams();
    }, []);

    useEffect(() => {
        setMissionList(props.missions);
        generateTableData();

    }, [teams, props.missions]);

    const getTeams = () => {

        OnboardingAxios.get("/Team?includeConclaves=true&onlyConclaves=false")
            .then(function (response) {
                setTeams(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const DeleteTeam = (team) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            OnboardingAxios.post("/Team/deleteteam/" + team.id)
                .then(function () {
                    getTeams();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Team</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {team.membersCount == 0 ?
                            <p>Are you sure you want to delete this team?</p>
                            : <p>Sorry, you can not delete this team.</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        {team.membersCount == 0 &&
                            <Button variant="danger" size="sm" onClick={handleDelete}>
                                Yes, Delete
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Id', className: 'text-center w-10' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Name",
                field: 'teamName',
                attributes: { 'aria-controls': 'DataTable' },
                filter: true,
                filterType: 'text',
            },
            {
                label: "Mission",
                field: 'mission',
                attributes: { 'aria-controls': 'DataTable' },
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...missionList.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ],

            },
            {
                label: "Conclave",
                field: 'isConclave',
                attributes: { 'aria-controls': 'DataTable' },
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ],
            },
            {
                label: "Action",
                field: 'action',
                attributes: { 'aria-controls': 'DataTable', className: 'text-center' },
                filter: false,
            },

        ];

        let rows = [];
        teams.forEach(team => {

            let row = {};
            row.id = { value: team.id, label: team.id, export: team.id };
            row.teamName = { value: team.teamName, label: team.teamName, export: team.teamName };
            row.mission = { value: '', label: '-', export: '-' };
            row.isConclave = { value: team.isConclave, label: team.isConclave ? "Yes" : "No", export: team.isConclave ? "Yes" : "No" };
            row.action = {
                value: '',
                label: <div className="d-flex gap-2 justify-content-center"><EditTeam team={team} /> <DeleteTeam {...team} /></div>
            };

            let mission = missionList.find(mission => mission.id === team.missionId);
            if (mission) {
                row.mission = { value: mission.id, label: mission.missionName, export: mission.missionName };
                // rows.push(row);
            } else if (team.isConclave) {
                row.mission = { value: 0, label: '-', export: '-' };
            }
            if (team.offeringId == user.selectedOffering) {
                // console.log(team, user.selectedOffering, missionList);
                rows.push(row);
            }

        });

        setTableData({ columns, rows });

    }

    const EditTeam = ({ team }) => {
        const [showEdit, setShowEdit] = React.useState(false);
        const [editTeam, setEditTeam] = React.useState(team);
        const [teamName, setTeamName] = React.useState(team.teamName);
        const [isConclave, setIsConclave] = React.useState(team.isConclave);
        const [missionId, setMissionId] = React.useState(team.missionId || 0);
        const [error, setError] = React.useState({});

        const handleCloseEdit = () => setShowEdit(false);
        const handleShowEdit = () => setShowEdit(true);

        const handleTeamNameChange = (event) => {
            setTeamName(event.target.value);
        }

        const handleEdit = (e) => {
            e.preventDefault();
            let data = {
                id: team.id,
                teamName: teamName,
                missionId: parseInt(missionId),
                isConclave: team.isConclave,
            }

            OnboardingAxios.post("/Team/updateteamname", data)
                .then(function (response) {
                    handleCloseEdit();
                    getTeams();
                }).catch(function (error) {
                    console.log(error);
                });

        }

        return (
            <>
                <Button variant="outline-primary" size='sm' onClick={handleShowEdit} ><i className="fa fa-pencil" /></Button>
                <Modal
                    show={showEdit}
                    onHide={handleCloseEdit}
                    centered
                    animation={false}
                    className="admin-modal"
                    size="md"
                >
                    <form onSubmit={handleEdit}>

                        <Modal.Header >
                            <Modal.Title>Manage Teams</Modal.Title>
                            <div className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                {!isConclave &&
                                    <div className="form-group col-md">
                                        <label >Mission<span className="required-star">{isConclave ? '' : '*'}</span></label>
                                        <select
                                            className="form-control" value={missionId}
                                            onChange={(e) => { setMissionId(e.target.value); setError({ ...error, missionId: '' }) }}
                                            required={!isConclave ? true : false}
                                        >
                                            <option value="">Select Mission</option>
                                            {missionList.map(mission => {
                                                return <option key={mission.id} value={mission.id}>{mission.missionName}</option>
                                            }
                                            )}
                                        </select>
                                        {error.missionId && <div className="danger">{error.missionId}</div>}
                                    </div>
                                }
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>Team Name</label>
                                        <input type="text" className="form-control" value={teamName} onChange={handleTeamNameChange} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" className="btn-text" onClick={handleCloseEdit}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary" size="sm" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>

        )
    }

    const AddTeam = (props) => {
        const [showAddTeam, setShowAddTeam] = React.useState(false);
        const [isConclave, setIsConclave] = React.useState(false);
        const [teamName, setTeamName] = React.useState('');
        const [missionId, setMissionId] = React.useState();
        const [error, setError] = React.useState({});

        const handleClose = () => { setShowAddTeam(false); }
        const handleShow = () => { setShowAddTeam(true); }

        useEffect(() => {
            setError({});
            setTeamName('');
        }, [showAddTeam]);

        const createNewTeam = () => {
            let error = {};
            let newTeam = {
                offeringId: props.offeringId,
                missionId: isConclave ? 0 : missionId,
                teamName: teamName,
                isConclave: isConclave,
            };

            if (!Boolean(missionId) && !isConclave) {
                error.missionId = "Please select a mission";
                setError(error);
            }

            if (!teamName) {
                error.teamName = "Please enter a team name";
                setError(error);
                return;
            }

            OnboardingAxios.post(`/Team/`, newTeam)
                .then(res => {
                    setShowAddTeam(false);
                    props.updateTeam(res.data)
                }).catch(err => {
                    console.log("createNewTeam: err", err);
                }
                );
        };

        return (<>
            <div className="page-actions">
                <Button variant="outline-primary" className="btn-rounded btn-sm mb-2" onClick={handleShow} >
                    <i className="fa fa-plus" /> New Team
                </Button>
            </div>

            <Modal
                show={showAddTeam}
                onHide={handleClose}
                centered
                animation={false}
                className="admin-modal"
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
            >

                <Modal.Header >
                    <Modal.Title>Create Team/Conclave</Modal.Title>
                    <div className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label >Mission<span className="required-star">{isConclave ? '' : '*'}</span></label>
                            <select
                                className="form-control" value={missionId}
                                onChange={(e) => { setMissionId(e.target.value); setError({ ...error, missionId: '' }) }}
                                required={!isConclave ? true : false}
                            >
                                <option value="">Select Mission</option>
                                {missionList.map(mission => {
                                    return <option key={mission.id} value={mission.id}>{mission.missionName}</option>
                                }
                                )}
                            </select>
                            {error.missionId && <div className="danger">{error.missionId}</div>}


                        </div>

                        <div className="form-group col-md-6">
                            <label >Conclave/Team Name<span className="required-star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                value={teamName}
                                required
                                onChange={(e) => { setTeamName(e.target.value); setError({ ...error, teamName: '' }); }}
                            />
                            {error && <div className="danger">{error.teamName}</div>}
                        </div>
                        <div className="form-group checkbox-group">
                            <input
                                type="checkbox"
                                checked={isConclave}
                                onChange={(e) => { setIsConclave(e.target.checked); setMissionId(''); }}
                                className="form-check-input pointer"
                            />
                            <label> Conclave</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="btn-text" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" size="sm" onClick={() => createNewTeam()} >Create team</Button>
                </Modal.Footer>

            </Modal>
        </>);
    };
    const updateTeam = (team) => {
        setTeams([...teams, team]);
    }
    return (
        <>
            <Button variant="primary" className="btn-sm" onClick={handleShow}  >
                <i className="fa fa-users me-2" /> Manage Teams
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                animation={false}
                className="admin-modal"
                size="xl"
            >
                <Modal.Header >
                    <Modal.Title>Manage Teams</Modal.Title>
                    <div className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <AddTeam offeringId={user.selectedOffering} updateTeam={updateTeam} />
                    <div className="data-table">
                        <DataTable  {...tableData} multiSelect={false} pageSize={10} totalCount={tableData.rows && tableData.rows.length || 0} />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default TeamManagement;