import React from "react";

const Settings = (props) => {

    return (
        <h4>
            Settings
        </h4>
    );
}

export default Settings;