import React from "react";
import { Spinner } from "react-bootstrap";
import './inputs.css';

const FileInput = (props) => {
    const [selectFileLink, setSelectFileLink] = React.useState(props.linkOnly || false);

    return (
        selectFileLink ?
            <>
                {props.uploading ?
                    <label className="btn btn-link btn-text p-0 float-right">
                        <Spinner animation="border" size="sm" className="thin-border me-2" />Uploading...
                    </label>
                    :
                    <>
                        <label htmlFor="customFileSelect" className="btn btn-link btn-text p-0 float-right">{props.label || 'Select a file'}</label>
                        <input
                            type="file"
                            className="custom-file-input"
                            id={"customFileSelect"}
                            hidden
                            onChange={props.onChange} />
                    </>}
            </>
            :
            <div className="form-group">
                <label className="form-label">{props.label || "Select file"}</label>
                <div className="custom-file">
                    <input
                        {...props}
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={props.onChange} />
                    <label className="custom-file-label" htmlFor="customFile">{props.filename || "Choose file"}</label>
                </div>
            </div>
    );
};

export default FileInput;