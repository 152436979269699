import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import './Toast.css';

const ToastMessage = (props) => {

    const handleDismiss = (id) => {
        props.setToastList(props.toastList.filter(x => x.id !== id));
    }

    return (
        <div className="toast-container-wrapper">
            <ToastContainer className="p-3" position={"bottom-end"} >
                {props.toastList && props.toastList.map(toast => {
                    return (
                        <Toast
                            className="toast-message"
                            onClose={() => handleDismiss(toast.id)}
                            bg={toast.variant ? toast.variant?.toLowerCase() : ""}
                            key={toast.id}
                            delay={2000}
                            autohide
                        >
                            <Toast.Header closeButton={false}>
                                <strong className="me-auto">{toast.title}</strong>
                                <div className="close" onClick={() => handleDismiss(toast.id)}>
                                    <span aria-hidden="true">&times;</span>
                                </div>
                            </Toast.Header>
                            <Toast.Body >
                                {toast.message}
                            </Toast.Body>
                        </Toast>)
                })}
            </ToastContainer>
        </div>
    )
}

ToastMessage.defaultProps = {
    variant: "Dark",
    title: "Toast Message",
    message: "Hello, world! This is a toast message.",
    time: new Date().getTime(),
    show: false
}

export default ToastMessage;