import React, { useContext, useEffect } from "react";
import UploadData from "./UploadData";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import { Button, Modal } from "react-bootstrap";
import ExportExcel from "../Exports/ExportExcel";
import BulkAction from "./BulkAction";
import UserContext from "../../UserContext";
import Tooltip from "../Layout/Tooltip";
import TeamManagement from "./TeamManagement";
import ConfirmDialog from "../Layout/ConfirmDialog";
import ExportExcelAPI from "../Exports/ExportExcelAPI";
import axios from "axios";
import AutoMaticTeamAlloation from "./AutoMaticTeamAlloation";


let cancelToken = axios.CancelToken.source();
const TeamAllocation = (props) => {
    const user = useContext(UserContext);
    const [usersList, setUsersList] = React.useState([]);
    const [usersListExport, setUsersListExport] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [teams, setTeams] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const roleLabels = { 3: 'Admin', 4: 'Learner', 5: 'Coach' };
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const [pageNo, setPageNo] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [isPublished, setIsPublished] = React.useState(false);


    const [selectedOfferingName, setSelectedOfferingName] = React.useState();

    useEffect(() => {
        if (user.offeringList && user.selectedOffering) {
            setLoaderShow(true);
            var tempName = user.offeringList.filter(x => x.id == user.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getSAPUsers();
        }
        setIsPublished(user.isPublished);
    }, [user.selectedOffering, user.offeringList]);

    useEffect(() => {
        var usersListData = generateTableData(usersList);
        setTableData(usersListData);
    }, [usersList, teams, preferredMission]);

    useEffect(() => {
        getSAPUsers();
    }, [pageNo, pageSize]);

    useEffect(() => {
        let filterOptions = {
            offeringId: user.selectedOffering,
            isRegisterd: 1,
            pageNumber: 1,
            pageSize: 5
        };

        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions)
            .then(function (response) {
                let isUserApproved = response.data[0]["isRegisteredUserPresent"];
                if (isUserApproved) {
                    // console.log("User is approved");
                    let updatedMenuStatus = [
                        { link: '/team-allocation', unLocked: true, isCompleted: true },
                        { link: '/publish', unLocked: true, isCompleted: false },
                    ];
                    user.setMenuStatus(updatedMenuStatus)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const getSAPUsers = () => {
        setLoaderShow(true)
        // get filters with values
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        let filterOptions = {
            offeringId: user.selectedOffering,
            pageNumber: pageNo,
            pageSize: pageSize,
            isRegistered: 1,
            ...options
        }

        if (options.name) {
            filterOptions.firstName = filters.name;
            filterOptions.lastName = filters.name;
            // delete name key from filterOptions
            delete filterOptions.name;
        }

        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions)
            .then(function (response) {
                setUsersList(response.data);
                setLoaderShow(false);

                if (response.data.length > 0) {
                    let totalCount = response.data[0].totalCount;
                    setTotalCount(totalCount);
                }
                // let isUserApproved = response.data.some(x => x.isApproved === true && x.isOfferingUserCreated === false);
                // console.log("isUserApproved", isUserApproved);
                // if (isUserApproved) {
                //     // console.log("User is approved");
                //     let updatedMenuStatus = [
                //         { link: '/team-allocation', unLocked: true, isCompleted: true },
                //         { link: '/publish', unLocked: true, isCompleted: false },
                //     ];
                //     user.setMenuStatus(updatedMenuStatus)
                // }

            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setPreferredMission(response.data);
                if (pageNo == 1) {
                    getSAPUsersExport();
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        getTeams();

    };

    const getSAPUsersExport = () => {
        // get filters with values
        setUsersListExport([]);
        setIsLoadingExport(true);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }


        let filterOptions = {
            offeringId: user.selectedOffering,
            pageNumber: 0,
            pageSize: 0,
            isRegistered: 1,
            ...options
        }

        if (options.name) {
            filterOptions.firstName = filters.name;
            filterOptions.lastName = filters.name;
            // delete name key from filterOptions
            delete filterOptions.name;
        }

        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions, { cancelToken: cancelToken.token })
            .then(function (response) {
                setIsLoadingExport(false);
                var UsersListData = generateTableData(response.data);
                setUsersListExport(UsersListData?.rows);
            })
            .catch(function (error) {
                setUsersListExport([]);
                setIsLoadingExport(false);
            });

    };

    const getTeams = () => {
        OnboardingAxios.get("/Team?includeConclaves=true&onlyConclaves=false")
            .then(function (response) {
                setTeams(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const generateTableData = (rawData) => {

        let roles = usersList.map((x) => {
            let label = roleLabels[x.role];
            return { value: x.role, label: label };
        });
        const ids = roles.map(o => o.value);
        roles = roles.filter(({ value }, index) => !ids.includes(value, index + 1));

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering Name",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'hidden'
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Name",
                field: 'name',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Email ID",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Portfolio",
                field: 'portfolio',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Portfolio',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Industry",
                field: 'industry',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Industry',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Location",
                field: 'location',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Location',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },

            {
                label: "Role",
                field: 'role',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Role',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    { value: 5, label: 'Coach' },
                    { value: 4, label: 'Learner' },
                    // ...roles,
                ],

            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'missionId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Team",
                field: 'teamId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'TeamId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: 0, label: 'All' },
                    ...teams.filter(obj => obj.isConclave === false && obj.offeringId === user.selectedOffering)
                        .map((x) => {
                            return { value: x.id, label: x.teamName };
                        }),
                    { value: 0, label: 'Blank' },
                ]
            },
            {
                label: "MD Conclave",
                field: 'conclaveId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'NameConclaveId',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: 'All' },
                    ...teams.filter(obj => obj.isConclave === true && obj.offeringId === user.selectedOffering)
                        .map((x) => {
                            return { value: x.id, label: x.teamName };
                        }),
                    { value: 0, label: 'Blank' },
                ]
            },
            {
                label: "Approve",
                field: 'isApproved',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Approved',
                    'className': 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: 0, label: 'All' },
                    { value: 1, label: 'Yes' },
                    { value: -1, label: 'No' },
                ]
            },

            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                }
            },

        ];

        let rows = [];
        rawData.forEach((user) => {
            let row = {};
            let mission = preferredMission.find(x => x.id === user.preferredMission);
            let team = teams.find(x => x.id === user.teamId);
            let conclave = teams.find(x => x.id === user.conclaveId);

            row.id = { value: user.id, label: user.id };
            row.offeringName = { value: selectedOfferingName, label: selectedOfferingName, export: selectedOfferingName }
            row.name = { value: user.firstName + " " + user.lastName, label: user.firstName + " " + user.lastName, export: user.firstName + " " + user.lastName };
            row.portfolio = { value: user.portfolio, label: user.portfolio, export: user.portfolio };
            row.industry = { value: user.industry, label: user.industry, export: user.industry };
            row.location = { value: user.location, label: user.location, export: user.location };

            row.email = { value: user.email, label: user.email, export: user.email };
            row.role = { value: user.role, label: roleLabels[user.role], export: roleLabels[user.role] };
            row.missionId = {
                value: user.preferredMission,
                label: mission ? mission.missionName : '-',
                export: mission ? mission.missionName : '-'
            };
            row.teamId = {
                value: user.teamId,
                label: team ? team.teamName : '-',
                export: team ? team.teamName : '-'
            };
            row.conclaveId = {
                value: user.conclaveId,
                label: conclave ? conclave.teamName : 'NA', export: conclave ? conclave.teamName : 'NA'
            };
            row.isApproved = { value: user.isApproved, label: <ApprovedCheckBox user={user} />, export: user.isApproved ? 'Yes' : 'No' };
            // row.published = { value: user.isOfferingUserCreated, label: user.isOfferingUserCreated ? "Published" : "Unpublished", export: user.isOfferingUserCreated ? "Published" : "Unpublished" };
            row.action = { value: "", label: <EditUserRow user={user} /> };
            row.selectable = user.isOfferingUserCreated ? false : true;

            if (user.userId > 0) {
                rows.push(row);
            }
        });

        // setTotalCount(rows.length);
        return { columns, rows };
    };



    const AddTeam = (props) => {
        const [showAddTeam, setShowAddTeam] = React.useState(false);
        const [teamName, setTeamName] = React.useState('');
        const [missionName, setMissionName] = React.useState('');
        const [localPreferredMission, setLocalPreferredMission] = React.useState(0);
        const [error, setError] = React.useState({});

        const handleClose = () => { setShowAddTeam(false); }
        const handleShow = () => { setShowAddTeam(true); }

        useEffect(() => {
            setError({});
            setTeamName('');
        }, [showAddTeam]);

        useEffect(() => {
            let mission = preferredMission.find(x => x.id === parseInt(props.selectedMIssion));
            if (mission) {
                setMissionName(mission.missionName);
            } else {
                setMissionName('');
            }

            setLocalPreferredMission(props.selectedMIssion);

        }, [props.selectedMIssion]);

        const createNewTeam = () => {

            let newTeam = {
                offeringId: props.offeringId,
                missionId: props.isConclave ? 0 : localPreferredMission,
                teamName: teamName,
                isConclave: props.isConclave || false,
            };
            if (!teamName) {
                setError({ ...error, teamName: "Team name is required" });
                return;
            }

            OnboardingAxios.post(`/Team/`, newTeam)
                .then(res => {
                    setShowAddTeam(false);
                    props.updateTeam(res.data)
                }).catch(err => {
                    console.log("createNewTeam: err", err);
                }
                );
        };

        return (<>
            {localPreferredMission ?
                <Button variant="outline-primary" className="btn-rounded btn-sm" onClick={handleShow} >
                    <i className="fa fa-plus" />
                </Button>
                :
                <Tooltip text="Please select Mission">
                    <Button variant="outline-primary" className="btn-rounded btn-sm btn-disabled" >
                        <i className="fa fa-plus" />
                    </Button>
                </Tooltip>
            }

            <Modal
                show={showAddTeam}
                onHide={handleClose}
                centered
                animation={false}
                className="admin-modal"
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
            >

                <Modal.Header >
                    <Modal.Title>Create {props.isConclave ? "Conclave" : 'Team'}</Modal.Title>
                    <div className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group ">
                        <label >Mission<span className="required-star">*</span> : <strong>{missionName ? missionName : "NA"}</strong></label>
                        {/* <input
                                type="text"
                                className="form-control"
                                value={missionName}
                                readOnly
                                required
                            /> */}

                    </div>

                    <div className="form-group">
                        <label >{props.isConclave ? 'Conclave ' : 'Team '}Name<span className="required-star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            value={teamName}
                            required
                            onChange={(e) => { setTeamName(e.target.value); setError({ ...error, teamName: '' }); }}
                        />
                        {error && <div className="danger">{error.teamName}</div>}
                    </div>
                    {props.isConclave &&
                        <div className="form-group checkbox-group">
                            <input
                                type="checkbox"
                                checked={props.isConclave}
                                className="form-check-input pointer"
                                disabled
                            />
                            <label> Conclave</label>
                        </div>}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="btn-text" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" size="sm" onClick={() => createNewTeam()} >Create team</Button>
                </Modal.Footer>

            </Modal>
        </>);
    };


    const EditUserRow = (props) => {
        const [show, setShow] = React.useState(false);
        const [localUser, setLocalUser] = React.useState(props.user);
        const [localTeams, setLocalTeams] = React.useState(teams);
        const [localPreferredMission, setLocalPreferredMission] = React.useState(localUser.preferredMission);

        const updateUser = (e) => {
            if (localUser.preferredMission == null || localUser.preferredMission == '' || localUser.preferredMission == undefined)
                localUser.preferredMission = 0;
            e.preventDefault();
            ProgramSetupAxios.put(`/EligiblePool/updateeligibleusers`, [localUser])
                .then(() => {
                    getSAPUsers();
                    setShow(false);
                }).catch(err => {
                    console.log("updateUser: err", err);
                });
        };

        useEffect(() => {
            // console.log("localUser", localUser);
        }, [localUser]);

        const updateLocalTeam = (item) => {
            setLocalTeams([...localTeams, item]);
        }


        return (
            <>
                <Button onClick={() => setShow(true)} className="btn-text" size="sm" variant="outline-primary" disabled={localUser.isOfferingUserCreated && localUser.userId !== '0'}>
                    <i className="fa fa-pencil" />
                </Button>
                <Modal
                    show={show}
                    centered
                    className="admin-modal"
                    size="lg"
                >
                    <form onSubmit={updateUser}>
                        <Modal.Header>
                            <Modal.Title>Edit User - <strong>{localUser.firstName + " " + localUser.lastName} ({roleLabels[localUser.role]})</strong> </Modal.Title>
                            <div className="close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="admin-modal">
                            {/* {JSON.stringify(props.user)} */}
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" value={localUser.email || ''} className="form-control" readOnly />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="role">Role</label>
                                    <select className="form-control" readOnly value={localUser.role}>
                                        {
                                            Object.keys(roleLabels).map((key, index) => {
                                                return <option key={index} value={key}>{roleLabels[key]}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="role">Preferred Mission</label>
                                    <select
                                        className="form-control"
                                        value={localPreferredMission}
                                        onChange={(e) => {
                                            setLocalUser({ ...localUser, preferredMission: e.target.value });
                                            setLocalPreferredMission(e.target.value);
                                            // setLocalUser({ ...localUser, teamId: '' });
                                        }}

                                    >
                                        <option value={0}>Select Mission</option>
                                        {
                                            preferredMission.map((mission, index) => {
                                                return <option key={index} value={mission.id}>{mission.missionName}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="role">Team</label>
                                    <div className="d-flex align-items-center gap-2">

                                        <select
                                            className="form-control"
                                            value={localUser.teamId}
                                            onChange={(e) => {
                                                setLocalUser({ ...localUser, teamId: e.target.value });
                                            }}
                                        >
                                            <option value="">Select team</option>
                                            {
                                                localTeams.filter(item => item.missionId == localUser.preferredMission).map((team, index) => {
                                                    if (!team.isConclave) {
                                                        return <option key={index} value={team.id} >{team.teamName}</option>;
                                                    }
                                                })
                                            }
                                        </select>
                                        <AddTeam
                                            offeringId={localUser.offeringId}
                                            updateTeam={updateLocalTeam}
                                            selectedMIssion={localPreferredMission}
                                        />

                                    </div>
                                </div>
                                {localUser.role == 5 && <div className="form-group col-6">
                                    <label htmlFor="role">Conclave</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <select
                                            className="form-control"
                                            value={localUser.conclaveId}
                                            onChange={(e) => {
                                                setLocalUser({ ...localUser, conclaveId: e.target.value });
                                            }}
                                        >
                                            <option value="">Select conclave</option>
                                            {
                                                localTeams.filter(item => item.isConclave && item.offeringId === user.selectedOffering).map((team, index) => {
                                                    return <option key={index} value={team.id} >{team.teamName}</option>;

                                                })
                                            }
                                        </select>
                                        <AddTeam
                                            isConclave={true}
                                            offeringId={localUser.offeringId}
                                            updateTeam={updateLocalTeam}
                                            selectedMIssion={localPreferredMission} />
                                    </div>
                                </div>}

                                <div className="form-group col-6 checkbox-group">
                                    <input
                                        id="approveCheckbox"
                                        type="checkbox"
                                        checked={localUser.isApproved}
                                        value={localUser.isApproved}
                                        className="form-check-input pointer"
                                        onChange={(e) => { setLocalUser({ ...localUser, isApproved: e.target.checked }); }} />
                                    <label htmlFor="approveCheckbox"> Approve user</label>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer >
                            {isPublished &&
                                <div className="flex-auto text-warning">The offering is published. You have to republish to make changes visible.</div>
                            }
                            <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                            <Button variant="primary" type="submit">Update</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    };

    const ApprovedCheckBox = (props) => {
        const [user, setUser] = React.useState(props.user);
        const [isChecked, setIsChecked] = React.useState(false);
        const [showAlert, setShowAlert] = React.useState(false);
        const [alertMessage, setAlertMessage] = React.useState('Before approving the user, please select the team and preferred mission.');

        useEffect(() => {
            setIsChecked(user.isApproved);
        }, [user]);

        const handleChange = (event) => {
            if (user.teamId <= 0 || user.preferredMission <= 0) {
                setShowAlert(true);
                return;
            }

            if (isPublished && event.target.checked) {
                setShowAlert(true);
                setAlertMessage('The offering is published. You have to republish to make changes visible.');
            }

            setIsChecked(event.target.checked);
            let newUser = { ...user, isApproved: event.target.checked };
            setUser(newUser);

            if (newUser.preferredMission == null || newUser.preferredMission == '' || newUser.preferredMission == undefined)
                newUser.preferredMission = 0;

            ProgramSetupAxios.put(`/EligiblePool/updateeligibleusers`, [newUser])
                .then(() => {
                    // console.log("User updated successfully.");
                }).catch(err => {
                    console.log("updateUser: err", err);
                });
        };


        return (<>
            <input
                className="form-check-input pointer"
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                disabled={user.isOfferingUserCreated && user.userId !== '0'}
            />
            <ConfirmDialog
                open={showAlert}
                title="Alert"
                size={'md'}
                message={alertMessage}
                confirmText="Ok"
                onConfirm={() => setShowAlert(false)}
            />
        </>
        );
    };

    return (
        <>
            <div className="page-header mb-4">
                <h1>Team Allocation</h1>
                <div className="page-actions">
                    <AutoMaticTeamAlloation getSAPUsers={getSAPUsers} />
                    <BulkAction
                        selectedRows={selectedRows}
                        usersList={usersList}
                        localMissions={preferredMission.map((x) => {
                            return { value: x.id, label: x.missionName };
                        })}
                        localTeams={teams}
                        onSuccess={getSAPUsers}
                    />
                    <TeamManagement missions={preferredMission} />
                    <ExportExcel jsonObject={usersListExport} isLoading={isLoadingExport} columns={tableData.columns} fileName="EligiblePool.csv" />

                </div>
            </div>
            <div className="data-table">
                <DataTable apiPagination={true} loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} setSelectedRows={setSelectedRows} applyFilters={getSAPUsers} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} totalCount={totalCount} />
            </div>
        </>
    );
};

export default TeamAllocation;
