import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";
import AwardCategory from "../Settings/AwardCategory";
import RatingTypes from "./RatingTypes";


const Ratings = (props) => {
    const user = useContext(UserContext);
    const [offeringRatingsList, setOfferingRatingsList] = React.useState([]);
    const [offeringRatingTypeList, setOfferingRatingTypeList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localRating, setLocalRating] = React.useState([]);
    const [awardCategories, setAwardCategories] = React.useState([]);
    const [selectedAwardCategory, setSelectedAwardCategory] = React.useState([]);
    const [selectedRatingCategoryList, setSelectedRatingCategoryList] = React.useState([]);

    useEffect(() => {
        setLoaderShow(true);
        getRatings();
        getAwardCategory();
    }, [user.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [selectedRatingCategoryList]);

    useEffect(() => {
        onCategoryChange(selectedAwardCategory);
    }, [offeringRatingsList])

    const getAwardCategory = () => {
        ProgramSetupAxios.get("/SolutionTag/getsolutiontags/" + user.selectedOffering)
            .then(response => {
                setAwardCategories(response.data);
                setSelectedAwardCategory(response.data[0].id);

            })
            .catch(error => {
                console.log(error);
            });
    }

    const getRatings = () => {

        ProgramSetupAxios.get("/OfferingRatings/offeringratings/" + user.selectedOffering)
            .then(function (response) {
                setOfferingRatingsList(response.data.ratings.sort((a, b) => a.sortOrder - b.sortOrder));
                setOfferingRatingTypeList(response.data.ratingTypes.sort((a, b) => a.sortOrder - b.sortOrder))

                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });



    };

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Rating Name",
                field: 'ratingName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Rating Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',


            },
            {
                label: "Description",
                field: 'description',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Description',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Max Rating",
                field: 'maxRating',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Max Rating',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Is Overall Ratings",
                field: 'isOverallRatings',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Is Overall Ratings',
                },
                sort: false,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'edit-button-align text-center',
                },
            },
        ];
        let rows = [];
        selectedRatingCategoryList.forEach((rating, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.ratingName = { value: rating.name, label: rating.name, export: rating.name };
            row.description = { value: rating.description, label: rating.description, export: rating.description };
            row.maxRating = { value: rating.maxRating, label: rating.maxRating, export: rating.maxRating };
            row.sequence = { value: rating.sortOrder, label: rating.sortOrder, export: rating.sortOrder };
            row.isOverallRatings = { value: rating.isOverallRating, label: rating.isOverallRating ? "Yes" : "No", export: rating.isOverallRating }
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button onClick={() => { setShow(true); setLocalRating(rating); }} variant="outline-primary" size="sm" >
                            <i className="fa fa-pencil" />
                        </Button >
                        {/* <DeleteLink {...rating} /> */}
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addRatings = (e) => {
        e.preventDefault();
        let data = {
            offeringId: user.selectedOffering,
            missionId: 0,
            name: localRating.name,
            description: localRating.description,
            maxRating: localRating.maxRating,
            sortOrder: localRating.sortOrder,
            isOverallRating: true
        }

        ProgramSetupAxios.post(`/OfferingRatings/createtofferingratings`, [data])
            .then(() => {
                getRatings();
                setShow(false);
            }).catch(err => {
                console.log("AddLevel: err", err);
            });


    }


    const updateRatings = (e) => {
        e.preventDefault();
        ProgramSetupAxios.put(`/OfferingRatings/updateofferingrating`, localRating)
            .then(() => {
                getRatings();
                setShow(false);
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    };

    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: link.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingRatings/deleteofferingratings", [data.id])
                .then(function () {
                    getRatings();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };

        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Category</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this category?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const onCategoryChange = (categoryId) => {
        setSelectedAwardCategory(categoryId);
        var tempRatingType = offeringRatingsList.filter(x => x.tagId == categoryId);
        setSelectedRatingCategoryList(tempRatingType.sort((a, b) => a.sortOrder - b.sortOrder))


    }
    return (
        <>
            <AwardCategory awardCategories={awardCategories} getAwardCategory={getAwardCategory} />
            <div className="page-header mb-4">
                <h4>Rating Categories</h4>

            </div>
            <div className="d-flex mb-4 align-items-baseline">
                <h5 className="me-3">Award Category :</h5>

                <div className="d-inline-block align-items-center  ">

                    <select
                        className="form-control"
                        value={selectedAwardCategory}
                        onChange={(e) => {
                            // setLocalActivityFeedback({ ...selectedActivityCategory, isActive: e.target.value });
                            onCategoryChange(e.target.value)

                        }}
                        required>
                        {awardCategories.map(awardCategory => {
                            return <option key={awardCategory.id} value={awardCategory.id} >{awardCategory.tagName}</option>
                        })
                        }

                    </select>

                </div>
            </div>
            <div className="data-table">
                <DataTable {...tableData} multiSelect={false} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localRating.id != 0 && localRating.id ? updateRatings(e) : addRatings(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localRating.id != 0 && localRating.id ? "Edit" : "Add"} Rating Category</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="levelName">Rating Name<span className="required-star">*</span></label>
                                <input type="text" maxLength={200} value={localRating.name || ''} className="form-control" onChange={(e) => {
                                    setLocalRating({ ...localRating, name: e.target.value });
                                }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="levelKey">Description<span className="required-star">*</span></label>
                                <input type="text" maxLength={200} value={localRating.description || ''} className="form-control" onChange={(e) => {
                                    setLocalRating({ ...localRating, description: e.target.value });
                                }}
                                    required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="role">Max Rating<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={localRating.maxRating || ''} className="form-control"
                                    onChange={(e) => {
                                        setLocalRating({ ...localRating, maxRating: e.target.value });
                                    }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Sequence<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={localRating.sortOrder || ''} className="form-control"
                                    onChange={(e) => {
                                        setLocalRating({ ...localRating, sortOrder: e.target.value });
                                    }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Is Overall Rating<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localRating.isOverallRating}
                                        onChange={(e) => {
                                            setLocalRating({ ...localRating, isOverallRating: e.target.value });
                                        }}
                                        required>
                                        <option key={1} value={1} >Yes</option>
                                        <option key={2} value={0} >No</option>
                                    </select>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" >{localRating.id != 0 && localRating.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <RatingTypes offeringRatingTypeList={offeringRatingTypeList} getRatings={getRatings} />
        </>
    )
}

export default Ratings;