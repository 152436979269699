export const LINK_TYPE = {
    notSpecified: 0,
    imagePath: 1,
    videoPath: 2,
    quickLink: 3,
    referenceMaterial: 4,
    connect: 5,
    own: 6,
    solve: 7,
    communicate: 8,
    onboardingVideo: 9,
    coachOnlyReferenceMaterial: 10,
};

export const ACTIVITY_TYPE = {

    notSpecified: 0,

    meeting: 1,

    teamDiscussion: 2,

    individualAssignment: 3,

    selfReflection: 5,

    coachOnly: 6,

    solutionSubmit: 4
}

export const POINT_TYPE = {
    notSpecified: 0,
    experience: 1,
    bonus: 2
}