import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ProgramSetupAxios } from "../../../API";
import UserContext from "../../../UserContext";
import StageSetup from "./StageSetup";


const StageGroup = (props) => {
    const user = useContext(UserContext);
    const [key, setKey] = React.useState(1);
    const [missionData, setMissionData] = useState([]);
    useEffect(() => {
        getMissionByOfferingId()
    }, [user.selectedOffering])

    const getMissionByOfferingId = () => {
        ProgramSetupAxios.get("OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setMissionData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleChange = (index, data) => {
        let tempData = JSON.parse(JSON.stringify(missionData));
        tempData[index] = data;
        setMissionData(tempData);
    };
    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            {missionData && missionData.map((mission, index) => {
                return <Tab eventKey={index + 1} title={mission.missionName} >
                    <StageSetup index={index} mission={mission} missionData={missionData} missionOne={missionData[0]} setMissionData={handleChange} getMissionByOfferingId={getMissionByOfferingId} />
                </Tab>
            }
            )}

        </Tabs >
    );

}

export default StageGroup;