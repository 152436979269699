import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import ActivityFeedbackTypes from "./ActivityFeedbackTypes";
import RatingTypes from "./RatingTypes";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UserContext from "../../UserContext";


const ActivityFeedback = (props) => {
    const user = useContext(UserContext);
    const [offeringActivityFeedbackList, setOfferingActivityFeedbackList] = React.useState([]);
    const [offeringActivityFeedbackTypeList, setOfferingActivityFeedbackTypeList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localActivityFeedback, setLocalActivityFeedback] = React.useState([]);
    const [selectedActivityCategory, setSelectedActivityCategory] = React.useState([]);



    useEffect(() => {
        setLoaderShow(true);
        getActivityFeedbacks();
    }, [user.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [offeringActivityFeedbackList]);


    const getActivityFeedbacks = () => {

        ProgramSetupAxios.get("/OfferingActivityFeedback/getofferingactivityfeedback/" + user.selectedOffering)
            .then(function (response) {
                setOfferingActivityFeedbackList(response.data?.sort((a, b) => a.sortOrder - b.sortOrder));
                setOfferingActivityFeedbackTypeList(response.data[0].offeringActivityFeedbackTypes?.sort((a, b) => a.sortOrder - b.sortOrder));
                setSelectedActivityCategory(response.data[0]);
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });


    };

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Name",
                field: 'name',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',


            },
            {
                label: "Description",
                field: 'description',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Description',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            // {
            //     label: "Is Active",
            //     field: 'isActive',
            //     attributes: {
            //         'aria-controls': 'DataTable',
            //         'aria-label': 'Is Overall Ratings',
            //     },
            //     sort: false,
            //     filter: true,
            //     filterType: 'text',
            // },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'edit-button-align text-center',
                },

            },
        ];
        let rows = [];
        offeringActivityFeedbackList.forEach((activityFeedback, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.name = { value: activityFeedback.name, label: activityFeedback.name, export: activityFeedback.name };
            row.description = { value: activityFeedback.description, label: <div dangerouslySetInnerHTML={{ __html: activityFeedback.description }} />, export: activityFeedback.description };
            row.sequence = { value: activityFeedback.sortOrder, label: activityFeedback.sortOrder, export: activityFeedback.sortOrder };
            // row.isActive = { value: activityFeedback.isOverallRating, label: activityFeedback.isOverallRating ? "Yes" : "No", export: activityFeedback.isOverallRating }
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button onClick={() => { setShow(true); setLocalActivityFeedback(activityFeedback); }} variant="outline-primary" size="sm" >
                            <i className="fa fa-pencil" />
                        </Button >
                        {/* <DeleteLink {...activityFeedback} /> */}
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addActivityFeedbacks = () => {

        let data = {
            offeringId: user.selectedOffering,
            name: localActivityFeedback.name,
            description: localActivityFeedback.description,
            sortOrder: localActivityFeedback.sortOrder
        }

        ProgramSetupAxios.post(`/OfferingActivityFeedback/createtofferingactivityfeedback`, [data])
            .then(() => {
                getActivityFeedbacks();
                setShow(false);
            }).catch(err => {
                console.log("err", err);
            });


    }


    const updateActivityFeedbacks = () => {

        ProgramSetupAxios.put(`/OfferingActivityFeedback/updateactivityfeedback`, localActivityFeedback)
            .then(() => {
                getActivityFeedbacks();
                setShow(false);
            }).catch(err => {
                console.log("err", err);
            });
    };


    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: link.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingActivityFeedback/deleteofferingactivityfeedback", [data.id])
                .then(function () {
                    getActivityFeedbacks();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Rating Category</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this rating category?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Rating Categories</h4>
                {/* <Button onClick={() => {
                    setShow(true); setLocalActivityFeedback({
                        offeringId: user.selectedOffering,
                        name: "",
                        description: "",
                        sortOrder: 0
                    });
                }} className="btn-sm">
                    + Add Rating Category
                </Button> */}
            </div>
            <div className="data-table">
                <DataTable {...tableData} multiSelect={false} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                {/* <form onSubmit={() => { localActivityFeedback.id != 0 && localActivityFeedback.id ? updateActivityFeedbacks() : addActivityFeedbacks() }}> */}
                <Modal.Header>
                    <Modal.Title>{localActivityFeedback.id != 0 && localActivityFeedback.id ? "Edit" : "Add"} Rating Category</Modal.Title>
                    <div className="close" onClick={() => setShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </div>
                </Modal.Header>
                <Modal.Body className="admin-modal">
                    <div className="row">
                        <div className="form-group col-6">
                            <label htmlFor="levelName">Name<span className="required-star">*</span></label>
                            <input type="text" maxLength={200} value={localActivityFeedback.name || ''} className="form-control" onChange={(e) => {
                                setLocalActivityFeedback({ ...localActivityFeedback, name: e.target.value });
                            }} required />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="role">Sequence<span className="required-star">*</span></label>
                            <input type="number" min={0} max={9999} value={localActivityFeedback.sortOrder || ''} className="form-control"
                                onChange={(e) => {
                                    setLocalActivityFeedback({ ...localActivityFeedback, sortOrder: e.target.value });
                                }} required />
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-12">
                            <label htmlFor="levelKey">Description<span className="required-star">*</span></label>
                            {/* <input type="text" value={localActivityFeedback.description || ''} className="form-control" onChange={(e) => {
                                    setLocalActivityFeedback({ ...localActivityFeedback, description: e.target.value });
                                }}
                                    required /> */}
                            <CKEditor
                                className={"mission-full-width"}
                                editor={ClassicEditor}
                                data={localActivityFeedback.description}
                                required
                                config={{
                                    placeholder: "Enter value",
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setLocalActivityFeedback({ ...localActivityFeedback, description: data });
                                    // console.log({ event, editor, data });
                                }}

                            />
                        </div>
                        {/* <div className="form-group col-6">
                                <label htmlFor="role">Is Active</label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localActivityFeedback.isActive}
                                        onChange={(e) => {
                                            setLocalActivityFeedback({ ...localActivityFeedback, isActive: e.target.value });
                                        }}
                                        required>
                                        <option key={1} value={1} >Yes</option>
                                        <option key={2} value={0} >No</option>
                                    </select>

                                </div>
                            </div> */}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                    <Button variant="primary" type="submit" onClick={() => { localActivityFeedback.id != 0 && localActivityFeedback.id ? updateActivityFeedbacks() : addActivityFeedbacks() }}>{localActivityFeedback.id != 0 && localActivityFeedback.id ? "Update" : "Add"}</Button>
                </Modal.Footer>
                {/* </form> */}
            </Modal>
            <ActivityFeedbackTypes offeringActivityFeedbackTypeList={offeringActivityFeedbackTypeList} getActivityFeedbacks={getActivityFeedbacks} offeringActivityFeedbackList={offeringActivityFeedbackList} selectedActivityCategory={selectedActivityCategory} />
        </>
    )
}

export default ActivityFeedback;