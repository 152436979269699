import React, { useState, useEffect, useContext } from "react";
import ExportExcel from "../Exports/ExportExcel";
import DataTable from "../DataTable/DataTable";
import { NetworkAxios } from "../../API";
import UserContext from "../../UserContext";

const SkillTrajectory = (props) => {
    const user = useContext(UserContext);
    const [tableData, setTableData] = useState({});
    const [filters, setFilters] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [commentsLikeData, setCommentsLikeData] = useState([]);
    const [selectedOfferingName, setSelectedOfferingName] = useState();
    const [loaderShow, setLoaderShow] = React.useState(false);

    useEffect(() => {
        setLoaderShow(true);
        if (user.offeringList && user.selectedOffering) {
            var tempName = user.offeringList.filter(x => x.id == user.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getCommentsLikeReport();
        }
    }, [user.selectedOffering, user.offeringList]);


    useEffect(() => {
        generateTableData();
    }, [commentsLikeData]);

    const getCommentsLikeReport = () => {

        NetworkAxios.get("/SolutionComment/getallcommentlikecount/" + user.selectedOffering)
            .then(function (response) {

                if (response && response.data && response.data.length > 0) {
                    setCommentsLikeData(response.data);
                }
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

    }

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "User Name",
                field: 'userName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'User Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "User Email",
                field: 'userEmail',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'User Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Team Name",
                field: 'teamName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Name',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Mission Name",
                field: 'missionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Comments on Solve Tab",
                field: 'solveTabCommentCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Comments on Solve Tab',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Comments on Forums",
                field: 'forumCommentCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Comments on Forums',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Comments on Gallery",
                field: 'solutionCommentCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Comments on Gallery',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Likes on Solve Tab",
                field: 'solveTabLikeCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Likes on Solve Tab',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Likes on Forums",
                field: 'forumLikeCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Likes on Forums',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Likes on Gallery",
                field: 'solutionLikeCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Likes on Gallery',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Shares on Gallery",
                field: 'solutionShareCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': ' Shares on Gallery',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
        ];
        let rows = [];
        commentsLikeData.forEach((commentsLike) => {
            let row = {};
            let offeringName = selectedOfferingName;

            row.id = { value: commentsLike.offeringUserId, label: commentsLike.offeringUserId, export: commentsLike.offeringUserId };
            row.teamName = { value: commentsLike.teamName, label: commentsLike.teamName, export: commentsLike.teamName };
            row.userName = { value: commentsLike.userName, label: commentsLike.userName, export: commentsLike.userName };
            row.userEmail = { value: commentsLike.userEmail, label: commentsLike.userEmail, export: commentsLike.userEmail };
            row.missionName = {
                value: commentsLike.missionName,
                label: commentsLike.missionName,
                export: commentsLike.missionName
            };
            row.solveTabCommentCount = { value: commentsLike.solveTabCommentCount, label: commentsLike.solveTabCommentCount, export: commentsLike.solveTabCommentCount };
            row.forumCommentCount = { value: commentsLike.forumCommentCount, label: commentsLike.forumCommentCount, export: commentsLike.forumCommentCount };
            row.solutionCommentCount = { value: commentsLike.solutionCommentCount, label: commentsLike.solutionCommentCount, export: commentsLike.solutionCommentCount };
            row.solveTabLikeCount = { value: commentsLike.solveTabLikeCount, label: commentsLike.solveTabLikeCount, export: commentsLike.solveTabLikeCount };
            row.forumLikeCount = { value: commentsLike.forumLikeCount, label: commentsLike.forumLikeCount, export: commentsLike.forumLikeCount };
            row.solutionLikeCount = { value: commentsLike.solutionLikeCount, label: commentsLike.solutionLikeCount, export: commentsLike.solutionLikeCount };
            row.solutionShareCount = { value: commentsLike.solutionShareCount, label: commentsLike.solutionShareCount, export: commentsLike.solutionShareCount };

            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    return (<>
        <div className="page-header mb-4">
            <h4>Comments & Likes Report</h4>
            <div className="page-actions">
                <ExportExcel jsonObject={filterData} columns={tableData.columns} fileName="CommentsLike.csv" />
            </div>
        </div>
        <div className="data-table">
            <DataTable loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
        </div>
    </>);
}

export default SkillTrajectory;