import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = (props) => {


    return (
        <div className="loader-background">
            <Spinner animation="border" size="lg" />
        </div>)
}

export default Loader;