import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
const ConfirmDialog = ({ open, title, message, onConfirm, onCancel, confirmText, cancelText, size, confirmVarient }) => {
    const [disableSubmit, setDisableSubmit] = React.useState(false);

    useEffect(() => {
        setDisableSubmit(false);
    }, [open]);

    return (
        <Modal
            show={open}
            onHide={onCancel}
            backdrop="static"
            centered
            size={size}
        >
            <Modal.Header >
                <Modal.Title>{title}</Modal.Title>
                {onCancel &&
                    <div className="close" onClick={onCancel}>
                        <span aria-hidden="true">&times;</span>
                    </div>}
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                {onCancel && <Button variant='link' className='btn-text' onClick={onCancel}>{cancelText}</Button>}
                <Button className='confirm-btn' variant={confirmVarient} size='sm' onClick={() => { onConfirm(); setDisableSubmit(true) }} disabled={disableSubmit} >{confirmText}</Button>
            </Modal.Footer>
        </Modal>
    )
};

ConfirmDialog.defaultProps = {
    title: 'Confirm',
    message: 'Are you sure?',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    confirmVarient: 'primary',
    size: 'md'
}
export default ConfirmDialog;