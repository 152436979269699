import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Links from "./Links";

const LinkGroup = (props) => {

    const [key, setKey] = React.useState('onboarding');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="onboarding" title="Onboarding">
                <Links label={"Onboarding"} group={"onboarding"} />
            </Tab>
            <Tab eventKey="journey" title="Journey">
                <Links label={"Journey"} group={"journey"} />
            </Tab>
            <Tab eventKey="importantLinks" title="Important Links">
                <Links label={"Important Links"} group={"importantLinks"} />
            </Tab>
        </Tabs >
    );

}


export default LinkGroup