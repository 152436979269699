import React, { useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { OnboardingAxios, ProgramSetupAxios, ConfigurationAxios, IAMAxios, NetworkAxios, UserActivityAxios } from "../../API";
import UserContext from "../../UserContext";
import { generalDateFormat } from "../../util";
import Tooltips from "./Tooltip";

const AdminHeader = (props) => {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log("userDetails", user);
        // if user is empty, get user from local storage
        if (!user.firstName) {
            let userData = localStorage.getItem("user")
            user.setUser(JSON.parse(userData));
        }
    }, [user]);

    useEffect(() => {
        var tempToken = JSON.parse(localStorage.getItem('items'));
        if (tempToken && tempToken.accessToken !== undefined) {
            IAMAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
            ConfigurationAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
            ProgramSetupAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
            OnboardingAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
            NetworkAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
            UserActivityAxios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.accessToken}`;
        }
    }, []);

    const OfferingMenu = (props) => {

        const handleSelection = (id) => {
            let selectedOffering = user.selectedOffering;
            localStorage.setItem("selectedOffering", id);
            if (selectedOffering !== id) {
                user.setSelectedOffering(id);
                navigate("/dashboard", { replace: true })
            } else {
                console.log("Already selected");
            }
        }

        return (
            <Dropdown className="header-dropdon">
                <Dropdown.Toggle variant="success" id="dropdown-basic" as="div">
                    {user.offeringList.find(x => x.id === user.selectedOffering)
                        ? user.offeringList.find(x => x.id === user.selectedOffering).programName
                        : "Select Offering"
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Header>Recent</Dropdown.Header>
                    {user.offeringList.sort((a, b) => { return b.id - a.id; }).slice(0, 5)
                        .map((offering, index) => {
                            return (
                                <Dropdown.Item
                                    key={index} as="div"
                                    onClick={() => handleSelection(offering.id)}>
                                    <div className="info">
                                        <div className="title">
                                            {offering.programName}
                                        </div>
                                        <div className="sub-heading">Start Date: {generalDateFormat(offering.startDate)}</div>
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                    <Dropdown.Divider />
                    <Dropdown.Item key={'offering-list-link'} as="div" onClick={() => navigate("/offering-list", { replace: true })}>
                        <div className="info">
                            <div className="title">View All Offerings</div>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown >
        )
    }

    return (
        <div className="admin-header">
            <div className="header-left">
                <div className="menu-icon" onClick={props.toggleSidebar}>
                    <i className='fa fa-bars' />
                </div>
                {!props.hideOfferingselect && <OfferingMenu offeringList={user.offeringList} />}
                {!props.hideOfferingselect && <>
                    <Tooltips text="Publish status" placement="bottom">
                        {user.isPublished ? <i className="fa fa-check fa-1 success" /> : <i className="fa fa-times fa-1" />}
                    </Tooltips>
                </>}
            </div>
            <div className="admin-profile">

                <div className="avatar">
                    {Boolean(user.profileImage) && user.profileImage !== "NA" ? (
                        <img
                            src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg"
                            style={{ width: "100%" }}
                            alt=""
                        />
                    ) : (
                        user.firstName && user.firstName[0].toUpperCase() + user.lastName && user.lastName[0].toUpperCase()
                    )}
                </div>
                <div className="user-info">
                    <span className="user-name">{user.firstName + " " + user.lastName}</span>
                    <span className="user-role">
                        {user.roleId === 1 && "Super Admin"}
                        {user.roleId === 2 && "Program Manager"}
                        {user.roleId === 3 && "Program Administrator"}
                    </span>

                </div>
            </div>
        </div>
    );
};

export default AdminHeader;
