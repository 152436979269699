import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";

const ProfileMetaData = (props) => {
    const user = useContext(UserContext);
    const [offeringProfileDataList, setOfferingProfileList] = React.useState([]);

    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localProfile, setLocalProfile] = React.useState([]);

    useEffect(() => {
        setLoaderShow(true);
        getProfileData();
    }, [user.selectedOffering]);

    useEffect(() => {

        generateTableData();
    }, [offeringProfileDataList]);


    const getProfileData = () => {
        ProgramSetupAxios.get("/ProfileMetadata/getprofilemetadata/" + user.selectedOffering)
            .then(function (response) {
                setOfferingProfileList(response.data.sort((a, b) => a.sequence - b.sequence));
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    };

    const generateTableData = () => {
        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'number',
            },
            // {
            //     label: "Profile Key",
            //     field: 'profileKey',
            //     attributes: {
            //         'aria-controls': 'DataTable',
            //         'aria-label': 'Profile Key',
            //     },
            //     sort: true,
            //     filter: true,
            //     filterType: 'number',
            // },
            {
                label: "Question Text",
                field: 'value',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'value',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Category",
                field: 'category',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'category',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Answer Type",
                field: 'valueTypeName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Value Type Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Value Type Name',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: user.isPublished ? 'text-center locked' : 'text-center',
                }
            },
        ];
        let rows = [];
        offeringProfileDataList.forEach((profile, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            // row.profileKey = { value: profile.profileKey, label: profile.profileKey, export: profile.profileKey };
            row.value = { value: profile.value, label: profile.value, export: profile.value };
            row.category = { value: profile.category, label: profile.category, export: profile.category };
            row.valueTypeName = { value: profile.valueTypeName, label: profile.valueTypeName, export: profile.valueTypeName };
            row.sequence = { value: profile.sequence, label: profile.sequence, export: profile.sequence };

            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button className="btn-text btn-sm" variant="outline-primary" onClick={() => { setShow(true); setLocalProfile(profile); }}><i className="fa fa-pencil" /></Button>
                        <DeleteProfile {...profile} />
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    const DeleteProfile = (profile) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: profile.id,
                ...profile,
                isActive: false
            }

            ProgramSetupAxios.post("/ProfileMetadata/deleteprofilemetadata", [data.id])
                .then(function () {
                    getProfileData();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Link</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this link?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const addProfile = (e) => {
        e.preventDefault();
        var valueTypeName = "";
        if (localProfile.valueType == 1) {
            valueTypeName = "Text";
        } else if (localProfile.valueType == 2) {
            valueTypeName = "Multi-Select";
        } else {
            valueTypeName = "Single-Select";
        }
        let data = {
            offeringId: user.selectedOffering,
            profileKey: localProfile.profileKey,
            value: localProfile.value,
            category: localProfile.category,
            valueType: localProfile.valueType,
            valueTypeName: valueTypeName,
            options: localProfile.options,
            sequence: localProfile.sequence
        }

        ProgramSetupAxios.post(`/ProfileMetadata/profilemetadata`, [data])
            .then(() => {
                getProfileData();
                setShow(false);
            }).catch(err => {
                console.log("AddLevel: err", err);
            });
    }


    const updateProfile = (e) => {
        e.preventDefault();
        var valueTypeName = "";
        if (localProfile.valueType == 1) {
            valueTypeName = "Text";
        } else if (localProfile.valueType == 2) {
            valueTypeName = "Multi-Select";
        } else {
            valueTypeName = "Single-Select";
        }
        let data = {
            id: localProfile.id,
            offeringId: user.selectedOffering,
            profileKey: localProfile.profileKey,
            value: localProfile.value,
            category: localProfile.category,
            valueType: localProfile.valueType,
            valueTypeName: valueTypeName,
            options: localProfile.options,
            sequence: localProfile.sequence
        }
        ProgramSetupAxios.put(`/ProfileMetadata/updateprofilemetadata`, data)
            .then(() => {
                getProfileData();
                setShow(false);
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    };

    const AnswerOptions = (props) => {
        const [options, setOptions] = useState([]);
        const [optionFirst, setOptionFirst] = useState("");
        const [optionSecond, setOptionSecond] = useState("");
        const [selectedIndex, setSelectedIndex] = useState(-1);
        const [valueType, setValueType] = useState(2);

        useEffect(() => {
            if (isJsonString(props.localProfile.options)) {
                let options = JSON.parse(props.localProfile.options);
                // console.log("options", options);
                if (isJsonString(options)) {
                    setOptions(JSON.parse(options));
                } else {
                    setOptions(options);
                }
            }
            // setOptions(options);
            setValueType(props.localProfile.valueType);
        }, [props.localProfile]);

        // useEffect(() => {
        //     // props.setLocalProfile({ ...props.localProfile, options: JSON.stringify(options) });
        //     // props.updateOptions && props.updateOptions({ ...props.localProfile, options: options });
        //     console.log("options", options);
        // }, [options]);

        useEffect(() => {
            if (selectedIndex > -1) {
                let option = options[selectedIndex];

                if (valueType == 2) {
                    setOptionFirst(option);
                } else if (valueType == 3) {
                    if (Array.isArray(option)) {
                        setOptionFirst(option[0]);
                        setOptionSecond(option[1]);
                    } else {
                        setOptionFirst(option);
                        setOptionSecond("");
                    }
                }
            }
        }, [selectedIndex]);

        function isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        const handleAddOption = (e) => {
            e.preventDefault();
            let updateOptions = options ? [...options] : [];
            if (selectedIndex >= 0) {
                if (valueType == 2) {
                    updateOptions[selectedIndex] = optionFirst;
                    setOptionFirst("");
                } else if (valueType == 3) {
                    let option = [];
                    option.push(optionFirst);
                    option.push(optionSecond);
                    updateOptions[selectedIndex] = option;
                    setOptionFirst("");
                    setOptionSecond("");
                }
            } else {
                if (valueType == 2) {
                    if (optionFirst != "") {
                        updateOptions.push(optionFirst);
                        setOptionFirst("");
                    }
                } else if (valueType == 3) {
                    if (optionFirst != "" && optionSecond != "") {
                        let option = [];
                        option.push(optionFirst);
                        option.push(optionSecond);
                        updateOptions.push(option);
                        setOptionFirst("");
                        setOptionSecond("");
                    }
                }
            }

            setSelectedIndex(-1);
            setOptions(updateOptions);
            props.updateOptions && props.updateOptions({ ...props.localProfile, options: JSON.stringify(updateOptions) });
        }


        return (
            <>
                <div className="row mb-2 align-items-center">
                    <div className="col">
                        <input type="text" className="form-control" value={optionFirst} onChange={(e) => setOptionFirst(e.target.value)}
                            required={options ? false : true} />
                    </div>
                    {valueType == 3 &&
                        <div className="col">
                            <input type="text" className="form-control" value={optionSecond} onChange={(e) => setOptionSecond(e.target.value)} />
                        </div>
                    }
                    <div className="col-1">
                        <Button onClick={handleAddOption} variant="primary" >
                            <i className="fa fa-floppy-o" />
                        </Button>
                    </div>
                </div>
                <div className="chips-group">
                    {/* {JSON.stringify(options)} */}
                    {options && options.map((option, index) => {
                        if (valueType == 3) {
                            if (Array.isArray(option)) {
                                return <div key={index} className="chips-group-wrapper info" onClick={() => setSelectedIndex(index)}>
                                    {option.map((option, index) => {
                                        return <div key={index} className="chips">
                                            {option}
                                        </div>
                                    })}
                                    <i className="fa fa-times chip-clear m-0" onClick={() => {
                                        let options = JSON.parse(localProfile.options);
                                        options.splice(index, 1);
                                        setLocalProfile({ ...localProfile, options: JSON.stringify(options) });
                                    }} />
                                </div>
                            } else {
                                return <div className="chips-group-wrapper info" onClick={() => setSelectedIndex(index)} key={index}>
                                    <div className="chips">
                                        {option}
                                        <i className="fa fa-times chip-clear" onClick={() => {
                                            let options = JSON.parse(localProfile.options);
                                            options.splice(index, 1);
                                            setLocalProfile({ ...localProfile, options: JSON.stringify(options) });
                                        }} />
                                    </div>
                                </div>
                            }
                        }
                        else {
                            return <div key={index} className="chips" onClick={() => setSelectedIndex(index)}>
                                {option}
                                <i className="fa fa-times chip-clear" onClick={() => {
                                    let options = JSON.parse(localProfile.options);
                                    options.splice(index, 1);
                                    setLocalProfile({ ...localProfile, options: JSON.stringify(options) });
                                }} />
                            </div>
                        }
                    })}

                </div>
            </>
        )
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Profile Questions</h4>
                <Button onClick={() => {
                    setShow(true); setLocalProfile({
                        offeringId: user.selectedOffering,
                        profileKey: 0,
                        value: "",
                        category: "",
                        valueType: 0,
                        valueTypeName: "",
                        options: null,
                        sequence: 0
                    });
                }} className="btn-sm"
                    disabled={user.isPublished}
                >
                    + Add Profile Question
                </Button>
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} {...tableData} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localProfile.id != 0 && localProfile.id ? updateProfile(e) : addProfile(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localProfile.id != 0 && localProfile.id ? "Edit" : "Add"} Profile Question</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        {/* {JSON.stringify(props.user)} */}
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="value">Question Text<span className="required-star">*</span></label>
                                <input type="text" maxLength={250} value={localProfile.value || ''} className="form-control" onChange={(e) => {
                                    setLocalProfile({ ...localProfile, value: e.target.value });
                                }}
                                    required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Category<span className="required-star">*</span></label>

                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localProfile.category}
                                        onChange={(e) => {
                                            setLocalProfile({ ...localProfile, category: e.target.value });
                                        }}
                                        required>
                                        <option value="" >Select Category</option>
                                        <option key={1} value="About me" >About me</option>
                                        <option key={2} value="Basic Profile" >Basic Profile</option>
                                        <option key={3} value="Communities of Interest" >Communities of Interest</option>

                                    </select>
                                </div>

                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="sequence">Sequence<span className="required-star">*</span></label>
                                <input type="number" value={localProfile.sequence || ''} className="form-control" onChange={(e) => {
                                    setLocalProfile({ ...localProfile, sequence: e.target.value });
                                }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Answer Type<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={localProfile.valueType}
                                        onChange={(e) => {
                                            setLocalProfile({ ...localProfile, valueType: e.target.value, options: null });
                                        }}
                                        required>
                                        <option value="">Select Type</option>
                                        <option key={1} value={1} disabled={localProfile.category === "Communities of Interest" ? true : false}>Text</option>
                                        <option key={2} value={2} disabled={localProfile.category === "About me" || localProfile.category === "Basic Profile" ? true : false}>Multi-Select</option>
                                        <option key={3} value={3} disabled={localProfile.category === "About me" || localProfile.category === "Basic Profile" ? true : false}>Single-Select</option>
                                    </select>
                                </div>
                            </div>
                            {(localProfile.valueType == 3 || localProfile.valueType == 2) && <div className="form-group col-12">
                                <label htmlFor="role">Option(s) <span className="required-star">*</span></label>
                                <AnswerOptions localProfile={localProfile} updateOptions={setLocalProfile} />
                            </div>}

                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" size="sm" disabled={localProfile.category === "Communities of Interest" && (localProfile.options == "" || localProfile.options == null) ? true : false}>
                            {localProfile.id != 0 && localProfile.id ? "Update" : "Add"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ProfileMetaData;