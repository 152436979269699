import { Tab } from "bootstrap";
import React from "react";
import { Tabs } from "react-bootstrap";
import Badges from "../Gamification/Badges";
import PointActivities from "../Gamification/PointActivities";
import PointAction from "../Gamification/PointAction";


const Gamification = (props) => {
    const [key, setKey] = React.useState('pointActivities');
    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="pointActivities" title="Points: Activities">
                <PointActivities selectedOffering={props.selectedOffering} offeringList={props.offeringList} />
            </Tab>
            <Tab eventKey="pointAction" title="Points: Actions">
                <PointAction selectedOffering={props.selectedOffering} offeringList={props.offeringList} />
            </Tab>
            <Tab eventKey="badges" title="Badges">
                <Badges />
            </Tab>
        </Tabs >
    );
}

export default Gamification;