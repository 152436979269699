import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { IAMAxios, ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import { generalDateFormat, inputDateFormat } from "../../util";
import UserContext from "../../UserContext";
import ConfirmDialog from "../Layout/ConfirmDialog";

const OfferingList = (props) => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [offeringList, setOfferingList] = React.useState([]);
    const [tableData, setTableData] = React.useState({ rows: [], columns: [] });
    const [loaderShow, setLoaderShow] = React.useState(true);
    const [programList, setProgramList] = React.useState([]);
    const [adminUsers, setAdminUsers] = React.useState([]);

    useEffect(() => {
        // setLoaderShow(true);
        getAllOfferings();
        getProgramList();
        getAdminUsers();
    }, [user]);

    useEffect(() => {
        generateTableData();
    }, [offeringList]);

    const getAdminUsers = () => {
        IAMAxios.get("/AdminUser/getadminusers")
            .then((res) => {
                setAdminUsers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const getAllOfferings = () => {
        getAdminUsers();
        ProgramSetupAxios.get("/OfferingAdmin/getadminofferings/" + user.adminId)
            .then((response) => {
                setOfferingList(response.data);
                setLoaderShow(false);
            })
            .catch((error) => {
                console.log(error);
                setLoaderShow(false);
            });
    }

    const getProgramList = () => {
        ProgramSetupAxios.get("/Program/getactiveprograms")
            .then((response) => {
                setProgramList(response.data);
            })
            .catch((error) => {
                console.log(error);
                setLoaderShow(false);
            });
    }

    const generateTableData = () => {
        let tableData = {};
        let columns = [
            {
                label: "Offering Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: true,
                filter: false,
            },
            {
                label: "Offering Name",
                field: 'programName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: true,
                filter: false,
            },
            {
                label: "Start Date",
                field: 'startDate',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Start Date',
                },
                sort: true,
                filter: false,
            },
            {
                label: "End Date",
                field: 'endDate',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'End Date',
                },
                sort: true,
                filter: false,
            },
            {
                label: "Program Manager",
                field: 'managerName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'End Date',
                },
                sort: true,
                filter: false,
            },
            {
                label: "Program Administrator",
                field: 'adminName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'End Date',
                },
                sort: true,
                filter: false,
            },

            {
                label: "Status",
                field: 'status',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'End Date',
                },
                sort: true,
                filter: false,
            },

            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'text-center',
                }
            },
        ];

        let rows = [];
        offeringList.forEach((offering) => {
            let row = {};
            row.id = { value: offering.id, label: offering.id, export: offering.id }
            row.programName = {
                value: offering.programName,
                label: <Button onClick={() => handleOfferingClick(offering.id)} variant="link" className="p-0 text-left">{offering.programName}</Button>,
                export: offering.programName
            }
            row.location = { value: offering.location, label: offering.location, export: offering.location }
            row.startDate = { value: offering.startDate, label: generalDateFormat(offering.startDate), export: generalDateFormat(offering.startDate) }
            row.endDate = { value: offering.endDate, label: generalDateFormat(offering.endDate), export: generalDateFormat(offering.endDate) }
            row.managerName = { value: '', label: '', export: '' }

            row.adminName = { value: '', label: '', export: '' }
            row.status = { value: offering.isPublished, label: offering.isPublished ? "Published" : "Not Published", export: offering.isPublished ? "Published" : "Not Published" }
            row.action = {
                value: "",
                label: <>
                    <AddNewOffering
                        adminUserList={adminUsers}
                        handleSaveOffering={handleSaveOffering}
                        roleId={user.roleId}
                        userId={user.id}
                        label={<i className="fa fa-pencil" />}
                        variant="outline-primary"
                        editOffering={true}
                        // disabled={offering.isPublished}
                        {...offering} />
                </>
            }

            offering.programAdminUsers.forEach((adminUser, index) => {
                if (adminUser) {
                    if (adminUser.roleId === 2 || adminUser.roleId === 1) {
                        row.managerName.value += adminUser.firstName + " " + adminUser.lastName;
                        row.managerName.label += adminUser.firstName + " " + adminUser.lastName;
                        if (index < offering.programAdminUsers.length - 1) {
                            row.managerName.value += ", ";
                            row.managerName.label += ", ";
                        }
                    }
                }
            });

            offering.programAdminUsers.forEach((adminUser, index) => {
                if (adminUser) {
                    if (adminUser.roleId === 3) {
                        row.adminName.value += adminUser.firstName + " " + adminUser.lastName;
                        row.adminName.label += adminUser.firstName + " " + adminUser.lastName;
                        if (index < offering.programAdminUsers.length - 1) {
                            row.adminName.value += ", ";
                            row.adminName.label += ", ";
                        }
                    }
                }
            });


            rows.push(row);
        });

        tableData.columns = columns;
        tableData.rows = rows;
        setTableData(tableData);

    }

    const handleOfferingClick = (id) => {
        user.setSelectedOffering(id);
        localStorage.setItem("selectedOffering", id);
        navigate(`/dashboard`);
    }

    const handleSaveOffering = (data) => {

        ProgramSetupAxios.post("/Offering", data)
            .then((response) => {
                user.createToast({ title: "Success", message: "Offering created successfully.", variant: "success" });
            })
            .catch((error) => {
                user.createToast({ title: "Error", message: "Someting went wrong", variant: "danger" });
                console.log(error);
            });
    }

    const AddNewOffering = (props,) => {
        const [showNewOfferingModal, setShowNewOfferingModal] = React.useState(false);
        const [selectedProgram, setSelectedProgram] = React.useState(null);
        const [offeringName, setOfferingName] = React.useState('');
        const [startDate, setStartDate] = React.useState('');
        const [endDate, setEndDate] = React.useState('');
        const [oldAdminId, setOldAdminId] = React.useState(0);
        const [adminUserId, setAdminUserId] = React.useState();
        const [today, setToday] = React.useState('');
        const [cloneOffering, setCloneOffering] = React.useState(false);
        const [cloneOfferingId, setCloneOfferingId] = React.useState(0);
        const [showConfirmModal, setShowConfirmModal] = React.useState(false);
        const [showEditModal, setShowEditModal] = React.useState(false);

        const [title, setTitle] = React.useState('Add New Offering');

        useEffect(() => {
            if (props.roleId === 2 || props.roleId === 3) {
                setAdminUserId(props.userId);
            }

        }, [props.roleId]);

        useEffect(() => {
            if (props.editOffering) {
                let programManager = props.programAdminUsers.find(adminUser => adminUser.roleId === 2);

                setOfferingName(props.programName);
                setStartDate(inputDateFormat(props.startDate));
                setEndDate(inputDateFormat(props.endDate));
                setSelectedProgram(props.programId);
                if (programManager) {
                    setOldAdminId(programManager.id);
                    setAdminUserId(programManager.id);
                }

                setTitle('Edit Offering');
            }
        }, [props.editOffering]);


        useEffect(() => {
            let offering = offeringList.find((offering) => offering.id == cloneOfferingId);
            if (offering) {

                let manager = offering.programAdminUsers.find(adminUser => adminUser.roleId === 2);
                let managerId = manager ? manager : 0;
                setStartDate(offering.startDate);
                setEndDate(offering.endDate);
                setAdminUserId(managerId);
            }

        }, [cloneOfferingId]);

        useEffect(() => {
            let date = new Date();
            let today = inputDateFormat(date);
            setToday(today);

        }, []);


        const handleSubmit = (e) => {
            e && e.preventDefault();

            if (cloneOffering && cloneOfferingId) {
                const clonedOffering = {
                    cloneOfferingId: cloneOfferingId,
                    programName: offeringName,
                    startDate: null,
                    endDate: null,
                    adminUserId: adminUserId,
                };

                ProgramSetupAxios.post("/Offering/cloneoffering", clonedOffering)
                    .then((response) => {
                        user.createToast({ title: "Success", message: "Offering cloned successfully.", variant: "success" });
                        // getAllOfferings();
                        setShowNewOfferingModal(false);
                    }).catch((error) => {
                        user.createToast({ title: "Error", message: "Someting went wrong", variant: "danger" });
                        console.log(error);
                    });

            } else if (props.editOffering) {
                let offeringData = {
                    id: props.id,
                    programName: offeringName,
                    programId: selectedProgram,
                    startDate: startDate,
                    endDate: endDate,
                    oldAdminId: oldAdminId,
                    newAdminId: adminUserId,
                }

                ProgramSetupAxios.post("/Offering/editofferingbasicdetails", offeringData)
                    .then(() => {
                        user.createToast({ title: "Success", message: "Offering updated successfully.", variant: "success" });
                        // getAllOfferings();
                        setShowNewOfferingModal(false);
                    }).catch((error) => {
                        user.createToast({ title: "Error", message: "Someting went wrong", variant: "danger" });
                        console.log(error);
                    });
            }
            else {
                let data = {
                    tdtProgramId: selectedProgram,
                    tdtOfferingId: 0,
                    programId: selectedProgram,
                    programName: offeringName,
                    deliveryType: "",
                    industry: "",
                    fy: "",
                    fss: "",
                    shortDescription: "",
                    longDescription: "",
                    startDate: startDate || null,
                    endDate: endDate || null,
                    location: "",
                    agenda: "",
                    coachMaxSeats: 0,
                    learnerMaxSeats: 0,
                    coachRemainingSeats: 0,
                    learnerRemainingSeats: 0,
                    deanFirstName: "",
                    deanLastName: "",
                    deanEmail: "",
                    deanProfileImage: "",
                    deanBrief: "",
                    adminUserId: adminUserId,
                }
                props.handleSaveOffering(data);
            }

        }

        return (
            <>
                <Button
                    variant={props.variant ? props.variant : "primary"}
                    size='sm'
                    onClick={() => { setShowNewOfferingModal(true); }}
                    disabled={props.disabled}
                >
                    {props.label}
                </Button>
                <Modal
                    show={showNewOfferingModal}
                    onHide={() => setShowNewOfferingModal(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="md"
                >
                    <form onSubmit={handleSubmit}>
                        <Modal.Header >
                            <Modal.Title>{title}</Modal.Title>
                            <div className="close" onClick={() => setShowNewOfferingModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Program <span className="danger">*</span></label>
                                    <select
                                        className="form-control"
                                        value={selectedProgram}
                                        onChange={(e) => { setSelectedProgram(e.target.value); }}
                                        required >
                                        <option value="" >Select</option>
                                        {
                                            programList.map((program, index) => {
                                                return <option value={program.id} key={"option-" + index}>{program.name}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="col-md-12 form-group">
                                    <label>Offering Name <span className="danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={offeringName}
                                        maxLength={200}
                                        onChange={(e) => { setOfferingName(e.target.value); }
                                        }
                                        required
                                    />
                                    {!props.editOffering &&
                                        <div className="col-md-12 form-group mt-2">
                                            <div className="d-flex width-fit">
                                                <input
                                                    className="me-2"
                                                    type="checkbox"
                                                    id="cloneOffering"
                                                    onChange={(e) => { setCloneOffering(e.target.checked); }}
                                                    disabled={selectedProgram === null && offeringList.filter(x => x.programId == selectedProgram).length <= 0 ? true : false}
                                                />
                                                <label htmlFor="cloneOffering">Clone existing offering</label>
                                            </div>

                                            {
                                                cloneOffering &&
                                                <select className="form-control" onChange={(e) => setCloneOfferingId(e.target.value)}>
                                                    <option value="" >Select</option>
                                                    {
                                                        offeringList.filter(x => x.programId == selectedProgram).map((offering, index) => {
                                                            return <option value={offering.id} key={"option-" + index}>{offering.programName}</option>
                                                        })
                                                    }

                                                </select>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Start Date</label>
                                    <input type="date" className="form-control" min={today} value={startDate} onChange={(e) => { setStartDate(e.target.value); }} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>End Date</label>
                                    <input type="date" className="form-control" min={startDate || today} value={endDate} onChange={(e) => { setEndDate(e.target.value); }} />
                                </div>
                                <div className="col-md-12 form-group">
                                    <label>Program Manager <span className="danger">*</span></label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => { setAdminUserId(e.target.value); }}
                                        required
                                        value={adminUserId}
                                    >
                                        <option value="">Select User </option>
                                        {
                                            props.adminUserList.filter(x => x.roleId === 2).map((admin) => {
                                                return <option value={admin.id}>{admin.firstName + ' ' + admin.lastName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                        </Modal.Body >
                        <Modal.Footer>
                            <Button variant="link" className="btn-text" onClick={() => { setShowNewOfferingModal(false); }}>
                                Close
                            </Button>
                            {
                                cloneOffering ?
                                    <>
                                        {offeringName && adminUserId > 0 ?
                                            <Button variant="primary" size='sm' onClick={() => setShowConfirmModal(true)}>
                                                <i className="fa fa-clone me-2" /> Clone Offering
                                            </Button>
                                            :
                                            <Button type="submit" variant="primary" size='sm' >
                                                <i className="fa fa-clone me-2" /> Clone Offering
                                            </Button>}
                                        <ConfirmDialog
                                            open={showConfirmModal}
                                            title="Confirm Cloning"
                                            message={<>
                                                <p>Are you sure you want to clone the offering?</p>
                                                <p>Cloning will create a new offering with the same missions, stages, content, settings as the existing offering.</p>
                                            </>}
                                            onConfirm={handleSubmit}
                                            onCancel={() => { setShowConfirmModal(false); }}
                                        />
                                    </>
                                    :
                                    <>

                                        {props.editOffering && oldAdminId !== adminUserId && adminUserId ?
                                            <>
                                                <Button variant="primary" size='sm' onClick={() => setShowEditModal(true)}>Save Changes</Button>
                                                <ConfirmDialog
                                                    open={showEditModal}
                                                    title="Confirm Cloning"
                                                    message={<>
                                                        <p>Are you sure you want to change Project Manager</p>
                                                        <p>Changing program manager will remove access from the current program manager, You can add additional manager in user management under offering.</p>
                                                    </>}
                                                    onConfirm={handleSubmit}
                                                    onCancel={() => { setShowEditModal(false); }}
                                                />
                                            </>
                                            :
                                            <Button type="submit" variant="primary" size='sm'>Save Changes</Button>
                                        }
                                    </>
                            }
                        </Modal.Footer>
                    </form>

                </Modal >
            </>
        )
    }


    const AddNewProgram = (props) => {
        const [showNewProgramModal, setShowNewProgramModal] = React.useState(false);
        const [programName, setProgramName] = React.useState('');
        const [programDescription, setProgramDescription] = React.useState('');

        const handleSaveProgram = (e) => {
            e.preventDefault();
            let data = {
                name: programName,
                description: programDescription,
                agenda: "",
                isActive: true
            }

            ProgramSetupAxios.post("/Program", data)
                .then((response) => {
                    setShowNewProgramModal(false);
                    setProgramName('');
                    setProgramDescription('');
                    user.createToast({ title: "Success", message: "Program created successfully.", variant: "success" });
                    props.getProgramList();
                })
                .catch((error) => {
                    user.createToast({ title: "Error", message: "Something went wrong", variant: "danger" });
                    console.log(error);
                });
        }

        return (
            <>
                <Button variant="primary" size='sm' onClick={() => { setShowNewProgramModal(true); }}>
                    <i className="fa fa-plus" /> Add New Program
                </Button>
                <Modal
                    show={showNewProgramModal}
                    onHide={() => setShowNewProgramModal(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="md"
                >
                    <form onSubmit={handleSaveProgram}>
                        <Modal.Header >
                            <Modal.Title>Add New Program</Modal.Title>
                            <div className="close" onClick={() => setShowNewProgramModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Program Name <span className="danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={programName}
                                        onChange={(e) => { setProgramName(e.target.value); }
                                        }
                                        required
                                    />
                                </div>
                                <div className="col-md-12 form-group">
                                    <label>Program Description <span className="danger">*</span></label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={programDescription}
                                        onChange={(e) => { setProgramDescription(e.target.value); }
                                        }
                                        required
                                    />
                                </div>
                            </div>
                        </Modal.Body >
                        <Modal.Footer>
                            <Button variant="link" className="btn-text" size='sm' onClick={() => { setShowNewProgramModal(false); }}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary" size='sm'>Save Changes</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        )

    }



    return (
        <>
            <div className="page-header mb-4 ">
                <h1>Program Offerings</h1>
                <div className="page-actions">
                    {/* <Button variant="primary" size="sm" >
                        <i className="fa fa-plus" /> Add New Program
                    </Button> */}
                    <AddNewProgram getProgramList={getProgramList} />
                    <AddNewOffering
                        adminUserList={adminUsers}
                        handleSaveOffering={handleSaveOffering}
                        roleId={user.roleId}
                        userId={user.id}
                        label={<><i className="fa fa-plus" /> Add New Offering</>}
                    />
                </div>
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} loaderShow={loaderShow} {...tableData} />
            </div>

        </>
    );
};

export default OfferingList;