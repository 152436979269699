import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const ExportExcel = (props) => {

    useEffect(() => {
        // console.log('jsonObject', props.jsonObject);
    }, [props.jsonObject]);

    const exportJSON = () => {

        // var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var array = props.jsonObject;
        let updatedArray = [];

        array.forEach(element => {
            let row = Object.keys(element).map(key => {
                let colName = props.columns.filter(item => item.field == key);

                return { key: colName.length > 0 ? colName[0].label : "", value: element[key].export };
            });
            row = row.filter(row => row.value !== undefined);
            updatedArray.push(row);
        });

        // Sort updatedArray by length of object keys in descending order
        let headerArray = [...updatedArray];
        headerArray.sort((a, b) => Object.keys(b).length - Object.keys(a).length);
        var csv = headerArray[0].map(function (key) {
            return key.key;
        }).join(",") + "\r\n";


        csv += updatedArray.map(e => e.map(r => '"' + r.value + '"').join(",")).join("\r\n");

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = props.fileName ? props.fileName : 'output.csv';
        hiddenElement.click();
    };

    return (
        <div className="export-excel">
            <button className="btn btn-secondary btn-sm"
                onClick={exportJSON}
                disabled={props.jsonObject && props.jsonObject.length > 0 ? false : true}
            >
                {props.isLoading ? <Spinner animation="border" variant="light" size="sm" className="me-2" />
                    : <i className="fa fa-download me-2" />
                }                Export to Excel

            </button>
        </div>
    );
};

export default ExportExcel;