import React, { useContext, useEffect, useState } from "react";
import { UserActivityAxios, ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import Moment from "react-moment";
import ExportExcel from "../Exports/ExportExcel";
import UserContext from "../../UserContext";
import ExportExcelAPI from "../Exports/ExportExcelAPI";
import axios from "axios";

let cancelToken = axios.CancelToken.source();
const LearnerProgress = (props) => {
    const user = useContext(UserContext);
    const [learnerProgressList, setLearnerProgressList] = React.useState([]);
    const [learnerProgressListExport, setLearnerProgressListExport] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);

    const [selectedOfferingName, setSelectedOfferingName] = React.useState();

    useEffect(() => {
        setPreferredMission(props.preferredMission);
    }, [props.preferredMission]);

    useEffect(() => {
        setLoaderShow(true);
        if (user.offeringList && user.selectedOffering) {
            var tempName = user.offeringList.filter(x => x.id == user.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getLearnerProgress();
        }
    }, [user.selectedOffering]);

    useEffect(() => {
        var userActivityLogListData = generateTableData(learnerProgressList);
        setTableData(userActivityLogListData);
    }, [learnerProgressList, preferredMission]);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const getLearnerProgress = () => {

        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }
        var data = {
            offeringId: user.selectedOffering,
            pageNumber: pageNumber,
            pageSize: pageSize,
            ...options
        }

        UserActivityAxios.post("/OfferingUserActivity/getlearnerprogress", data)
            .then(function (response) {
                setLearnerProgressList(response.data);
                setTotalCount(response.data[0].totalCount)
                setLoaderShow(false);
                if (pageNumber == 1) {
                    getLearnerProgressExport();
                }
            })
            .catch(function (error) {
                setLearnerProgressList([]);
                setTotalCount(0);
                console.log(error);
                setLoaderShow(false);
            });
    };

    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const getLearnerProgressExport = () => {
        setIsLoadingExport(true)
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }
        var data = {
            offeringId: user.selectedOffering,
            pageNumber: 0,
            pageSize: 0,
            ...options
        }
        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();
        UserActivityAxios.post("/OfferingUserActivity/getlearnerprogress", data, { cancelToken: cancelToken.token })
            .then(function (response) {
                setIsLoadingExport(false)
                var userActivityLogListData = generateTableData(response.data);
                setLearnerProgressListExport(userActivityLogListData?.rows);
            })
            .catch(function (error) {
                setIsLoadingExport(false)
                setLearnerProgressListExport([])
            });
    };


    useEffect(() => {
        getLearnerProgress();
    }, [pageNumber, pageSize]);



    const generateTableData = (rawData) => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering Name",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering Name',
                    className: 'text-center',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: '-' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Team Name",
                field: 'teamName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Role",
                field: 'role',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Role',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Learner Email",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Learner Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Learner Name",
                field: 'userName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'learnerName',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Stage Name",
                field: 'stageName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'stageName',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Activity Name",
                field: 'activityName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Activity Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Completion Status",
                field: 'completionStatus',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Completion Status',
                },
                sort: true,
                filter: false,
                filterType: 'boolean',

            },
            {
                label: "Badge/Level",
                field: 'level',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Badge/Level',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Individual Rank",
                field: 'rank',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Individual Rank',
                },
                sort: true,
                filter: false,
                filterType: 'number',

            },
            {
                label: "Solution Submitted?",
                field: 'solutionSubmitted',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Solution Submitted?',
                },
                sort: true,
                filter: false,
                filterType: 'boolean',

            },
            {
                label: "Solution Rating",
                field: 'solutionRating',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'solutionRating',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            },
            {
                label: "Team Rank",
                field: 'teamRank',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Rank',
                },
                sort: true,
                filter: false,
                filterType: 'number',
            }, {
                label: "Bonus Points",
                field: 'bonusPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'bonusPoints',
                },
                sort: true,
                filter: false,
                filterType: 'number',
            }, {
                label: "Experienced Points",
                field: 'experiencedPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'experiencedPoints',
                },
                sort: true,
                filter: false,
                filterType: 'number',
            }, {
                label: "Total Points",
                field: 'totalPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'totalPoints',
                },
                sort: true,
                filter: false,
                filterType: 'number',
            }, {
                label: "Average Completion",
                field: 'avgCompletion',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'avgCompletion',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            }, {
                label: "Connection Count",
                field: 'connectionCount',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'connectionCount',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            }, {
                label: "Meeting Attended",
                field: 'meetingAttended',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'meetingAttended',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            },
        ];
        let rows = [];
        rawData.forEach((learner, index) => {
            let row = {};
            let mission = preferredMission.find(x => x.id === learner.missionId);
            let offeringName = selectedOfferingName;
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.offeringName = { value: offeringName, label: offeringName, export: offeringName };
            row.role = { value: learner.roleName, label: learner.roleName, export: learner.roleName };
            row.email = { value: learner.learnerEmail, label: learner.learnerEmail, export: learner.learnerEmail };
            row.userName = { value: learner.learnerName, label: learner.learnerName, export: learner.learnerName };
            row.stageName = { value: learner.stageName, label: learner.stageName, export: learner.stageName };
            row.missionId = {
                value: learner.missionId,
                label: mission
                    ? mission.missionName
                    : '-',
                export: mission && mission.missionName
            };
            row.activityName = { value: learner.activityName, label: learner.activityName, export: learner.activityName };
            row.completionStatus = { value: learner.isCompleted, label: learner.isCompleted == true ? "Yes" : "No", export: learner.isCompleted == true ? "Yes" : "No" };
            row.level = { value: learner.level, label: learner.level, export: learner.level };
            row.rank = { value: learner.rank, label: learner.rank, export: learner.rank };

            row.solutionSubmitted = { value: learner.solutionSubmitted, label: learner.solutionSubmitted == true ? "Yes" : "No", export: learner.solutionSubmitted == true ? "Yes" : "No" };
            row.solutionRating = { value: learner.solutionRating, label: learner.solutionRating, export: learner.solutionRating };

            row.teamName = { value: learner.teamName, label: learner.teamName, export: learner.teamName }
            row.teamRank = { value: learner.teamRank, label: learner.teamRank, export: learner.teamRank }
            row.bonusPoints = { value: learner.bonusPoints, label: learner.bonusPoints, export: learner.bonusPoints };
            row.experiencedPoints = { value: learner.experiencedPoints, label: learner.experiencedPoints, export: learner.experiencedPoints };
            row.totalPoints = { value: learner.totalPoints, label: learner.totalPoints, export: learner.totalPoints }
            row.avgCompletion = { value: learner.avgCompletion, label: Math.round(learner.avgCompletion * 100) / 100, export: learner.avgCompletion }
            row.connectionCount = { value: learner.connectionCount, label: learner.connectionCount, export: learner.connectionCount }
            row.meetingAttended = { value: learner.meetingAttended, label: learner.meetingAttended, export: learner.meetingAttended }
            rows.push(row);
        });

        return { columns, rows };
    };

    return (
        <>
            <div className="page-header mb-4">
                <h4>Learner Progress Report</h4>
                <div className="page-actions">
                    <ExportExcel jsonObject={learnerProgressListExport} isLoading={isLoadingExport} columns={tableData.columns} fileName="LearnerProgress.csv" />

                </div>
            </div>
            <div className="data-table">
                <DataTable {...tableData} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} multiSelect={false} pageSize={pageSize} setPageNo={setPageNumber}
                    applyFilters={getLearnerProgress} apiPagination={true} setPageSize={setPageSize} totalCount={totalCount} />
            </div>
        </>
    );
};

export default LearnerProgress;
