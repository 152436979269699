import React, { useEffect, useState, } from 'react';
import UserContext from './UserContext';

import './App.css';
import "./AdminLayout.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig.js';

import AdminLayout from "./AdminLayout";
import { OnboardingAxios, ProgramSetupAxios, IAMAxios, ConfigurationAxios, NetworkAxios, UserActivityAxios } from "./API";
import AdminDashboard from "./components/AdminDashboard";
import OfferingList from "./components/Offerings/OfferingList";
import TeamAllocation from "./components/EligiblePool/TeamAllocation";
import Registrations from "./components/ProgramSetup/Registrations";
import ErrorPage from './components/Layout/ErrorPage';
import MissionSetup from './components/Offerings/MissionSetup/MissionSetup';
import Reports from './components/Reports/Reports';
import Certificate from './components/Certificate/Certificate';
import Login from './components/Login/Login';
import Settings from './components/Settings/Settings';
import UserManagement from './components/UserManagement/UserManagement';
import Gamification from './components/Settings/Gamification';
import ThoughtCatalog from './components/Offerings/ThoughtCatalog';
import Schedule from './components/Schedule/Schedule';
import LinkGroup from './components/Offerings/Links/LinkGroup';
import StageGroup from './components/Offerings/StageActivity/StageGroup';
import PublishOffering from './components/Settings/PublishOffering';
import AdminMainLayout from './AdminMainLayout';
import ProfileMetaData from './components/Gamification/ProfileMetaData';
import RatingScales from './components/RatingScales/RatingScales';
import OfferingDetails from './components/Offerings/OfferingDetails';
import ToastMessage from './components/Layout/ToastMessage';
import Tokens from './components/Settings/Tokens';
import AwardCategory from './components/Settings/AwardCategory';
import EmailTemplate from './components/Settings/EmailTemplate';

function App() {
  const history = useNavigate();
  const location = useLocation();
  const [offeringList, setOfferingList] = useState([]);
  const [selectedOffering, setSelectedOffering] = useState();
  const [user, setUser] = useState({
    fisrtName: "",
    lastName: "",
    email: "",
    profileImage: "",
    roleId: "",
  });

  const [menuStatus, setMenuStatus] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [selectedOfferingName, setSelectedOfferingName] = useState("" || user.programName);
  const [userTokenData, setUserTokenData] = useState();
  const [isPublished, setIsPublished] = useState(false);

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    localStorage.getItem("user") && setUser(JSON.parse(localStorage.getItem("user")));
    //getAllOfferings();
  }, []);

  useEffect(() => {
    if (user.adminId > 0) {
      getAllOfferings();
    }
  }, [user, selectedOffering]);

  useEffect(() => {
    let isPublished = false;
    offeringList.forEach(offering => {
      if (offering.id === selectedOffering) {
        isPublished = offering.isPublished;
      }
    });
    setIsPublished(isPublished);

  }, [offeringList, selectedOffering]);

  useEffect(() => {
    getAllOfferings();
  }, [selectedOffering]);


   useEffect(() => {
     const request = {
       ...loginRequest,
       account: accounts[0]
     };
     if (inProgress === InteractionStatus.None) {
       instance.acquireTokenSilent(request).then((response) => {
         IAMAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         ConfigurationAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         ProgramSetupAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         OnboardingAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         NetworkAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         UserActivityAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         // dispatch(authSlice.actions.setTokenData(response));
         localStorage.setItem('items', JSON.stringify(response));
         setTimeout(function () { RefreshToken(); }, new Date(response.expiresOn) - new Date());
         if (location.pathname == "" && location.pathname == "/login") {
           history.push("/login");
         }
       }).catch((error) => {
         instance.acquireTokenRedirect(request).then((response) => {
           IAMAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           ConfigurationAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           ProgramSetupAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           OnboardingAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           NetworkAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           UserActivityAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           //  dispatch(authSlice.actions.setTokenData(response));
           localStorage.setItem('items', JSON.stringify(response));
           setTimeout(function () { RefreshToken(); }, new Date(response.expiresOn) - new Date());
           history.push("/login");
         });
       });
     }
   }, [instance, accounts, inProgress]);

   const RefreshToken = () => {
     const request = {
       ...loginRequest,
       account: accounts[0]
     };
     if (inProgress === InteractionStatus.None) {
       instance.acquireTokenSilent(request).then((response) => {
         IAMAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         ConfigurationAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         ProgramSetupAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         OnboardingAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         NetworkAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         UserActivityAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
         //  dispatch(authSlice.actions.setTokenData(response));
         localStorage.setItem('items', JSON.stringify(response));
         setTimeout(function () { RefreshToken(); }, new Date(response.expiresOn) - new Date());
       }).catch((error) => {
         instance.acquireTokenRedirect(request).then((response) => {
           IAMAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           ConfigurationAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           ProgramSetupAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           OnboardingAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           NetworkAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           UserActivityAxios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
           //  dispatch(authSlice.actions.setTokenData(response));
           localStorage.setItem('items', JSON.stringify(response));
           setTimeout(function () { RefreshToken(); }, new Date(response.expiresOn) - new Date());
           history.push("/login");
         });
       });
     }
   };

  const getAllOfferings = () => {
    ProgramSetupAxios.get("/OfferingAdmin/getadminofferings/" + user.adminId)
      .then((response) => {
        setOfferingList(response.data);
        if (localStorage.getItem("selectedOffering") == null) {
          setSelectedOffering(response.data[0].id);
        }
        else {
          setSelectedOffering(parseInt(localStorage.getItem("selectedOffering")));
        }
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );
  };

  const createToast = (toast) => {
    let newToast = {
      ...toast,
      id: Math.random().toString(36).replace('0.', 'toast-' || ''),
      title: toast.title || "Message",
      message: toast.message || "Something happened",
      show: true,
      variant: toast.variant || 'light',
      timeout: toast.timeout || 2000,
    }
    setToastList([...toastList, newToast]);
  }

  useEffect(() => {
    // console.log("toastList", toastList);
  }, [toastList]);
  return (

    <>
      <UserContext.Provider value={
        {
          ...user,
          selectedOffering: selectedOffering || 0,
          setSelectedOffering,
          setUser,
          offeringList,
          menuStatus,
          isPublished,
          setIsPublished,
          setMenuStatus,
          createToast,
          getOfferingList: getAllOfferings,
        }
      }>
        <Routes>
          <Route path="/" element={<Login setUser={setUser} />} />
          <Route element={<AdminLayout toastList={toastList} setToastList={setToastList} />} >
            <Route path="/dashboard" element={<AdminDashboard />} exact />
            <Route path="/outline/missions" element={<MissionSetup />} exact />
            <Route path="/outline/stages" element={<StageGroup />} exact />
            <Route path="/content/thought-catalog" element={<ThoughtCatalog />} exact />
            <Route path="/content/links" element={<LinkGroup selectedOfferingName={selectedOfferingName} />} exact />
            <Route path="/content/offering-details" element={<OfferingDetails />} exact />
            <Route path="/content/meta-data" element={<ProfileMetaData />} />
            <Route path="/settings" element={<Settings />} exact />
            <Route path="/settings/gamification" element={<Gamification selectedOfferingName={selectedOfferingName} />} exact />
            <Route path="/settings/rating-scales" element={<RatingScales />} />
            <Route path="/settings/tokens" element={<Tokens />} />
            <Route path="/settings/email-template" element={<EmailTemplate />} />
            <Route path="/settings/award-category" element={<AwardCategory />} />
            <Route path="/schedule" element={<Schedule />} exact />
            <Route path="/registrations" element={<Registrations />} exact />
            <Route path="/team-allocation" element={<TeamAllocation />} exact />
            <Route path="/reports" element={<Reports selectedOfferingName={selectedOfferingName} />} exact />
            <Route path="/certificates" element={<Certificate />} exact />
            <Route path="/publish" element={<PublishOffering />} exact />
            <Route path="/offering/user-management" element={<UserManagement isOffering />} exact />
          </Route>

          <Route element={<AdminMainLayout user={user} toastList={toastList} setToastList={setToastList} />} >
            <Route path="/offering-list" element={<OfferingList />} exact />
            <Route path="/user-management" element={<UserManagement />} exact />
          </Route>
          <Route path="/login" element={<Login setUser={setUser} />} />
          {/* Add any route above this             */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </UserContext.Provider>
      <ToastMessage toastList={toastList} setToastList={setToastList} />
    </>

  );
}

export default App;