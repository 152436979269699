import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios, UserActivityAxios } from "../../../API";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Accordion } from 'react-bootstrap';
import UserContext from "../../../UserContext";
import ImageUpload from "../../FileUpload/ImageUpload";

const Mission = (props) => {
    const user = useContext(UserContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [oldMissionItem, setOldMissionItem] = useState();
    const [missionItem, setMissionItem] = useState(props.item);
    const [stageData, setstageData] = useState();
    const [imagePath, setImagePath] = useState();

    useEffect(() => {
        if (oldMissionItem == undefined) {
            setOldMissionItem(props.item);
        }
        setMissionItem(props.item);
    }, [props.item]);


    const deletePreviousStageData = (sameAsId, newMissionId) => {
        let stageDataToDelete = [];
        var tempStageData = props.stageData.filter(x => x.missionId == missionItem.id);
        if (tempStageData == undefined) {
            cloneMissionStageData(sameAsId, newMissionId);
            return;
        }
        tempStageData.forEach(element => {

            stageDataToDelete.push(element.id);
        });

        ProgramSetupAxios.post("/OfferingStage/deleteofferingstages", stageDataToDelete)
            .then((response) => {
                cloneMissionStageData(sameAsId, newMissionId);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const cloneMissionStageData = (sameAsId, newMissionId) => {
        // delete previous 
        let stageDataToUpdate = [];
        var tempStageData = props.stageData.filter(x => x.missionId == sameAsId);

        tempStageData.forEach(element => {
            element.id = 0;
            element.missionId = newMissionId;
            element.imagePath = "";

            element.activities.forEach(activity => {
                activity.missionId = newMissionId;
                activity.missionName = missionItem.missionName;
                activity.id = 0;
            });
            stageDataToUpdate.push(element);
        });

        ProgramSetupAxios.post("/OfferingStage/createofferingstagewithactivities", stageDataToUpdate)
            .then((response) => {
                props.getMissionByOfferingId();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const saveMission = (e) => {
        //e.stopPropagation();
        e.preventDefault();
        var coachRemainingSeats = missionItem.coachMaxSeats;
        var learnerRemainingSeats = missionItem.coachMaxSeats;

        if (oldMissionItem.coachMaxSeats != missionItem.coachMaxSeats) {
            var seatsDiff = oldMissionItem.coachMaxSeats - oldMissionItem.coachRemainingSeats
            coachRemainingSeats = missionItem.coachMaxSeats - seatsDiff;
        }

        if (oldMissionItem.learnerMaxSeats != missionItem.learnerMaxSeats) {
            var seatsDiff = oldMissionItem.learnerMaxSeats - oldMissionItem.learnerRemainingSeats
            learnerRemainingSeats = missionItem.learnerMaxSeats - seatsDiff;
        }

        if (missionItem.id != 0) {
            let data = {
                id: missionItem.id,
                missionName: missionItem.missionName,
                shortDescription: missionItem.shortDescription,
                imagePath: missionItem.imagePath,
                longDescription: missionItem.longDescription,
                additionalInfo: missionItem.additionalInfo,
                sequence: missionItem.sequence,
                coachMaxSeats: missionItem.coachMaxSeats,
                learnerMaxSeats: missionItem.learnerMaxSeats,
                journeyVideo: missionItem.journeyVideo,
                coachRemainingSeats: coachRemainingSeats,
                learnerRemainingSeats: learnerRemainingSeats,
                sameAs: missionItem.sameAs
            };

            ProgramSetupAxios.put("/OfferingMission/updatemissiondata", data)
                .then((response) => {
                    //props.getMissionByOfferingId();

                    //oldMissionItem.sameAs != missionItem.sameAs &&
                    if (missionItem.sameAs != 0) {
                        deletePreviousStageData(missionItem.sameAs, missionItem.id);
                    } else {
                        props.getMissionByOfferingId();
                    }
                    user.createToast({
                        title: "Mission",
                        message: "Mission saved successfully",
                        variant: "success" // "success", "warning", "danger", "info"
                    }
                    )

                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let data = [{
                offeringId: user.selectedOffering,
                missionName: missionItem.missionName,
                shortDescription: missionItem.shortDescription,
                imagePath: missionItem.imagePath,
                longDescription: missionItem.longDescription,
                sequence: missionItem.sequence,
                coachMaxSeats: missionItem.coachMaxSeats,
                learnerMaxSeats: missionItem.learnerMaxSeats,
                coachRemainingSeats: missionItem.coachMaxSeats,
                learnerRemainingSeats: missionItem.learnerMaxSeats,
                additionalInfo: missionItem.additionalInfo,
                journeyVideo: missionItem.journeyVideo,
                sameAs: missionItem.sameAs
            }];

            ProgramSetupAxios.post("/OfferingMission", data)
                .then((response) => {
                    console.log(response);
                    //props.getMissionByOfferingId();
                    //oldMissionItem.sameAs != missionItem.sameAs &&
                    if (response.data[0].sameAs != 0) {
                        deletePreviousStageData(response.data[0].sameAs, response.data[0].id);
                    } else {
                        props.getMissionByOfferingId();
                    }
                    user.createToast({
                        title: "Mission",
                        message: "Mission saved successfully",
                        variant: "success" // "success", "warning", "danger", "info"
                    }
                    )

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };

    const deleteMission = () => {
        if (missionItem.id != 0) {
            let stageDataToDelete = [];
            var tempStageData = props.stageData.filter(x => x.missionId == missionItem.id);

            tempStageData.forEach(element => {

                stageDataToDelete.push(element.id);
            });

            ProgramSetupAxios.post("/OfferingStage/deleteofferingstages", stageDataToDelete)
                .then((response) => {
                    ProgramSetupAxios.delete("/OfferingMission/deletemission/" + missionItem.id)
                        .then((response) => {
                            props.getMissionByOfferingId();
                            setShowDeleteModal(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });


        }
        else {
            props.getMissionByOfferingId();
        }
    };

    const getProfileImage = (value) => {
        let missionDataTemp = { ...missionItem, imagePath: value }
        console.log(missionDataTemp);
        setMissionItem({ ...missionItem, imagePath: value });
    }


    return (<>
        <Accordion.Item className="mission-box" eventKey={props.index + 1}>
            <Form onSubmit={(e) => saveMission(e)}>
                <Accordion.Header className="mission-header" onClick={props.setActiveKey}>Mission: {missionItem.missionName || missionItem.missionName != "" ? missionItem.missionName : "New Mission"}
                    <div className="page-actions me-2">
                        <Button variant="primary" className="btn-sm" type="submit" onClick={(e) => { e.stopPropagation(); }} disabled={user.isPublished}>
                            <i className="fa fa-floppy-o" />
                        </Button>
                        {/* <Button variant="secondary" className="btn-sm" onClick={() => saveMission()}><i className="fa fa-minus-circle me-2" />Cancel</Button> */}
                        <Button variant="outline-danger" className="btn-sm" onClick={(e) => { setShowDeleteModal(true); e.stopPropagation(); e.preventDefault(); }} disabled={user.isPublished}>
                            <i className="fa fa-trash " />
                        </Button>
                    </div>
                </Accordion.Header>
                <Accordion.Body className="mission-body">

                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Mission Name<span className="required-star">*</span>	</Form.Label>
                        <Form.Control className="mission-width-name" type="text" maxLength={200} value={missionItem.missionName} onChange={(e) => { setMissionItem({ ...missionItem, missionName: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex col-6">
                        <Form.Label className="mission-label">Thumbnail Image	</Form.Label>
                        {/* <Form.Control className="mission-width-name" type="text" value={missionItem.imagePath} onChange={(e) => { setMissionItem( { ...missionItem, imagePath: e.target.value }); }} placeholder="Enter value" /> */}
                        <ImageUpload getFileUrl={getProfileImage} inputId={missionItem.id} filename={missionItem.imagePath} />

                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Short Description<span className="required-star">*</span>	</Form.Label>
                        <Form.Control className="mission-full-width" as="textarea" maxLength={125} value={missionItem.shortDescription} rows={2} onChange={(e) => { setMissionItem({ ...missionItem, shortDescription: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Long Description<span className="required-star">*</span>	</Form.Label>
                        {/* <Form.Control className="mission-full-width" as="textarea" rows={3} value={missionItem.longDescription} onChange={(e) => { setMissionItem( { ...missionItem, longDescription: e.target.value }); }} placeholder="Enter value" /> */}
                        <div className="w-100">
                            <CKEditor
                                editor={ClassicEditor}
                                data={missionItem.longDescription}
                                config={{
                                    placeholder: "Enter value",
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setMissionItem({ ...missionItem, longDescription: data });
                                    // console.log({ event, editor, data });
                                }}

                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Additional Info<span className="required-star">*</span></Form.Label>
                        <Form.Control className="mission-full-width" type="text" value={missionItem.additionalInfo} onChange={(e) => { setMissionItem({ ...missionItem, additionalInfo: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Journey Video Link<span className="required-star">*</span></Form.Label>
                        <Form.Control className="mission-full-width" type="text" value={missionItem.journeyVideo} onChange={(e) => { setMissionItem({ ...missionItem, journeyVideo: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    {/* <Form.Group className="mb-3  d-flex">
                <Form.Label className="mission-label">Sequence	</Form.Label>
                <Form.Control className="mission-width-number" type="number" value={missionItem.sequence} onChange={(e) => { setMissionItem( { ...missionItem, sequence: e.target.value }); }} placeholder="Enter value" />
            </Form.Group> */}
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Available Seats for Coaches<span className="required-star">*</span></Form.Label>
                        <Form.Control className="mission-width-number" min={1} max={9999} type="number" value={missionItem.coachMaxSeats} onChange={(e) => { setMissionItem({ ...missionItem, coachMaxSeats: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Available Seats for Learners<span className="required-star">*</span>	</Form.Label>
                        <Form.Control className="mission-width-number" min={1} type="number" max={9999} value={missionItem.learnerMaxSeats} onChange={(e) => { setMissionItem({ ...missionItem, learnerMaxSeats: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Sequence<span className="required-star">*</span>	</Form.Label>
                        <Form.Control className="mission-width-number" min={1} type="number" max={99} value={missionItem.sequence} onChange={(e) => { setMissionItem({ ...missionItem, sequence: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>

                    {props.index > 0 && <>
                        <hr />
                        <Form.Group className="mb-3  d-flex">
                            <Form.Label className="mission-label">Stages & Activities same as </Form.Label>
                            <Form.Select className="mission-width-name" value={missionItem.sameAs} onChange={(e) => { setMissionItem({ ...missionItem, sameAs: e.target.value }); }} >
                                <option value={0} >Select Mission</option>
                                {props.missionData.length > 0 && props.missionData.map((mission) => {
                                    if (mission.id !== missionItem.id) {
                                        return (<option value={mission.id}>{mission.missionName}</option>);
                                    }
                                })
                                }
                            </Form.Select>
                        </Form.Group>
                    </>
                    }
                </Accordion.Body>
            </Form>
        </Accordion.Item >
        <Modal
            show={showDeleteModal}
            centered
            className="admin-modal"
        >
            <Modal.Header >
                <Modal.Title>Delete mission</Modal.Title>
                <div className="close" onClick={() => setShowDeleteModal(false)}>
                    <span aria-hidden="true">&times;</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this mission?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" size="sm" onClick={deleteMission}>
                    Yes, Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default Mission;