import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";

const BulkAction = (props) => {
    const [show, setShow] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [role, setRole] = React.useState('');
    const [teamId, setTeamId] = React.useState('');
    const [preferredMission, setPreferredMission] = React.useState('');
    const [isApproved, setIsApproved] = React.useState(false);

    useEffect(() => {
        setSelectedUsers(props.selectedRows || []);
    }, [props.selectedRows]);

    useEffect(() => {
        // console.log('selectedUsers', selectedUsers);
    }, [selectedUsers]);

    const UpdateUsers = (e) => {

        e.preventDefault();

        let updatedUsers = selectedUsers.map((user) => {
            let userDetails = props.usersList.find(x => x.id === user);
            console.log('userDetails', userDetails);
            return { id: user, teamId: teamId, preferredMission: preferredMission, isApproved: Boolean(isApproved), isActive: userDetails.isActive };
        });

        console.log('bulkAction', updatedUsers);

        ProgramSetupAxios.put(`/EligiblePool/updateeligibleusers`, updatedUsers)
            .then(() => {
                // props.setSelectedRows([]);
                props.onSuccess();
                setShow(false);
            }).catch(err => {
                console.log("updateUser: err", err);
            });
    }

    return (
        <>
            <Button variant="primary" className="btn-sm" onClick={() => setShow(true)} disabled={selectedUsers.length > 0 ? false : true}>
                <i className="fa fa-pencil-square-o me-2" /> Bulk Action
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                animation={false}
                className="admin-modal"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Action [{selectedUsers.length}]</Modal.Title>
                </Modal.Header>
                <form onSubmit={UpdateUsers}>
                    <Modal.Body>
                        <div className="row">
                            {/* <div className="form-group col-6">
                                <label htmlFor="role">Role</label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={role}
                                        onChange={(e) => { setRole(e.target.value); }}>
                                        <option value="">role</option>
                                        <option value="4">Learner</option>
                                        <option value="5">Coach</option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="form-group col-6">
                                <label htmlFor="preferredMission">Preferred Mission<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={preferredMission}
                                        onChange={(e) => { setPreferredMission(e.target.value); setTeamId(0); }}
                                        required
                                    >
                                        <option value="">Select mission</option>
                                        {
                                            props.localMissions.map((mission, index) => {
                                                return <option key={index} value={mission.value} >{mission.label}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-6">
                                <label htmlFor="teamId">Team<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    {/* {JSON.stringify(props.localTeams.filter(obj => obj.isConclave === false && obj.missionId == preferredMission))} */}

                                    <select
                                        className="form-control"
                                        value={teamId}
                                        onChange={(e) => { setTeamId(e.target.value); }}
                                        required
                                    >
                                        <option value="">Select team</option>
                                        {
                                            props.localTeams.filter(obj => obj.isConclave === false && obj.missionId === parseInt(preferredMission))
                                                .map((team, index) => {
                                                    return <option key={index} value={team.id} >{team.teamName}</option>;
                                                })
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className="form-group col-6">
                                <label htmlFor="isApproved">Approved</label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={isApproved}
                                        onChange={(e) => { setIsApproved(e.target.value); }}
                                    >
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default BulkAction;