import React, { useContext, useDebugValue, useEffect, useState } from "react";
import "./MissionSetup.css";
import Mission from "./Mission";
import { ProgramSetupAxios } from "../../../API";
import { Button } from 'react-bootstrap';
import { Accordion } from "react-bootstrap";
import UserContext from "../../../UserContext";


const MissionSetup = (props) => {
    const user = useContext(UserContext);
    const [missionData, setMissionData] = useState([]);
    const [stageData, setStageData] = useState();
    const [activeKey, setActiveKey] = useState(1)



    useEffect(() => {
        setMissionData([]);
        getMissionByOfferingId();
        getStageData();

    }, [user.selectedOffering]);

    const getStageData = () => {
        ProgramSetupAxios.get("OfferingStage/getstagedetailsbyofferingid/" + user.selectedOffering)
            .then(function (response) {
                setStageData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getMissionByOfferingId = () => {
        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + user.selectedOffering)
            .then(function (response) {
                setMissionData(response.data);
                if (response.data.length < 1) {
                    setMissionData([{
                        id: 0,
                        offeringId: null,
                        missionName: "",
                        imagePath: "",
                        shortDescription: "",
                        longDescription: "",
                        sequence: 1,
                        coachMaxSeats: null,
                        learnerMaxSeats: null,
                        additionalInfo: "",
                        journeyVideo: "",
                        sameAs: 0
                    }]);

                } else {
                    let updatedMenuStatus = [
                        { link: '/outline/missions', isCompleted: true, unLocked: true },
                        { link: '/outline/stages', unLocked: true }
                    ];
                    user.setMenuStatus(updatedMenuStatus)

                }

            })
            .catch(function (error) {
                console.log(error);
                // setMissionData([]);
            });
    };

    const addMission = () => {
        let tempMissionData = JSON.parse(JSON.stringify(missionData));

        var sequence = 0;

        if (tempMissionData && tempMissionData.length > 0) {
            tempMissionData.forEach(element => {
                if (element.sequence > sequence) {
                    sequence = element.sequence;
                }
            });
        }
        let temp = {
            id: 0,
            offeringId: null,
            missionName: "",
            imagePath: "",
            shortDescription: "",
            longDescription: "",
            sequence: sequence + 1,
            coachMaxSeats: null,
            learnerMaxSeats: null,
            additionalInfo: "",
            sameAs: 0
        };

        tempMissionData.push(temp);
        setMissionData(tempMissionData);
    };


    // const handleChange = (index, data) => {
    //     let tempData = JSON.parse(JSON.stringify(missionData));
    //     tempData[index] = data;
    //     setMissionData(tempData);
    // };

    const toogleAccordion = (key) => {
        if (key === activeKey) {
            setActiveKey(0)
        }
        else {
            setActiveKey(key);
        }
    }
    return (<>
        <div className="page-header">
            <h1>Missions within the Offering</h1>
            <Button onClick={() => { addMission(); setActiveKey(missionData.length + 1); }} className="btn-sm" disabled={user.isPublished || missionData.length > 2}>
                + Add Mission
            </Button>
        </div>
        <div className="mission-container">
            <Accordion activeKey={activeKey}>
                {stageData && missionData.length > 0 && missionData.map((mission, index) => {
                    return <Mission setActiveKey={() => toogleAccordion(index + 1)} item={mission} key={"mission" + mission.id + missionData.length} index={index} getMissionByOfferingId={getMissionByOfferingId} missionData={missionData} stageData={stageData} />;
                })}
            </Accordion>
        </div>
        {/* <div className="add-mission-button" onClick={addMission}>
            + Add Mission</div> */}
    </>);

};
export default MissionSetup;