import React, { useState, useEffect, useContext } from "react";
import ExportExcel from "../Exports/ExportExcel";
import DataTable from "../DataTable/DataTable";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import Moment from "react-moment";
import UserContext from "../../UserContext";

const TeamProgress = (props) => {
    const user = useContext(UserContext);
    const [tableData, setTableData] = useState({});
    const [filters, setFilters] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [teamProgressList, setTeamProgressList] = useState([]);
    const [missionData, setMissionData] = useState([]);
    const [selectedOfferingName, setSelectedOfferingName] = useState();
    const [loaderShow, setLoaderShow] = React.useState(false);

    useEffect(() => {
        setLoaderShow(true);
        if (user.offeringList && user.selectedOffering) {
            var tempName = user.offeringList.filter(x => x.id == user.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getTeamProgress();
        }
    }, [user.selectedOffering, props.offeringLis]);


    useEffect(() => {
        setMissionData(props.preferredMission);
    }, [props.preferredMission]);

    useEffect(() => {
        generateTableData();
    }, [teamProgressList, missionData]);

    const getTeamProgress = () => {

        OnboardingAxios.get("/Team/getteamprogressreport/" + user.selectedOffering)
            .then(function (response) {
                setTeamProgressList(response.data);
                setLoaderShow(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });

    }

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: '-' },
                    ...missionData.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]
            },
            {
                label: "Team",
                field: 'team',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Team Points",
                field: 'teamPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Points',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Team Rank",
                field: 'TeamRanks',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Ranks',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Team Completion",
                field: 'teamCompletion',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Completion',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Coach Name",
                field: 'coachName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Coach Name',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Coach Email",
                field: 'CoachEmail',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Coach Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Solution Submitted",
                field: 'solutionSubmitted',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Solution Submitted',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Solution Rating",
                field: 'solutionRatings',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Solution Ratings',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Award Category",
                field: 'awardCategory',
                attributes: { 'aria-controls': 'DataTable' },
                filter: false,
                filterType: 'text',
            }
        ];
        let rows = [];
        teamProgressList.forEach((teamProgress) => {
            let row = {};
            let mission = missionData.find(x => x.id === teamProgress.missionId);;
            let offeringName = selectedOfferingName;

            row.id = { value: teamProgress.id, label: teamProgress.id, export: teamProgress.id };
            row.offeringName = { value: offeringName, label: offeringName, export: offeringName };
            row.team = { value: teamProgress.teamName, label: teamProgress.teamName, export: teamProgress.teamName };
            row.TeamRanks = { value: teamProgress.currentRank, label: teamProgress.currentRank, export: teamProgress.currentRank };
            row.teamPoints = { value: teamProgress.totalPoints, label: teamProgress.totalPoints, export: teamProgress.totalPoints };
            row.teamCompletion = { value: teamProgress.avgTeamCompletion, label: Math.round(teamProgress.avgTeamCompletion * 100) / 100, export: Math.round(teamProgress.avgTeamCompletion * 100) / 100 }
            row.missionId = {
                value: teamProgress.missionId,
                label: mission
                    ? mission.missionName
                    : '-',
                export: mission
                    ? mission.missionName
                    : '-',
            };
            row.coachName = { value: teamProgress.coachName, label: teamProgress.coachName, export: teamProgress.coachName };
            row.CoachEmail = { value: teamProgress.coachEmail, label: teamProgress.coachEmail, export: teamProgress.coachEmail };
            row.solutionSubmitted = { value: teamProgress.solutionSubmitted, label: teamProgress.solutionSubmitted ? 'Yes' : 'No', export: teamProgress.solutionSubmitted ? 'Yes' : 'No' };
            row.solutionRatings = { value: teamProgress.solutionRating, label: teamProgress.solutionRating, export: teamProgress.solutionRating };
            row.awardCategory = { value: teamProgress.awardCategory, label: teamProgress.awardCategory, export: teamProgress.awardCategory };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    return (<>
        <div className="page-header mb-4">
            <h4>Team Progress Report</h4>
            <div className="page-actions">
                <ExportExcel jsonObject={filterData} columns={tableData.columns} fileName="TeamProgress.csv" />
            </div>
        </div>
        <div className="data-table">
            <DataTable loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} multiSelect={false} />
        </div>
    </>);
}

export default TeamProgress;