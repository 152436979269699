import React, { useContext, useEffect } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';
import UserContext from "../../UserContext";
import ConfirmDialog from "../Layout/ConfirmDialog";



const ThoughtCatalog = (props) => {
    const user = useContext(UserContext);
    const [key, setKey] = React.useState("intro");
    const [thoughtCatalog, setThoughtCatalog] = React.useState();
    // const [stageData, setStageData] = React.useState();
    const [tabContent, setTabContent] = React.useState([]);
    const [thoughtCatalogId, setThoughtCatalogId] = React.useState(null);

    useEffect(() => {
        if (user.selectedOffering > 0) {
            getThoughtCatalog();
        }
    }, [user.selectedOffering]);

    const getThoughtCatalog = () => {
        ProgramSetupAxios.get("/ThoughtCatalog/getthoughtcatalogbyofferingid/" + user.selectedOffering)
            .then(function (response) {
                setThoughtCatalog(response.data);
                getTabContent(response.data.id);
            }
            ).catch(function (error) {
                console.log(error);
            }
            );

    }

    const getTabContent = (id = thoughtCatalogId) => {
        ProgramSetupAxios.get('/ThoughtCatalog/getthoughtcatalogtabs/' + id)
            .then(function (response) {
                //update activityIFrameLink
                setTabContent(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const IntroSection = (props) => {
        const [thoughtCatalogId, setThoughtCatalogId] = React.useState(null);
        const [name, setName] = React.useState();
        const [subTitle, setSubTitle] = React.useState();
        const [introDescription, setIntroDescription] = React.useState();
        const [buttonText, setButtonText] = React.useState();
        const [disableThoughtCatalog, setDisableThoughtCatalog] = React.useState(false);

        useEffect(() => {
            if (props.thoughtCatalog != null) {
                setThoughtCatalogId(props.thoughtCatalog.id);
                setName(props.thoughtCatalog.name);
                setSubTitle(props.thoughtCatalog.subtitle);
                setIntroDescription(props.thoughtCatalog.description);
                setButtonText(props.thoughtCatalog.buttonLabel);
            }
        }, [props.thoughtCatalog]);

        const saveIntro = (e) => {
            e.preventDefault();
            let data = {
                name: name,
                subtitle: subTitle,
                description: introDescription,
                buttonLabel: buttonText,
                offeringId: user.selectedOffering,
            };

            if (thoughtCatalogId) {
                data.id = thoughtCatalogId;
            }

            ProgramSetupAxios.post("/ThoughtCatalog/createthoughtcatalog", data)
                .then(function (response) {
                    user.createToast({ title: "Thought Catalog", message: "Thought Catalog saved successfully", variant: "success" });
                    getThoughtCatalog();

                })
                .catch(function (error) {
                    user.createToast({ title: "Thought Catalog", message: "Error saving Thought Catalog", variant: "danger" });
                    console.log(error);
                });
        }

        return (
            <form onSubmit={saveIntro}>
                <div className="row ">
                    <div className="col-12 form-group">
                        <div className="page-header">
                            <div >
                                <input type="checkbox" className="form-check-input me-2" id="checkbox" onChange={() => setDisableThoughtCatalog(!disableThoughtCatalog)} />
                                <label className="form-check-label" htmlFor="checkbox">Disable Thought Catalog</label>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <Button type="submit" size="sm" variant="primary" disabled={user.isPublished}>
                                    <i className="fa fa-floppy-o me-2" />{thoughtCatalogId > 0 ? 'Save' : "Create"}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="form-group">
                            <label>Name<span className="required-star">*</span></label>
                            <input type="text" className="form-control" placeholder="Intro Title" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Subtitle<span className="required-star">*</span></label>
                            <input type="text" className="form-control" placeholder="Intro SubTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} required />
                        </div>
                    </div>

                    <div className="col-12 mb-2">
                        <div className="form-group">
                            <label>Description<span className="required-star">*</span></label>
                            <textarea className="form-control" rows='5' placeholder="Intro Description" value={introDescription} onChange={(e) => setIntroDescription(e.target.value)} required />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Button label<span className="required-star">*</span> </label>
                            <input type="text" className="form-control" placeholder="Enter here" value={buttonText} onChange={(e) => setButtonText(e.target.value)} required />
                        </div>
                    </div>

                </div>
            </form>
        );
    }

    const TabContent = (props) => {
        const [thoughtCatalog, setThoughtCatalog] = React.useState(props.thoughtCatalog);
        const [tabContent, setTabContent] = React.useState(props.tabContent);
        const [tabContentId, setTabContentId] = React.useState();
        const [newQuestions, setNewQuestions] = React.useState([]);

        useEffect(() => {
            if (props.tabContent != null) {
                setTabContent(props.tabContent);
                setTabContentId(props.tabContent.id);
            }

        }, [props.tabContent]);

        // useEffect(() => {
        //     console.log("newQuestions", newQuestions);
        // }, [newQuestions]);


        const saveTabContent = (e) => {

            e.preventDefault();
            let data = {
                tabDisplayName: tabContent.tabDisplayName,
                qoute: tabContent.qoute,
                qouteAuthor: tabContent.qouteAuthor,
                curaLink: tabContent.curaLink,
                curaLinkDesc: tabContent.curaLinkDesc,
                reflectionDesc: tabContent.reflectionDesc,
                activityIFrameLink: tabContent.activityIFrameLink,
                activityDesc: tabContent.activityDesc,
                backgroundImage: tabContent.backgroundImage || '',
                stageId: 0,
                thoughtCatalogId: thoughtCatalog.id,
            }

            if (tabContentId) {
                data.id = tabContentId;
                data.sortOrder = tabContent.sortOrder
            } else {
                data.sortOrder = props.order
            }

            let questionData = []
            if (tabContent.questions && tabContent.questions.length > 0) {
                tabContent.questions.map((question) => {
                    questionData.push(question)
                })
            }

            if (newQuestions.length > 0) {
                newQuestions.map((question) => {
                    questionData.push(question)
                })
            }

            ProgramSetupAxios.post("/ThoughtCatalog/createthoughtcatalogtabs", [data])
                .then(function (response) {
                    questionData = questionData.map((question) => {
                        return { ...question, thoughtCatalogTabId: response.data[0].id }
                    });
                    user.createToast({ title: "Thought Catalog", message: "Tab saved successfully", variant: "success" });
                    ProgramSetupAxios.post("/ThoughtCatalog/createthoughtcatalogquestions", questionData)
                        .then(function (response) {
                            getThoughtCatalog();
                            setNewQuestions([]);
                        })
                        .catch(function (error) {
                            console.log(error);
                            setNewQuestions([]);

                        });
                    // getThoughtCatalog();
                })
                .catch(function (error) {
                    console.log(error);
                    user.createToast({ title: "Thought Catalog", message: "Error saving tab details", variant: "danger" });
                });




        }



        const handleDelete = (questionId) => {
            ProgramSetupAxios.delete("/ThoughtCatalog/deletehoughtcatalogQuestion/" + questionId)
                .then(function (response) {
                    getThoughtCatalog();
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        const DeleteQuestion = (props) => {
            const [showDeleteQuestion, setShowDeleteQuestion] = React.useState(false);

            return (
                <div>
                    <Button size="sm" variant="outline-danger" onClick={() => setShowDeleteQuestion(true)} disabled={user.isPublished}>
                        <i className="fa fa-trash-o" />
                    </Button>
                    <ConfirmDialog
                        open={showDeleteQuestion}
                        onCancel={() => setShowDeleteQuestion(false)}
                        onConfirm={() => handleDelete(props.questionId)}
                        title="Delete Question"
                        message={<><h5>Are you sure you want to delete this question?</h5>
                            <p>Deleting item from this stage cannot be undone.</p></>
                        }
                    />
                </div>
            );

        }

        const deleteTabContent = (id) => {
            ProgramSetupAxios.delete("/ThoughtCatalog/deletehoughtcatalogTab/" + id)
                .then(function (response) {
                    setKey('intro');
                    getThoughtCatalog();
                    user.createToast({ title: "Delete tab", message: "Tab deleted successfully", variant: "success" });
                })
                .catch(function (error) {
                    user.createToast({ title: "Delete tab", message: "Error deleting tab", variant: "danger" });
                    console.log(error);
                });
        }

        const DeleteTab = (props) => {
            const [showDeleteTab, setShowDeleteTab] = React.useState(false);

            const handleDeleteClose = () => setShowDeleteTab(false);
            const handleDeleteShow = () => setShowDeleteTab(true);

            return (
                <div>
                    <Button size="sm" variant="outline-danger" onClick={handleDeleteShow} disabled={user.isPublished}>
                        <i className="fa fa-trash-o me-2" />Delete Tab
                    </Button>
                    <ConfirmDialog
                        open={showDeleteTab}
                        onCancel={handleDeleteClose}
                        onConfirm={() => deleteTabContent(props.tabContentId)}
                        title="Delete Tab"
                        message={<><h5>Are you sure you want to delete this tab?</h5>
                            <p>Deleting item from this stage cannot be undone.</p></>
                        }
                    />
                </div>

            )
        }

        return (
            <form onSubmit={saveTabContent}>
                <div className="row mb-4 align-items-end ">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Tab Name<span className="required-star">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Tab name"
                                value={tabContent.tabDisplayName}
                                onChange={(event) => {
                                    setTabContent({
                                        ...tabContent,
                                        tabDisplayName: event.target.value
                                    });
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="col ">
                        <div className="page-actions">
                            <Button type="submit" variant="primary" size="sm" disabled={user.isPublished}>
                                <i className="fa fa-floppy-o me-2" />{tabContentId ? 'Save' : "Create"}
                            </Button>
                            {tabContentId &&
                                <DeleteTab tabContentId={tabContentId} handleDelete={deleteTabContent} />
                            }
                        </div>

                    </div>

                </div>
                <h5>Quote and Cura</h5>
                <div className="row mb-4">
                    <div className="col form-group">
                        <label>Quote Text</label>
                        <textarea
                            className="form-control"
                            rows="2"
                            placeholder="Quote text"
                            value={tabContent.qoute}
                            onChange={(event) => {
                                setTabContent({
                                    ...tabContent,
                                    qoute: event.target.value
                                });
                            }}
                        />
                    </div>
                    <div className="col-12 form-group">
                        <label>Quote Author</label>
                        <input
                            className="form-control"
                            placeholder="Quote author"
                            value={tabContent.qouteAuthor}
                            onChange={(event) => {
                                setTabContent({
                                    ...tabContent,
                                    qouteAuthor: event.target.value
                                });
                            }}
                        />
                    </div>

                    <div className="col-12 form-group">
                        <label>Cura Link Desc</label>
                        <CKEditor
                            className={"mission-full-width"}
                            editor={Editor}
                            data={tabContent.curaLinkDesc}
                            config={{
                                placeholder: "Enter description",
                                // links in new tab
                                link: { addTargetToExternalLinks: true, },

                            }}
                            onInit={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle("min-height", "200px", editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTabContent({
                                    ...tabContent,
                                    curaLinkDesc: data
                                });
                            }}
                        />
                    </div>
                    <div className="col-12 form-group">
                        <label>Cura Link</label>
                        <input
                            className="form-control"
                            placeholder="Cura link"
                            value={tabContent.curaLink}
                            onChange={(event) => {
                                setTabContent({
                                    ...tabContent,
                                    curaLink: event.target.value
                                });
                            }}
                        />
                    </div>
                </div>

                <h5>Reflection and Activity</h5>
                <div className="row mb-4">
                    <div className="col">
                        <div className="form-group">
                            <label>Reflection Description</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                placeholder="Reflection Description"
                                value={tabContent.reflectionDesc}
                                onChange={(event) => {
                                    setTabContent({
                                        ...tabContent,
                                        reflectionDesc: event.target.value
                                    });
                                }}
                            />

                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <h6 className="me-4">Questions</h6>
                    <Button size="sm" variant="outline-primary" onClick={() => {
                        setNewQuestions([
                            ...newQuestions,
                            {
                                title: tabContent.title,
                                thoughtCatalogTabId: tabContent.id,
                                type: 1,
                                sortOrder: tabContent.questions ? tabContent.questions.length + newQuestions.length + 1 : newQuestions.length + 1,
                            }
                        ])
                    }} >
                        <i className="fa fa-plus me-2" />Add Question</Button>
                </div>

                <div className="row mb-4">
                    {tabContent.questions && tabContent.questions.sort((a, b) => a.sortOrder - b.sortOrder).map((question, index) => {
                        if (question.sortOrder === 0) {
                            question.sortOrder = index + 1;
                        }
                        return (
                            <div className="col-12 form-group" data-id={'q-' + index} data-item-sortable-id={index} draggable="true">
                                <div className="question-header">
                                    <label>Question {index + 1}</label>
                                    <div className="question-header m-0">
                                        {/* <div className="d-flex">
                                            <Button size="sm" variant="outline-primary" className="me-2"
                                                onClick={() => {
                                                    question.sortOrder = question.sortOrder - 1;
                                                }}
                                                disabled={question.sortOrder <= 1}
                                            >
                                                <i className="fa fa-caret-up" />
                                            </Button>
                                            <Button size="sm" variant="outline-primary"
                                                onClick={() => {
                                                    // let questionData = tabContent.questions;
                                                    // questionData[index].sortOrder = questionData[index].sortOrder + 1;
                                                    // setTabContent({ ...tabContent, questions: questionData });
                                                }}>
                                                <i className="fa fa-caret-down" />
                                            </Button>
                                        </div> */}
                                        <DeleteQuestion questionId={question.id} handleDelete={() => handleDelete} />
                                    </div>
                                </div>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    placeholder="Question text"
                                    value={question.title}
                                    onChange={(event) => {
                                        setTabContent({
                                            ...tabContent,
                                            questions: tabContent.questions.map((q, i) => {
                                                if (i === index) {
                                                    return {
                                                        ...q,
                                                        title: event.target.value
                                                    }
                                                } else {
                                                    return q;
                                                }
                                            })
                                        });
                                    }}
                                />
                            </div>
                        )
                    })}
                    {newQuestions.map((question, index) => {
                        return (
                            <div className="col-12 form-group">
                                <div className="question-header">
                                    <label>Question {tabContent.questions ? tabContent.questions.length + index + 1 : index + 1}</label>
                                    <Button size="sm" variant="outline-warning" onClick={() => { setNewQuestions(newQuestions.filter((q, i) => i !== index)); }}>
                                        <i className="fa fa-trash-o" />
                                    </Button>
                                </div>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    placeholder="Question text"
                                    value={question.title}
                                    onChange={(event) => {
                                        setNewQuestions(newQuestions.map((q, i) => {
                                            if (i === index) {
                                                return {
                                                    ...q,
                                                    title: event.target.value
                                                }
                                            } else {
                                                return q;
                                            }
                                        }));
                                    }}
                                />
                            </div>
                        )
                    })}


                </div>

                <div className="d-flex align-items-center">
                    <h6 className="me-4">Activity</h6>
                </div>
                <div className="row ">
                    <div className="col-12 form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control"
                            rows="2"
                            placeholder="Activity Description"
                            value={tabContent.activityDesc}
                            onChange={(event) => {
                                setTabContent({
                                    ...tabContent,
                                    activityDesc: event.target.value
                                });
                            }}
                        />

                    </div>

                    <div className="col-12 form-group">
                        <label>Link</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Activity Link"
                            value={tabContent.activityIFrameLink}
                            onChange={(event) => {
                                setTabContent({
                                    ...tabContent,
                                    activityIFrameLink: event.target.value
                                });
                            }}
                        />
                    </div>
                </div>
            </form>
        );
    }

    return (
        <>
            <div className="page-header mb-3">
                <h1>Thought Catalog</h1>
                <div className="page-actions">
                </div>
            </div>
            <div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="intro" title="Intro">
                        <IntroSection thoughtCatalog={thoughtCatalog} selectedOffering={user.selectedOffering} />
                    </Tab>
                    {tabContent.map((tab, index) => {
                        return <Tab eventKey={tab.id} title={tab.tabDisplayName} key={"tab-content-" + index}>
                            <TabContent thoughtCatalog={thoughtCatalog} tabContent={tab} />
                        </Tab>
                    })}
                    <Tab eventKey={'add'} title={<i className="fa fa-plus" />} disabled={thoughtCatalog && thoughtCatalog.id && !user.isPublished ? false : true}>
                        <TabContent thoughtCatalog={thoughtCatalog} tabContent={{}} order={tabContent.length + 1} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default ThoughtCatalog;