import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";


const AwardCategory = (props) => {
    const user = useContext(UserContext);
    const [loading, setLoading] = React.useState(false);

    const [awardCategories, setAwardCategories] = React.useState([]);
    const [tableData, setTableData] = React.useState({});

    useEffect(() => {
        // getAwardCategory();
        setAwardCategories(props.awardCategories)
    }, [props.awardCategories]);

    useEffect(() => {
        generateTableData();
    }, [awardCategories]);

    // const getAwardCategory = () => {
    //     ProgramSetupAxios.get("/SolutionTag/getsolutiontags/" + user.selectedOffering)
    //         .then(response => {
    //             setAwardCategories(response.data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             setLoading(false);
    //         });
    // }


    const DeleteLink = (awardCategory) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: awardCategory.id,
                ...awardCategory
            }

            ProgramSetupAxios.delete("/SolutionTag/deletesolutiontag/" + data.id)
                .then(function () {
                    props.getAwardCategory();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Award Category</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this category?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const generateTableData = () => {
        let columns = [
            {
                label: "Award Category",
                field: 'tagName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Award Category',
                }
            },

            {
                label: "Actions",
                field: 'actions',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Actions',
                    className: user.isPublished ? 'text-center w-10 locked' : 'text-center w-10',
                }
            }
        ]

        let rows = [];
        awardCategories.forEach(awardCategory => {
            let row = {};
            row.id = { value: awardCategory.id, label: awardCategory.id, export: awardCategory.id };
            row.tagName = { value: awardCategory.tagName, label: awardCategory.tagName, export: awardCategory.tagName };

            row.actions = { value: "", label: <DeleteLink {...awardCategory} />, export: "" };
            rows.push(row);
        })

        setTableData({ columns, rows });

    }

    const AddAwardCategory = (props) => {
        const [show, setShow] = useState(false);
        const [awardCategory, setAwardCategory] = useState({
            offeringId: user.selectedOffering,
            tagName: "",
        });

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const handleSubmit = (e) => {
            e.preventDefault();
            let data = {
                offeringId: user.selectedOffering,
                tagName: awardCategory.tagName,
            }

            ProgramSetupAxios.post("/SolutionTag/addsolutiontag", data)
                .then(response => {
                    handleClose();
                    props.getAwardCategory();
                }).catch(error => {
                    console.log(error);
                });

        }

        return (
            <>
                <Button onClick={handleShow} size="sm" className="btn-sm">
                    + Add Award Category
                </Button>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    centered
                    size="md"
                ><form onSubmit={handleSubmit}>
                        <Modal.Header >
                            <Modal.Title>Add Award Category</Modal.Title>
                            <div className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="couponName">Award Category<span className="danger">*</span></label>
                                <input type="text" className="form-control" placeholder="Token Name" value={awardCategory.tagName} onChange={(e) => setAwardCategory({ ...awardCategory, tagName: e.target.value })} required />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" className="btn-text" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" size="sm" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );

    }

    return (
        <>
            <div className="page-header  mb-4">
                <h1>Award Category</h1>
                <AddAwardCategory getAwardCategory={props.getAwardCategory} />
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} loaderShow={loading} {...tableData} />
            </div>
        </>
    );
}

export default AwardCategory;