import React from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = () => {
    return (
        <div className="error-page">
            <div className="error-box">
                <h1>Error 404</h1>
                <h3><i className="fa fa-frown-o" /> Oops! Not Found</h3>
                <p>Sorry, the page you are looking for does not exist.</p>
                <NavLink to='dashboard' className={'link'} >Go to Home</NavLink>
            </div>
        </div>
    );
};

export default ErrorPage;