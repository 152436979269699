import React, { useContext } from "react";
import { Outlet } from "react-router";
import "./AdminLayout.css";
import AdminHeader from "./components/Layout/Header";
import AdminSidebar from "./components/Layout/Sidebar";
import UserContext from "./UserContext";

const AdminMainLayout = (props) => {
  const user = useContext(UserContext);
  const toggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("collapsed");
  };
  return (
    <div className="admin-layout">
      <AdminSidebar onlyAdminMenu={true} />
      <div className="main-content">
        <AdminHeader offeringList={user.offeringList} toggleSidebar={toggleSidebar} hideOfferingselect={true} />
        <div className="admin-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};


export default AdminMainLayout;
