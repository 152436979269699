import React, { useContext, useEffect, useState } from "react";
import { UserActivityAxios, ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import Moment from "react-moment";
import ExportExcel from "../Exports/ExportExcel";
import UserContext from "../../UserContext";
import ExportExcelAPI from "../Exports/ExportExcelAPI";
import moment from "moment";
import axios from "axios";

let cancelToken = axios.CancelToken.source();
const BasicUsage = (props) => {
    const localUserContext = useContext(UserContext);
    const [userActivityLogList, setUserActivityLogList] = React.useState([]);
    const [userActivityLogListExport, setUserActivityLogListExport] = React.useState([]);
    const [preferredMission, setPreferredMission] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const roleLabels = { 3: 'Admin', 4: 'Learner', 5: 'Coach' };
    const [filters, setFilters] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [email, setEmail] = useState("");
    const [missionId, setMissionId] = useState(0);
    const [actionType, setActionType] = useState("");
    const [actionName, setActionName] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);

    const [selectedOfferingName, setSelectedOfferingName] = React.useState();


    useEffect(() => {
        setLoaderShow(true);
        if (localUserContext.offeringList && localUserContext.selectedOffering) {
            var tempName = localUserContext.offeringList.filter(x => x.id == localUserContext.selectedOffering)
            setSelectedOfferingName(tempName[0].programName);
            getUserActivityLog();
        }
    }, [localUserContext.selectedOffering]);

    useEffect(() => {
        var userActivityLogListData = generateTableData(userActivityLogList);
        setTableData(userActivityLogListData);
    }, [userActivityLogList, preferredMission]);


    useEffect(() => {
        setPreferredMission(props.preferredMission);
    }, [props.preferredMission]);

    useEffect(() => {
        getUserActivityLog();
    }, [pageNumber, pageSize]);

    const getUserActivityLog = () => {
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        var data = {
            offeringId: localUserContext.selectedOffering,
            pageNumber: pageNumber,
            pageSize: pageSize,
            ...options
        }

        UserActivityAxios.post("/UserActivityLog/report", data)
            .then(function (response) {
                setUserActivityLogList(response.data);
                setTotalCount(response.data[0].totalCount)
                setLoaderShow(false);
                if (pageNumber == 1) {
                    getUserActivityLogExport();
                }
            })
            .catch(function (error) {
                console.log(error);
                setUserActivityLogList([]);
                setTotalCount(0);
                setLoaderShow(false);
            });

    };
    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const getUserActivityLogExport = () => {
        setIsLoadingExport(true);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        var data = {
            offeringId: localUserContext.selectedOffering,
            pageNumber: 0,
            pageSize: 0,
            ...options
        }

        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();
        UserActivityAxios.post("/UserActivityLog/report", data, { cancelToken: cancelToken.token })
            .then(function (response) {
                setIsLoadingExport(false);
                var userActivityLogListData = generateTableData(response.data);
                setUserActivityLogListExport(userActivityLogListData?.rows);
            })
            .catch(function (error) {
                setIsLoadingExport(false);
                console.log(error);
                setUserActivityLogListExport([]);

            });

    };


    const generateTableData = (rawData) => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering Name",
                field: 'offeringName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering Name',
                    className: 'text-center',
                },
                sort: true,
                filter: false,
                filterType: 'text',
            },
            {
                label: "Email",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Mission",
                field: 'missionId',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: '', label: '-' },
                    ...preferredMission.map((x) => {
                        return { value: x.id, label: x.missionName };
                    }),
                    { value: 0, label: 'Blank' },
                ]

            },
            {
                label: "Action Type",
                field: 'actionType',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action Type',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Action Name",
                field: 'actionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Action On",
                field: 'actionOn',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action On',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
            {
                label: "Activity On",
                field: 'activityOn',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Activity On',
                },
                sort: true,
                filter: false,
                filterType: 'text',

            },
        ];
        let rows = [];
        rawData.forEach((userActivity) => {
            let row = {};
            let mission = preferredMission.find(x => x.id === userActivity.missionId);
            let offeringName = selectedOfferingName;

            row.id = { value: userActivity.id, label: userActivity.id, export: userActivity.id };
            row.offeringName = { value: offeringName, label: offeringName, export: offeringName };
            row.email = { value: userActivity.email, label: userActivity.email, export: userActivity.email };
            row.actionType = { value: userActivity.actionType, label: userActivity.actionType, export: userActivity.actionType };
            row.actionName = { value: userActivity.actionName, label: userActivity.actionName, export: userActivity.actionName };
            row.missionId = {
                value: userActivity.missionId,
                label: mission
                    ? mission.missionName
                    : '-',
                export: mission && mission.missionName
            };
            row.actionOn = { value: userActivity.actionOn, label: userActivity.actionOn, export: userActivity.actionOn };
            row.activityOn = { value: userActivity.activityOn, label: userActivity.activityOn ? <Moment format="YYYY-MM-DD">{userActivity.activityOn}</Moment> : '-', export: userActivity.activityOn ? moment(userActivity.activityOn).format("YYYY-MM-DD") : '-' };
            rows.push(row);
        });

        return { columns, rows };
    };


    return (
        <>
            <div className="page-header mb-4">
                <h4>Basic Usage Report</h4>
                <div className="page-actions">
                    <ExportExcel jsonObject={userActivityLogListExport} isLoading={isLoadingExport} columns={tableData.columns} fileName="BasicUsage.csv" />

                </div>
            </div>
            <div className="data-table">
                <DataTable loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} multiSelect={false} pageSize={pageSize} setPageNo={setPageNumber}
                    applyFilters={getUserActivityLog} apiPagination={true} setPageSize={setPageSize} totalCount={totalCount} />
            </div>
        </>
    );
};

export default BasicUsage;
