import React, { useContext, useEffect, useState } from "react";
import { OnboardingAxios, ProgramSetupAxios } from "../../API";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Registrations from "./Registrations";
import BasicUsage from "./BasicUsage";
import LearnerProgress from "./LearnerProgress";
import ThoughtCatalog from "./ThoughtCatalog";
import OfferingReports from "./OfferingSummary";
import TeamProgress from "./TeamProgress";
import SkillTrajectory from "./SkillTrajectory";
import CommentsLike from "./CommentsLike";
import CoachProgress from "./CoachProgress";
import UserContext from "../../UserContext";
import UserCoupon from "./UserCoupon";

const Reports = (props) => {
    const [key, setKey] = React.useState('registration');
    const localUserContext = useContext(UserContext);
    const [preferredMission, setPreferredMission] = useState([]);

    useEffect(() => {
        getMissionsByOffering();
    }, [])

    const getMissionsByOffering = () => {
        ProgramSetupAxios.get("/OfferingMission/getmissionsbyoffering/" + localUserContext.selectedOffering)
            .then(function (response) {
                setPreferredMission(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="registration" title="Registrations">
                    {key == "registration" && <Registrations selectedOfferingName={props.selectedOfferingName} preferredMission={preferredMission} />}
                </Tab>
                <Tab eventKey="basicUsage" title="Basic Usage">
                    {key == "basicUsage" && <BasicUsage selectedOfferingName={props.selectedOfferingName} preferredMission={preferredMission} />}
                </Tab>
                <Tab eventKey="learnerProgress" title="Learner Progress">
                    {key == "learnerProgress" && <LearnerProgress selectedOfferingName={props.selectedOfferingName} preferredMission={preferredMission} />}
                </Tab>
                {/* <Tab eventKey="thoughtCatalog" title="Thought Catalog">
                    <ThoughtCatalog   selectedOfferingName={props.selectedOfferingName} />
                </Tab> */}
                <Tab eventKey="TeamProgress" title="Team Progress">
                    {key == "TeamProgress" && <TeamProgress selectedOfferingName={props.selectedOfferingName} preferredMission={preferredMission} />}
                </Tab>
                {/* <Tab eventKey="offeringReports" title="Offering Reports">
                    <OfferingReports   selectedOfferingName={props.selectedOfferingName} />
                </Tab> */}
                <Tab eventKey="SkillTrajectory" title="Skill Trajectory">
                    {key == "SkillTrajectory" && <SkillTrajectory selectedOfferingName={props.selectedOfferingName} />}
                </Tab>
                <Tab eventKey="CommentsLike" title="Comments & Likes">
                    {key == "CommentsLike" && <CommentsLike offeringList={props.offeringList} selectedOffering={props.selectedOffering} selectedOfferingName={props.selectedOfferingName} />}
                </Tab>
                <Tab eventKey="CoachProgress" title="Coach Progress">
                    {key == "CoachProgress" && <CoachProgress selectedOfferingName={props.selectedOfferingName} />}
                </Tab>
                <Tab eventKey="UserCoupon" title="User Token">
                    {key == "UserCoupon" && <UserCoupon selectedOfferingName={props.selectedOfferingName} />}
                </Tab>
            </Tabs>
        </>
    );
};

export default Reports;
