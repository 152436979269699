import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import DataTable from "../DataTable/DataTable";
import UserContext from "../../UserContext";

const PointActivities = (props) => {
    const user = useContext(UserContext);
    const [offeringActivitiesData, setOfferingActivitiesData] = React.useState([]);
    const [offeringPointsData, setOfferingPointsData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [editData, setEditData] = React.useState({});
    const [show, setShow] = React.useState(false);
    const [refreshData, setRefreshData] = React.useState(false);
    const [activitiesPointList, setActivitiesPointList] = React.useState([]);
    const ActivityType = [
        {
            ActionName: "UserAdded",
            ActionKey: 1,
            ActionType: []
        },
        {
            ActionName: "CommentAdded",
            ActionKey: 2,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CommentLiked",
            ActionKey: 3,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CommentUnliked",
            ActionKey: 4,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "ConnectionAccepted",
            ActionKey: 5,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "ConnectionSent",
            ActionKey: 6,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "FeedCreated",
            ActionKey: 7,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "ForumCreated",
            ActionKey: 8,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionCommentAdded",
            ActionKey: 9,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionCommentLiked",
            ActionKey: 10,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionCommentUnliked",
            ActionKey: 11,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionShared",
            ActionKey: 12,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionViewed",
            ActionKey: 13,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "Dummy",
            ActionKey: 14,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CouponAwarded",
            ActionKey: 15,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingUserAdded",
            ActionKey: 16,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "TeamAssigned",
            ActionKey: 17,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "DelegationAdded",
            ActionKey: 18,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "DelegationStatusUpdated",
            ActionKey: 19,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "DelegationDeleted",
            ActionKey: 20,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "TeamCreated",
            ActionKey: 21,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "UserProfileUpdated",
            ActionKey: 22,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "EligiblePoolMissionUpdated",
            ActionKey: 23,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingActivityAdded",
            ActionKey: 24,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingNavigationAdded",
            ActionKey: 25,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingLinkAdded",
            ActionKey: 26,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingConfigurationAdded",
            ActionKey: 27,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingPublished",
            ActionKey: 28,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "RemainingSeatsUpdated",
            ActionKey: 29,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingCreated",
            ActionKey: 30,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingCouponCreated",
            ActionKey: 31,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingGeographyCreated",
            ActionKey: 32,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingLevelCreated",
            ActionKey: 33,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingMetadataCreated",
            ActionKey: 34,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingPointsCreated",
            ActionKey: 36,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingPointTypeCreated",
            ActionKey: 37,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "OfferingStageCreated",
            ActionKey: 38,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "ProfileMetadataCreated",
            ActionKey: 39,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "ProgramCreated",
            ActionKey: 40,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CatalogCreated",
            ActionKey: 41,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CatalogTabsCreated",
            ActionKey: 42,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CatalogQuestionsCreated",
            ActionKey: 43,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "NotificationSent",
            ActionKey: 44,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "NotificationRead",
            ActionKey: 45,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "TeamNotificationSent",
            ActionKey: 46,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "Mark Attendance",
            ActionKey: 47,
            ActionType: [1, 2],
            IsTeamPoint: true
        },
        {
            ActionName: "Coach Huddle Feedback",
            ActionKey: 48,
            ActionType: [1, 2],
            IsTeamPoint: true
        },
        {
            ActionName: "SolutionCreated",
            ActionKey: 49,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionLiked",
            ActionKey: 50,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionUnliked",
            ActionKey: 51,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionUpdated",
            ActionKey: 52,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "SolutionRated",
            ActionKey: 53,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "UserPointAdded",
            ActionKey: 54,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "CatalogAnswerAdded",
            ActionKey: 55,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "UserReflectionCreated",
            ActionKey: 56,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "UserReflectionUpdated",
            ActionKey: 57,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "PostIndividualAssignment",
            ActionKey: 58,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "Connect",
            ActionKey: 100,
            ActionType: [],
            IsTeamPoint: false
        },
        {
            ActionName: "100% Attendance",
            ActionKey: 101,
            ActionType: [1, 2],
            IsTeamPoint: true
        },
        {
            ActionName: "100% Team Connection",
            ActionKey: 102,
            ActionType: [],
            IsTeamPoint: true
        }
    ]
    useEffect(() => {
        setLoaderShow(true);
        getOfferingPointList();
    }, [props.selectedOffering]);

    useEffect(() => {
        generateTableData();
    }, [activitiesPointList]);


    useEffect(() => {
        if (offeringActivitiesData.length > 0 && offeringPointsData.length > 0) {
            let activitiesPoint = [];
            offeringPointsData.forEach((item) => {
                let tmp = offeringActivitiesData.find(x => x.id == item.activityId);
                if (tmp && tmp.activityId != 0) {
                    activitiesPoint.push({
                        "id": item.id,
                        "stageId": tmp.stageId,
                        "stageName": tmp.stageName,
                        "missionId": tmp.missionId,
                        "missionName": tmp.missionName,
                        "activityType": tmp.activityType,
                        "activityId": tmp.id,
                        "activityName": tmp.activityName,
                        "actionKey": item.actionKey,
                        "actionName": item.actionName,
                        "experiencePoints": item.pointsType == 1 && item.isTeamPoints == false ? item.pointsValue : 0,
                        "bonusPoints": item.pointsType == 2 && item.isTeamPoints == false ? item.pointsValue : 0,
                        "teamPoints": item.isTeamPoints == true ? item.pointsValue : 0,
                        "pointsValue": item.pointsValue,
                        "pointsType": item.pointsType,
                        "isTeamPoints": item.isTeamPoints

                    })
                }
            });
            setActivitiesPointList(activitiesPoint);
        }
    }, [offeringActivitiesData, offeringPointsData, refreshData]);


    const getOfferingPointList = () => {
        let cnt = 0;
        ProgramSetupAxios.get("/OfferingActivity/getofferingactivities/" + user.selectedOffering)
            .then(function (response) {
                if (response.data.length > 0) {
                    setOfferingActivitiesData(response.data);
                }
                cnt = cnt + 1;
                if (cnt == 2) {
                    setLoaderShow(false);
                    setRefreshData(!refreshData);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
        ProgramSetupAxios.get("/OfferingPoints/getofferingpoints/" + user.selectedOffering)
            .then(function (response) {
                setOfferingPointsData(response.data);
                cnt = cnt + 1;
                if (cnt == 2) {
                    setLoaderShow(false);
                    setRefreshData(!refreshData);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    };

    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'text-center hidden',
                },
                sort: true,
                filter: true,
                filterType: 'number',
            },
            {
                label: "Mission",
                field: 'missionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Mission',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Stage",
                field: 'stageName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Stage',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Activity",
                field: 'activityName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Activity',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "ActionName",
                field: 'actionName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'ActionName',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Individual - Experience Points",
                field: 'experiencePoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Individual - Experience Points',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Individual - Bonus Points",
                field: 'bonusPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Individual - Bonus Points',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Team Points",
                field: 'teamPoints',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Team Points',
                },
                sort: true,
                filter: true,
                filterType: 'text'
            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: user.isPublished ? 'text-center locked' : 'text-center',
                }
            },
        ];

        let rows = [];

        activitiesPointList.forEach((rowItem, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.missionName = { value: rowItem.missionName, label: rowItem.missionName, export: rowItem.missionName };
            row.stageName = { value: rowItem.stageName, label: rowItem.stageName, export: rowItem.stageName };
            row.activityName = { value: rowItem.activityName, label: rowItem.activityName, export: rowItem.activityName };
            row.actionName = { value: rowItem.actionName, label: rowItem.actionName, export: rowItem.actionName };
            row.experiencePoints = { value: rowItem.experiencePoints, label: rowItem.experiencePoints, export: rowItem.experiencePoints };
            row.bonusPoints = { value: rowItem.bonusPoints, label: rowItem.bonusPoints, export: rowItem.bonusPoints };
            row.teamPoints = { value: rowItem.teamPoints, label: rowItem.teamPoints, export: rowItem.teamPoints };
            row.action = {
                value: "", label: <Button onClick={() => { setShow(true); setEditData(rowItem); }
                } variant="outline-primary" size="sm" >
                    <i className="fa fa-pencil" />

                </Button >
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };

    const updateActivitiesPoint = (e) => {
        e.preventDefault();
        let data = [
            {
                "id": editData.id,
                "offeringId": user.selectedOffering,
                "actionName": editData.actionName,
                "actionKey": editData.actionKey,
                "targetValue": 0,
                "pointsValue": editData.pointsValue,
                "pointsType": editData.pointsType,
                "description": editData.actionName,
                "activityId": editData.activityId,
                "isTeamPoints": editData.isTeamPoints
            }
        ];
        ProgramSetupAxios.post("/OfferingPoints/offeringpoints/", data)
            .then(function (response) {
                getOfferingPointList();
                setShow(false);
                setLoaderShow(true);
            })
            .catch(function (error) {
                console.log(error);
                setLoaderShow(false);
            });
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Points for Activities</h4>
            </div>
            <div className="data-table">
                <DataTable multiSelect={false} {...tableData} loaderShow={loaderShow} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={updateActivitiesPoint}>
                    <Modal.Header>
                        <Modal.Title>Edit Points </Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        {/* {JSON.stringify(props.user)} */}
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="mission">Mission</label>
                                <input id="mission" type="text" value={editData.missionName || ""} className="form-control" disabled />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="stage">Stage</label>
                                <input id="stage" type="text" value={editData.stageName || ""} className="form-control" disabled />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="activity">Activity</label>
                                <input id="activity" type="text" value={editData.activityName || ""} className="form-control" disabled />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="action">Action</label>
                                <input id="action" type="text" value={editData.actionName || ""} className="form-control" disabled />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="points">Points Type<span className="required-star">*</span></label>
                                <div className="d-flex align-items-center gap-2">
                                    <select
                                        className="form-control"
                                        value={editData.pointsType || ""}
                                        onChange={(e) => { setEditData({ ...editData, pointsType: e.target.value }); }}
                                        required
                                    >
                                        <option value="">Select PointsType</option>
                                        <option value="1">Experience</option>
                                        <option value="2">Bonus</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Points<span className="required-star">*</span></label>
                                <input min={0} max={9999} value={editData.pointsValue || 0} className="form-control"
                                    onChange={(e) => { setEditData({ ...editData, pointsValue: e.target.value }); }} />
                            </div>
                            <div className="col-6">
                                <input type="checkbox" className="form-check-input me-2" defaultChecked={editData.isTeamPoints} id="checkbox" onChange={(e) => setEditData({ ...editData, isTeamPoints: e.target.checked })} />
                                <label className="form-check-label" htmlFor="checkbox">Team Points?</label>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit">Update</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default PointActivities;