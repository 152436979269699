import React, { useContext, useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import ImageUpload from "../FileUpload/ImageUpload";
import "./OfferingDetails.css";
import { LINK_TYPE } from "../../shared/Enum";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MissionMetadata from "./MissionMetadata";
import Loader from "../Loader/Loader";

const OfferingDetails = () => {
    const user = useContext(UserContext);
    const [offeringDetails, setOfferingDetails] = useState();
    const [onboardingVideo, setOnboardingVideo] = useState();
    const [key, setKey] = useState('information');

    useEffect(() => {
        getOfferingDetails();
    }, [])

    const getOfferingDetails = () => {
        ProgramSetupAxios.get("Offering/getoffering/" + user.selectedOffering)
            .then(function (response) {
                setOfferingDetails(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });


        ProgramSetupAxios.get("/OfferingLink/getofferinglinks/" + user.selectedOffering)
            .then(function (response) {
                // setOfferingLinkList(response.data);
                var linkData = response?.data?.find(x => x.linkType == LINK_TYPE.onboardingVideo)
                if (linkData) {
                    setOnboardingVideo(linkData);
                } else {
                    setOnboardingVideo({
                        id: 0,
                        offeringId: user.selectedOffering,
                        missionId: 0,
                        stageId: 0,
                        activityId: 0,
                        displayName: "Onboarding Video",
                        link: "",
                        linkType: LINK_TYPE.onboardingVideo,
                        target: "_self",
                        sequence: 0,
                        isActive: true
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const saveOfferingDetails = (e) => {
        e.preventDefault();
        var data = {
            offeringId: user.selectedOffering,
            programName: offeringDetails.programName,
            deliveryType: offeringDetails.deliveryType,
            industry: offeringDetails.industry,
            fy: offeringDetails.fy,
            fss: offeringDetails.fss,
            shortDescription: offeringDetails.shortDescription,
            longDescription: offeringDetails.longDescription,
            location: offeringDetails.location,
            deanFirstName: offeringDetails.deanFirstName,
            deanLastName: offeringDetails.deanLastName,
            deanEmail: offeringDetails.deanEmail,
            deanProfileImage: offeringDetails.deanProfileImage,
            deanBrief: offeringDetails.deanBrief
        }
        ProgramSetupAxios.put("Offering/updateofferingdetails", data)
            .then(function (response) {

                var onboardingData = {
                    offeringId: user.selectedOffering,
                    missionId: 0,
                    stageId: 0,
                    activityId: 0,
                    displayName: onboardingVideo.displayName,
                    link: onboardingVideo.link,
                    linkType: onboardingVideo.linkType,
                    target: onboardingVideo.target,
                    sequence: onboardingVideo.sequence,
                    isActive: true
                }

                if (onboardingVideo.id == 0) {

                    ProgramSetupAxios.post(`/OfferingConfiguration/offeringlinks`, [onboardingData])
                        .then(() => {
                            getOfferingDetails();
                            user.createToast({
                                title: "Offering Details",
                                message: "Offering details saved successfully",
                                variant: "success" // "success", "warning", "danger", "info"
                            }
                            )
                        }).catch(err => {
                            console.log("AddLink: err", err);
                        });
                } else {
                    ProgramSetupAxios.put(`/OfferingLink/updateofferinglinks`, onboardingVideo)
                        .then(() => {
                            getOfferingDetails();
                            user.createToast({
                                title: "Offering Details",
                                message: "Offering details saved successfully",
                                variant: "success" // "success", "warning", "danger", "info"
                            }
                            )
                        }).catch(err => {
                            console.log("AddLink: err", err);
                        });
                }

            })
    }


    const getProfileImage = (value) => {
        setOfferingDetails({ ...offeringDetails, deanProfileImage: value })
    }
    return <>

        <div className="page-header mb-1">
            <h1>Offering Details</h1>
        </div>
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey={'information'} title={'Offering Information'}>
                {offeringDetails ?
                    <form onSubmit={e => { saveOfferingDetails(e) }}>
                        <div className="page-header ">
                            <h5 className="m-0">Offering Information</h5>

                            <div className="page-actions">
                                <Button variant="success" className="btn-sm" type="submit" onClick={(e) => { e.stopPropagation(); }} ><i className="fa fa-check-circle me-2" />Save</Button>
                            </div>
                        </div>
                        <div className="program-container pt-3">
                            <div className="row mb-4">
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name" >Offering Name<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={200} onChange={(e) => { setOfferingDetails({ ...offeringDetails, programName: e.target.value }) }} value={offeringDetails.programName} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name" >Delivery Type<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={20} onChange={(e) => { setOfferingDetails({ ...offeringDetails, deliveryType: e.target.value }) }} value={offeringDetails.deliveryType} placeholder="" />
                                    </div>
                                </div>

                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Industry<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={100} onChange={(e) => { setOfferingDetails({ ...offeringDetails, industry: e.target.value }) }} value={offeringDetails.industry} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Location<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={100} onChange={(e) => { setOfferingDetails({ ...offeringDetails, location: e.target.value }) }} value={offeringDetails?.location} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">FY<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={10} onChange={(e) => { setOfferingDetails({ ...offeringDetails, fy: e.target.value }) }} value={offeringDetails.fy} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">FSS<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={100} onChange={(e) => { setOfferingDetails({ ...offeringDetails, fss: e.target.value }) }} value={offeringDetails.fss} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Onboarding Video Link<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" onChange={(e) => { setOnboardingVideo({ ...onboardingVideo, link: e.target.value }) }} value={onboardingVideo?.link} placeholder="" />
                                    </div>
                                </div>

                                <div className="form-group col">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Short Description<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={200} onChange={(e) => { setOfferingDetails({ ...offeringDetails, shortDescription: e.target.value }) }} value={offeringDetails.shortDescription} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <div className="d-flex ">
                                        <label className="program-label" htmlFor="program-name">Long Description<span className="required-star">*</span></label>
                                        <textarea required className="form-control" rows='4' maxLength={500} value={offeringDetails.longDescription} onChange={(e) => { setOfferingDetails({ ...offeringDetails, longDescription: e.target.value }) }} />
                                    </div>
                                </div>
                            </div>

                            <div className="divider" />

                            <div className="page-header mb-3">
                                <h5 className="m-0">Dean Information</h5>
                            </div>
                            <div className="row mb-4">

                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">First Name<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={100} onChange={(e) => { setOfferingDetails({ ...offeringDetails, deanFirstName: e.target.value }) }} value={offeringDetails.deanFirstName} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Last Name<span className="required-star">*</span></label>
                                        <input required type="text" className="form-control" maxLength={50} onChange={(e) => { setOfferingDetails({ ...offeringDetails, deanLastName: e.target.value }) }} value={offeringDetails.deanLastName} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Email<span className="required-star">*</span></label>
                                        <input required type="email" className="form-control" maxLength={50} onChange={(e) => { setOfferingDetails({ ...offeringDetails, deanEmail: e.target.value }) }} value={offeringDetails.deanEmail} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="d-flex align-items-center">
                                        <label className="program-label" htmlFor="program-name">Profile Image<span className="required-star">*</span></label>
                                        <ImageUpload getFileUrl={getProfileImage} inputId={1} filename={offeringDetails.deanProfileImage} required={offeringDetails.deanProfileImage != "" ? false : true} />
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="d-flex ">
                                        <label className="program-label" htmlFor="program-name">Brief<span className="required-star">*</span></label>
                                        <div className="w-100">
                                            <CKEditor
                                                className={"mission-full-width"}
                                                editor={ClassicEditor}
                                                data={offeringDetails.deanBrief}
                                                config={{
                                                    placeholder: "",
                                                }}
                                                onReady={editor => {
                                                    editor.editing.view.change(writer => {
                                                        writer.setStyle("min-height", "100px", editor.editing.view.document.getRoot());
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setOfferingDetails({ ...offeringDetails, deanBrief: data });
                                                    // console.log({ event, editor, data });
                                                    // min height 200px
                                                }}

                                            />
                                        </div>
                                    </div>
                                    {/* <textarea required className="form-control" rows='5' value={offeringDetails.deanBrief} onChange={(e) => { setOfferingDetails({ ...offeringDetails, deanBrief: e.target.value }) }} /> */}
                                </div>
                            </div>
                        </div>
                    </form>
                    : <div><Loader /></div>
                }
            </Tab>
            <Tab eventKey="meta-data" title="Offering Metadata">
                <MissionMetadata />
            </Tab>
        </Tabs >


    </>
}
export default OfferingDetails;
