import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Tooltips = (props) => {

    return (
        <OverlayTrigger
            key={props.placement}
            delay={{ show: 800 }}
            placement={props.placement}
            trigger={['hover', 'focus']}
            overlay={
                <Tooltip id={`tooltip-${props.placement}`}>
                    {props.text}
                </Tooltip>
            }
        >
            {props.children}
        </OverlayTrigger>
    );
}

Tooltips.defaultProps = {
    placement: "top",
    text: "Tooltip text"
}

export default Tooltips;