import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";

const Tokens = (props) => {
    const user = useContext(UserContext);
    const [loading, setLoading] = React.useState(true);

    const [tokens, setTokens] = React.useState([
        {
            id: 1,
            couponName: "Connect",
            description: "Who eﬀectively manages relationships with leads and teams to deliver excellent results …",
            image: "",
        },
    ]);
    const [tableData, setTableData] = React.useState({});

    useEffect(() => {
        getCoupons();
    }, []);

    useEffect(() => {
        generateTableData();
    }, [tokens]);

    const getCoupons = () => {
        ProgramSetupAxios.get("/OfferingCoupon/getofferingcoupons/" + user.selectedOffering)
            .then(response => {
                setTokens(response.data?.sort((a, b) => a.sequence - b.sequence));
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const generateTableData = () => {
        let columns = [
            {
                label: "Token Name",
                field: 'couponName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Coupon Name',
                }
            },
            {
                label: "Description",
                field: 'description',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Description',
                }
            },
            // {
            //     label: "Image",
            //     field: 'image',
            //     attributes: {
            //         'aria-controls': 'DataTable',
            //         'aria-label': 'Image',

            //     }
            // },
            {
                label: "Actions",
                field: 'actions',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Actions',
                    className: user.isPublished ? 'text-center w-10 locked' : 'text-center w-10',
                }
            }
        ]

        let rows = [];
        tokens.forEach(token => {
            let row = {};
            row.id = { value: token.id, label: token.id, export: token.id };
            row.couponName = { value: token.couponName, label: token.couponName, export: token.couponName };
            row.description = { value: token.description, label: token.description, export: token.description };
            // row.image = { value: token.image, label: token.image, export: token.image };
            row.actions = { value: "", label: <EditToken token={token} />, export: "" };
            rows.push(row);
        })

        setTableData({ columns, rows });

    }

    const EditToken = (props) => {
        const [show, setShow] = React.useState(false);
        const [token, setToken] = React.useState(props.token);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const handleSubmit = (e) => {
            e.preventDefault();
            let data = [
                {
                    id: token.id,
                    offeringId: user.selectedOffering,
                    stageId: 0,
                    couponName: token.couponName,
                    type: token.type,
                    description: token.description,
                }
            ]
            ProgramSetupAxios.post("/OfferingCoupon/offeringcoupons", data)
                .then(response => {
                    getCoupons();
                    handleClose();
                }).catch(error => {
                    console.log(error);
                });

        }

        return (
            <>
                <Button variant="outline-primary" onClick={handleShow} size="sm">
                    <i className="fa fa-pencil" />
                </Button>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    centered
                    size="md"
                ><form onSubmit={handleSubmit}>
                        <Modal.Header >
                            <Modal.Title>Edit Token</Modal.Title>
                            <div className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="couponName">Token Name<span className="danger">*</span></label>
                                <input type="text" className="form-control" placeholder="Token Name" value={token.couponName} onChange={(e) => setToken({ ...token, couponName: e.target.value })} required />

                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description<span className="danger">*</span></label>
                                <textarea className="form-control" rows="2" placeholder="Description" value={token.description} onChange={(e) => setToken({ ...token, description: e.target.value })} required />
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="image">Image</label>
                                <input type="text" className="form-control" placeholder="Image" value={token.image} onChange={(e) => setToken({ ...token, image: e.target.value })} />
                            </div> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" className="btn-text" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" size="sm" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );

    }

    return (
        <>
            <div className="page-header  mb-4">
                <h1>Tokens</h1>
                <div className="page-actions">

                </div>

            </div>
            <div className="data-table">
                <DataTable multiSelect={false} loaderShow={loading} {...tableData} />
            </div>
        </>
    );
}

export default Tokens;