import React, { useContext, useEffect, useState } from "react";
import "./ProgramSetup.css";
import UploadFile from "./UploadFile";
import { IAMAxios, ProgramSetupAxios } from "../../API";
import { Button, Modal, Form, Tabs, Tab } from "react-bootstrap";
import DataTable from "../DataTable/DataTable";
import UploadData from "../EligiblePool/UploadData";
import ExportExcel from "../Exports/ExportExcel";
import UserContext from "../../UserContext";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ExportExcelAPI from "../Exports/ExportExcelAPI";
import axios from "axios";

let cancelToken = axios.CancelToken.source();
const Registrations = (props) => {
    const user = useContext(UserContext);
    const [usersList, setUsersList] = useState([]);
    const [usersListExport, setUsersListExport] = useState([]);
    const [userId, setUserId] = useState();
    const [userFirstName, setUserFirstName] = useState();
    const [userLastName, setUserLastName] = useState();
    const [userEmail, setUserEmail] = useState();
    const [userIndustry, setUserIndustry] = useState();
    const [userGeography, setUserGeography] = useState();
    const [userLocation, setUserLocation] = useState();
    const [userOffering, setUserOffering] = useState();
    const [userPortfolio, setUserPortfolio] = useState();
    const [userJobLevel, setUserJobLevel] = useState();
    const [tableData, setTableData] = React.useState({});
    const [filterData, setFilterData] = React.useState([]);
    const [filters, setFilters] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(true);
    const [key, setKey] = React.useState("coaches");
    const [pageNo, setPageNo] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [showEditUser, setShowEditUser] = useState(false);



    useEffect(() => {
        var userListData = generateTableData(usersList);
        setFilterData(userListData?.rows);
        setTableData(userListData);
    }, [usersList]);



    useEffect(() => {
        let filterOptions = {
            offeringId: user.selectedOffering,
            isRegisterd: 1,
            pageNumber: 1,
            pageSize: 5
        };

        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions)
            .then(function (response) {
                setTotalCount(response.data[0].totalCount)
                let isOfferingUserCreated = response.data[0]["isRegisteredUserPresent"];;
                console.log("isOfferingUserCreated", isOfferingUserCreated);
                if (isOfferingUserCreated) {
                    let updatedMenuStatus = [
                        { link: '/registrations', unLocked: true, isCompleted: true },
                        { link: '/team-allocation', unLocked: true },
                    ];
                    user.setMenuStatus(updatedMenuStatus)
                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }, []);


    useEffect(() => {
        getSAPUsers();
    }, [key, pageNo, pageSize, user.selectedOffering]);


    const getSAPUsers = () => {
        setLoaderShow(true);
        setUsersList([]);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        let filterOptions = {
            offeringId: user.selectedOffering,
            pageNumber: pageNo,
            pageSize: pageSize,
            ...options
        }

        if (key === "coaches") {
            filterOptions.role = 5;
        } else {
            filterOptions.role = 4;
        }

        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions)
            .then(function (response) {
                setUsersList(response.data);
                setTotalCount(response.data[0].totalCount)
                if (pageNo == 1) {
                    getSAPUsersExportData();
                }
                setLoaderShow(false);
            })
            .catch(function (error) {
                setLoaderShow(false);
                setUsersList([]);
                console.log(error);

            });
    };

    const [isLoadingExport, setIsLoadingExport] = React.useState(false);
    const getSAPUsersExportData = () => {
        setUsersListExport([]);
        setIsLoadingExport(true);
        let options = {};
        for (let key in filters) {
            if (filters[key] !== "") {
                options[key] = filters[key];
            }
        }

        let filterOptions = {
            offeringId: user.selectedOffering,
            pageNumber: 0,
            pageSize: 0,
            ...options
        }

        if (key === "coaches") {
            filterOptions.role = 5;
        } else {
            filterOptions.role = 4;
        }
        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();
        ProgramSetupAxios.post("/EligiblePool/geteligibleuserbyoffering", filterOptions, { cancelToken: cancelToken.token })
            .then(function (response) {
                setIsLoadingExport(false);
                var userListData = generateTableData(response.data);
                setUsersListExport(userListData?.rows);
                //setTableData(userListData);

            })
            .catch(function (error) {
                setIsLoadingExport(false);
                setUsersListExport([]);
            });

    };



    const generateTableData = (rawData) => {

        // let roles = usersList.map((x) => {
        //     let label = roleLabels[x.role];
        //     return { value: x.role, label: label };
        // });
        // const ids = roles.map(o => o.value);
        // roles = roles.filter(({ value }, index) => !ids.includes(value, index + 1));

        let columns = [
            {
                label: "ID",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Last Name',
                    className: 'hidden',
                },
                filter: true
            },
            {
                label: "First Name",
                field: 'firstName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'First Name',
                    className: 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Last Name",
                field: 'lastName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Last Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Email ID",
                field: 'email',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Email',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Offering",
                field: 'offering',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Offering',
                },
                sort: true,
                filter: true,
                filterType: 'text',

            },
            {
                label: "Portfolio",
                field: 'portfolio',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Portfolio',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Industry",
                field: 'industry',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Industry',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Location",
                field: 'location',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Location',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Geography",
                field: 'geography',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Geography',
                    'className': 'text-center',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Job Level",
                field: 'jobLevel',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Job Level',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Registered?",
                field: 'isRegistered',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Registered',
                },
                sort: true,
                filter: true,
                filterType: 'select',
                filterList: [
                    { value: 0, label: 'All' },
                    { value: 1, label: 'Yes' },
                    { value: -1, label: 'No' },
                ]
            },

            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action',
                    'className': user.isPublished ? 'text-center locked' : 'text-center',
                }
            },

        ];
        let rows = [];
        rawData.forEach((user) => {
            let row = {};

            row.id = { value: user.id, label: user.id, export: user.id };
            row.firstName = { value: user.firstName, label: user.firstName, export: user.firstName };
            row.lastName = { value: user.lastName, label: user.lastName, export: user.lastName };
            row.role = { value: user.role ? user.role : 4, label: user.role ? user.role : 4, };
            row.email = { value: user.email, label: user.email, export: user.email };
            row.offering = { value: user.offering, label: user.offering, export: user.offering };
            row.portfolio = { value: user.portfolio, label: user.portfolio, export: user.portfolio };
            row.industry = { value: user.industry, label: user.industry, export: user.industry };
            row.geography = { value: user.geography, label: user.geography, export: user.geography };
            row.location = { value: user.location, label: user.location, export: user.location };
            row.jobLevel = { value: user.jobLevel, label: user.jobLevel, export: user.jobLevel };
            row.isRegistered = { value: user.userId > 0 ? true : false, label: user.userId > 0 ? "Yes" : "No", export: user.userId > 0 ? "Yes" : "No" };
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button className="btn-text btn-sm" variant="outline-primary" onClick={() => editSAPUser(user)}><i className="fa fa-pencil" /></Button>
                        <DeleteUser {...user} />
                    </div>
            };
            row.selectable = user.isOfferingUserCreated ? false : true;
            rows.push(row);
        });


        // filter data by role

        // let coachData = rows.filter(x => x.role['value'] === 5);
        // let learnerData = rows.filter(x => x.role['value'] === 4);

        // setCoachTableData({ columns, rows: coachData });
        // setUserTableData({ columns, rows: learnerData });
        // if (key === "coaches") {
        //     setFilterData(coachTableData.rows);
        // }

        // if (key === "participants") {
        //     setFilterData(userTableData.rows);
        // }


        //setTableData({ columns, rows });
        // setFilterData(rows);
        return { columns, rows };
    };

    const DeleteUser = (user) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = { ...user, isActive: false }

            ProgramSetupAxios.put("/EligiblePool/updateeligibleusers", [data])
                .then(function () {
                    getSAPUsers();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete User</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this user?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const editSAPUser = (user) => {
        setUserId(user.id);
        setUserFirstName(user.firstName);
        setUserLastName(user.lastName);
        setUserEmail(user.email);
        setUserIndustry(user.industry);
        setUserGeography(user.geography);
        setUserLocation(user.location);
        setUserOffering(user.offering);
        setUserPortfolio(user.portfolio);
        setUserJobLevel(user.jobLevel);
        setShowEditUser(true);
    };

    const saveUser = (e) => {
        e.preventDefault();
        let data = [{
            id: userId,
            firstName: userFirstName,
            lastName: userLastName,
            email: userEmail,
            industry: userIndustry,
            geography: userGeography,
            location: userLocation,
            offering: userOffering,
            portfolio: userPortfolio,
            jobLevel: userJobLevel,
            isActive: user.isActive,
        }];


        IAMAxios.put("/SAPUser/updatesapusers", data)
            .then(function () {
                getSAPUsers();
                setShowEditUser(false);
            })
            .catch(function (error) {
                console.log(error);
                setShowEditUser(false);
            });
    };

    const downloadTemplate = () => {
        var json = [{ FirstName: "firstname", LastName: "lastname", Email: "email", Portfolio: "portfolio", Offering: "offering", Location: "location", Industry: "industry", Geography: "geography", JobLevel: "job level", Role: "coach/learner", OfferingId: "OfferingId of created Offering in cdx" }];
        let ws = XLSX.utils.json_to_sheet(json);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'CDx-Eligible-Pool-Data-Template.xlsx');

    }

    return (
        <>
            <div className="page-header  mb-4">
                <h1>Registrations</h1>
                <div className="page-actions">
                    <Button variant="link" onClick={downloadTemplate}>
                        Download Import Template
                    </Button>
                    <UploadData getSAPUsers={getSAPUsers} title="Import Eligible Pool" disabled={user.isPublished} />
                    {/* <UploadFile title="Import SAP Data" /> */}
                    <ExportExcel jsonObject={usersListExport} isLoading={isLoadingExport} columns={tableData.columns} fileName="Registrations.csv" />

                </div>

            </div>
            <div className="data-table">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => { setKey(k); setPageNo(1); }}
                    className="mb-3"
                >
                    <Tab eventKey="coaches" title="Coaches">
                        {key === "coaches" &&
                            <DataTable apiPagination={true} multiSelect={false} loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} applyFilters={getSAPUsers} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} totalCount={totalCount} />
                        }
                    </Tab>
                    <Tab eventKey="participants" title="Participants">
                        {key === "participants" &&
                            <DataTable apiPagination={true} multiSelect={false} loaderShow={loaderShow} {...tableData} setFilters={setFilters} filters={filters} setFilterData={setFilterData} applyFilters={getSAPUsers} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} totalCount={totalCount} />
                        }
                    </Tab>
                </Tabs>
            </div>

            <Modal show={showEditUser}
                dialogClassName="upload-modal"
                size="lg"
                centered>
                <form onSubmit={saveUser}>
                    <Modal.Header>
                        <Modal.Title>Update User</Modal.Title>
                        <div className="close" onClick={() => setShowEditUser(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="mb-2 user-form row">
                        <Form.Group className="mb-2  col-md-12" >
                            <Form.Label >Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label >First Name</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter first name" value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Last Name</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter last name" value={userLastName} onChange={(e) => setUserLastName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Offering</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter offering" value={userOffering} onChange={(e) => setUserOffering(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Portfolio</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter portfolio" value={userPortfolio} onChange={(e) => setUserPortfolio(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Industry</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter industry" value={userIndustry} onChange={(e) => setUserIndustry(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Geography</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter geography" value={userGeography} onChange={(e) => setUserGeography(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Location</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter location" value={userLocation} onChange={(e) => setUserLocation(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6" >
                            <Form.Label >Job Level</Form.Label>
                            <Form.Control type="text" maxLength={200} placeholder="Enter job level " value={userJobLevel} onChange={(e) => setUserJobLevel(e.target.value)} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-text"
                            variant="link"
                            onClick={() => setShowEditUser(false)}
                        >
                            Close
                        </Button>
                        <Button type="submit" variant="primary" >
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default Registrations;
