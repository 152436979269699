import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Modal, ButtonGroup } from "react-bootstrap";
import { ProgramSetupAxios } from "../../../API";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';
import { Accordion } from 'react-bootstrap';
import ActivityItem from "./ActivityItem";
import UserContext from "../../../UserContext";
import { ACTIVITY_TYPE } from "../../../shared/Enum";
import IconSelector from "./IconSelector";

const Stages = (props) => {
    const user = useContext(UserContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tempStage, setTempStage] = useState(props.item);
    const [activeKey, setActiveKey] = useState(1)

    useEffect(() => {
        setTempStage(props.item)
    }, [props.item]);

    const saveStage = (e) => {
        e.preventDefault();
        //get missions by saveAs 

        let data = [{
            id: props.item.id,
            offeringId: user.selectedOffering,
            missionId: props.selectedMission.id,
            stageName: tempStage.stageName,
            stageIcon: tempStage.stageIcon,
            timezone: props.item.timezone,
            shortDescription: props.item.shortDescription,
            longDescription: props.item.longDescription,
            sequence: props.item.sequence,
            startDate: props.item.startDate,
            endDate: props.item.endDate,
            imagePath: props.item.imagePath,
            activities: props.item.activities

        }];
        var missionToCopy = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);

        if (missionToCopy && missionToCopy.length > 0) {
            var stageToCopy = props.allStageData.filter(x => missionToCopy.filter(y => x.missionId == y.id).length)

            if (stageToCopy && stageToCopy[props.index]) {
                let updateStage = [];
                updateStage.push(stageToCopy[props.index]);

                //logic for more than one stage copy
                updateStage.concat(stageToCopy.filter(x => x.stageName == stageToCopy[props.index].stageName && x.id != stageToCopy[props.index].id))
                //updateStage.push(stageToCopy.filter(x => x.stageName == stageToCopy[props.index + 1].stageName));

                updateStage.forEach(element => {
                    let activities = [];
                    tempStage.activities.forEach((activity, aIndex) => {
                        if (element.activities[aIndex]) {
                            let tempActivity = {  // existing activity
                                id: element.activities[aIndex].id,
                                offeringId: element.activities[aIndex].offeringId,
                                missionId: element.activities[aIndex].missionId,
                                stageId: element.activities[aIndex].stageId,
                                missionName: element.activities[aIndex].missionName,
                                activityName: activity.activityName,
                                activityType: activity.activityType,
                                shortDescription: activity.shortDescription,
                                longDescription: activity.longDescription,
                                sequence: activity.sequence,
                                nextSteps: activity.nextSteps,
                                imagePath: activity.imagePath,
                                startDate: element.activities[aIndex].startDate,
                                endDate: element.activities[aIndex].endDate,
                                timezone: element.activities[aIndex].timezone,
                                isTeamActivity: activity.isTeamActivity,
                                isCoachOnlyActivity: activity.isCoachOnlyActivity,
                                isLearnerOnlyActivity: activity.isLearnerOnlyActivity,
                                isAutoComplete: activity.isAutoComplete
                            }
                            activities.push(tempActivity);
                        } else {  // new activity
                            let currentMission = props.missionsData.filter(x => element.missionId == x.id)

                            let tempActivity = {
                                id: 0,
                                offeringId: element.offeringId,
                                missionId: currentMission[0].id,
                                stageId: element.id,
                                missionName: currentMission[0].missionName,
                                activityName: activity.activityName,
                                activityType: activity.activityType,
                                shortDescription: activity.shortDescription,
                                longDescription: activity.longDescription,
                                sequence: activity.sequence ? activity.sequence : 1,
                                nextSteps: activity.nextSteps,
                                imagePath: activity.imagePath,
                                startDate: null,
                                endDate: null,
                                timezone: "",
                                isTeamActivity: activity.isTeamActivity,
                                isCoachOnlyActivity: activity.isCoachOnlyActivity,
                                isLearnerOnlyActivity: activity.isLearnerOnlyActivity,
                                isAutoComplete: activity.isAutoComplete
                            }
                            activities.push(tempActivity);
                        }
                    });
                    let tempStages = {  // existing stage
                        id: element.id,
                        offeringId: element.offeringId,
                        stageName: tempStage.stageName,
                        stageIcon: tempStage.stageIcon,
                        missionId: element.missionId,
                        timezone: element.timezone,
                        shortDescription: props.item.shortDescription,
                        longDescription: props.item.longDescription,
                        sequence: props.item.sequence,
                        startDate: element.startDate,
                        endDate: element.endDate,
                        imagePath: props.item.imagePath,
                        activities: activities
                    }
                    data.push(tempStages)
                })

            } else {
                missionToCopy.forEach(element => {
                    let activities = [];// new stage
                    if (props?.item?.activities?.length > 0) {
                        props.item.activities.forEach(activity => {
                            let tempActivity = {
                                id: 0,
                                offeringId: user.selectedOffering,
                                missionId: element.id,
                                stageId: 0,
                                missionName: element.missionName,
                                activityName: activity.activityName,
                                activityType: activity.activityType,
                                shortDescription: activity.shortDescription,
                                longDescription: activity.longDescription,
                                sequence: activity.sequence,
                                nextSteps: activity.nextSteps,
                                imagePath: activity.imagePath,
                                startDate: null,
                                endDate: null,
                                timezone: "",
                                isTeamActivity: activity.isTeamActivity,
                                isCoachOnlyActivity: activity.isCoachOnlyActivity,
                                isLearnerOnlyActivity: activity.isLearnerOnlyActivity,
                                isAutoComplete: activity.isAutoComplete
                            }
                            activities.push(tempActivity);
                        });
                    }
                    let tempStages = {
                        id: 0,
                        offeringId: user.selectedOffering,
                        stageName: tempStage.stageName,
                        stageIcon: tempStage.stageIcon,
                        missionId: element.id,
                        timezone: props.item.timezone,
                        shortDescription: props.item.shortDescription,
                        longDescription: props.item.longDescription,
                        sequence: props.item.sequence ? props.item.sequence : 1,
                        startDate: null,
                        endDate: null,
                        imagePath: props.item.imagePath,
                        activities: activities
                    }
                    data.push(tempStages)
                });
            }
        }

        ProgramSetupAxios.post("/OfferingStage/createofferingstagewithactivities", data)
            .then((response) => {
                user.createToast({
                    title: "Stage and Activities ",
                    message: "Stage and activities saved successfully",
                    variant: "success" // "success", "warning", "danger", "info"
                }
                )
                props.getStagesByOfferingId();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const deleteStage = () => {
        if (props.item.id != 0) {
            let dataId = [props.item.id];
            var missionToCopy = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);
            if (missionToCopy && missionToCopy.length > 0) {
                var stagesToBeRemoved = props.allStageData.filter(x => missionToCopy.filter(y => (x.missionId == y.id && x.stageName == tempStage.stageName && props.item.id != x.id)).length)

                if (stagesToBeRemoved && stagesToBeRemoved.length > 0) {
                    stagesToBeRemoved.forEach(element =>
                        dataId.push(element.id)
                    )
                }
            }

            ProgramSetupAxios.post("/OfferingStage/deleteofferingstages", dataId)
                .then((response) => {
                    // props.getStagesByOfferingId();
                    resetStageSequence();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            props.getStagesByOfferingId();
        }
    };

    const deleteActivity = (activity, activityIndex) => {
        let activityDelete = [activity.id];

        var missionToCopy = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);
        if (missionToCopy && missionToCopy.length > 0) {
            var stagesToBeRemoved = props.allStageData.filter(x => missionToCopy.filter(y => (x.missionId == y.id && x.stageName == tempStage.stageName && props.item.id != x.id)).length)

            if (stagesToBeRemoved && stagesToBeRemoved.length > 0) {
                stagesToBeRemoved.forEach(element =>
                    activityDelete.push(element.activities[activityIndex].id)
                )
            }
        }
        ProgramSetupAxios.post("/OfferingActivity/deleteofferingactivities", activityDelete)
            .then((response) => {
                //props.getStagesByOfferingId();
                deleteNonSavedActivity(activity, activityIndex);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const deleteNonSavedActivity = (activity, activityIndex) => {
        let tempActivity = props.item.activities;
        tempActivity.splice(activityIndex, 1);
        props.setStageData(props.index, { ...props.item, activities: tempActivity });
    }

    const addActivity = () => {
        let temp = {
            id: 0,
            activityName: "",
            offeringId: user.selectedOffering,
            missionId: props.selectedMission.id,
            stageId: props.item.id,
            missionName: props.selectedMission.missionName,
            activityType: ACTIVITY_TYPE.notSpecified,
            shortDescription: null,
            longDescription: "[{\"status\":0, \"text\":\"\"}]",
            sequence: tempStage.activities.length + 1,
            imagePath: "",
            startDate: null,
            endDate: null,
            timezone: "",
            isTeamActivity: false,
            isCoachOnlyActivity: false,
            isLearnerOnlyActivity: false,
            isAutoComplete: false,
            open: true
        };
        let tempStageData = JSON.parse(JSON.stringify(props.stageData));
        tempStageData[props.index].activities.push(temp);
        props.addActivity(tempStageData);
    }

    const toogleAccordion = (key) => {

        if (key === activeKey) {
            setActiveKey(0)
        }
        else {
            setActiveKey(key);
        }
    }

    const sequenceUp = (tempStage, index) => {
        if (props.index != 0) {
            props.setIsSort(true);
            //sequence --
            var updatedSequenceList = [];

            //original stage
            tempStage.sequence = tempStage.sequence - 1
            updatedSequenceList.push(tempStage);

            //upper stage
            let tempStageData = JSON.parse(JSON.stringify(props.stageData));
            var upperStage = tempStageData[index - 1];
            upperStage.sequence = upperStage.sequence + 1;
            updatedSequenceList.push(upperStage);


            var mission = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);
            if (mission && mission.length > 0) {
                var stagesToUpdate = props.allStageData.filter(x => mission.filter(y => (x.missionId == y.id)).length)

                if (stagesToUpdate && stagesToUpdate.length > 0) {

                    //original stage copy
                    var originalStageCopy = stagesToUpdate.find(x => x.stageName == tempStage.stageName);
                    originalStageCopy.sequence = tempStage.sequence;
                    updatedSequenceList.push(originalStageCopy);

                    //upper stage
                    var upperStageCopy = stagesToUpdate.find(x => x.stageName == upperStage.stageName);
                    upperStageCopy.sequence = upperStage.sequence;
                    updatedSequenceList.push(upperStageCopy);
                }
            }

            UpdateSequence(updatedSequenceList);
        }
    }

    const sequenceDown = (tempStage, index) => {

        props.setIsSort(true);

        //sequence ++
        var updatedSequenceList = [];

        //original stage
        tempStage.sequence = tempStage.sequence + 1
        updatedSequenceList.push(tempStage);

        //lower stage
        let tempStageData = JSON.parse(JSON.stringify(props.stageData));
        var lowerStage = tempStageData[index + 1];
        lowerStage.sequence = lowerStage.sequence - 1;
        updatedSequenceList.push(lowerStage);

        var mission = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);
        if (mission && mission.length > 0) {
            var stagesToUpdate = props.allStageData.filter(x => mission.filter(y => (x.missionId === y.id)).length)

            if (stagesToUpdate && stagesToUpdate.length > 0) {

                //original stage copy
                var originalStageCopy = stagesToUpdate.find(x => x.stageName === tempStage.stageName);
                originalStageCopy.sequence = tempStage.sequence;
                updatedSequenceList.push(originalStageCopy);

                //lower stage
                var lowerStageCopy = stagesToUpdate.find(x => x.stageName === lowerStage.stageName);
                lowerStageCopy.sequence = lowerStage.sequence;
                updatedSequenceList.push(lowerStageCopy);
            }
        }

        UpdateSequence(updatedSequenceList);
    }

    const UpdateSequence = (updateData = []) => {

        var data = []
        updateData.forEach((element) => {
            let stageSeq = {
                id: element.id,
                sequence: element.sequence
            }
            data.push(stageSeq);
        });

        ProgramSetupAxios.put("/OfferingStage/updateofferingstages", data)
            .then((response) => {
                props.getStagesByOfferingId();
                //props.setIsSort(false);
            }).catch((error) => {
                //props.setIsSort(false);
            })

    }

    const resetStageSequence = () => {
        //sequence ++
        var updatedSequenceList = [];

        //selected mission stages
        props.stageData.forEach(element => {
            if (element.sequence > props.item.sequence) {
                element.sequence = element.sequence - 1;
                updatedSequenceList.push(element);
            }
        });

        //copy mission stages update sequence 
        var missionToCopy = props.missionsData.filter(x => props.selectedMission.id == x.sameAs);
        if (missionToCopy && missionToCopy.length > 0) {
            var stagesToUpdate = props.allStageData.filter(x => missionToCopy.filter(y => (x.missionId == y.id)).length)

            if (stagesToUpdate && stagesToUpdate.length > 0) {
                stagesToUpdate.forEach(element => {
                    if (element.sequence > props.item.sequence) {
                        element.sequence = element.sequence - 1;
                        updatedSequenceList.push(element);
                    }
                });
            }
        }
        UpdateSequence(updatedSequenceList);
    }


    const activitySequenceUp = (index) => {
        var stage = JSON.parse(JSON.stringify(tempStage));

        stage.activities[index - 1].sequence = stage.activities[index - 1].sequence + 1;
        stage.activities[index].sequence = stage.activities[index].sequence - 1;

        setTempStage(stage);

    }

    const activitySequenceDown = (index) => {
        var stage = JSON.parse(JSON.stringify(tempStage));

        stage.activities[index + 1].sequence = stage.activities[index + 1].sequence - 1;
        stage.activities[index].sequence = stage.activities[index].sequence + 1;

        setTempStage(stage);
    }

    return (<>
        <Accordion.Item className="mission-box" eventKey={props.index + 1}>
            <Form onSubmit={(e) => saveStage(e)}>
                <Accordion.Header as="div" className="mission-header" onClick={props.setActiveKey}> Stage: {tempStage.stageName || tempStage.stageName != "" ? tempStage.stageName : "New Stage"}
                    <div className="page-actions me-2">
                        {tempStage.id != 0 && user.isPublished == false &&
                            <ButtonGroup className="me-2" aria-label="First group">
                                <Button variant="outline-primary" className="" size="sm" onClick={(e) => { sequenceUp(tempStage, props.index); e.stopPropagation(); }} disabled={props.index === 0 || props.isSort}>
                                    <i className="fa fa-arrow-up" /> {tempStage.sequence}
                                </Button>
                                <Button variant="outline-primary" className="btn-sm" onClick={(e) => { sequenceDown(tempStage, props.index); e.stopPropagation(); }} disabled={props.index === props.stageData.length - 1 || props.disableNext || props.isSort}>
                                    <i className="fa fa-arrow-down" />
                                </Button>
                            </ButtonGroup>
                        }
                        <Button variant="primary" className="btn-sm" type="submit" onClick={(e) => { e.stopPropagation(); }} disabled={user.isPublished}>
                            <i className="fa fa-floppy-o" />
                        </Button>
                        {/* <Button variant="secondary" className="btn-sm" onClick={() => saveMission()}><i className="fa fa-minus-circle me-2" />Cancel</Button> */}
                        <Button variant="outline-danger" className="btn-sm" onClick={(e) => { setShowDeleteModal(true); e.stopPropagation(); }} disabled={user.isPublished}>
                            <i className="fa fa-trash " />
                        </Button>
                    </div>
                </Accordion.Header>
                <Accordion.Body className="mission-body">

                    {/* <Form.Group className="mb-3  d-flex">
                    <Form.Label className="mission-label">Mission </Form.Label>
                    <Form.Select className="mission-width-name" value={props.item.missionId} onChange={(e) => { props.setStageData(props.index, { ...props.item, missionId: e.target.value }); }}>
                        <option>Select mission</option>
                        {props.missionData.length > 0 && props.missionData.map((mission) => {
                            return (<option value={mission.id}>{mission.missionName}</option>);
                        })
                        }
                    </Form.Select>
                </Form.Group> */}

                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Stage Name<span className="required-star">*</span></Form.Label>
                        <Form.Control className="mission-width-name" type="text" maxLength={200} value={tempStage.stageName} onChange={(e) => { setTempStage({ ...tempStage, stageName: e.target.value }) }} onBlur={(e) => { props.setStageData(props.index, { ...props.item, stageName: e.target.value }); }} placeholder="Enter value" required />
                        <div className="ms-2 flex-wrap">
                            {/* <IconSelector disabled={user.isPublished} icon={tempStage.stageIcon} onChange={(item) => { setTempStage({ ...tempStage, stageIcon: item }) }} /> */}
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Short Description<span className="required-star">*</span>	</Form.Label>
                        <Form.Control className="mission-full-width" as="textarea" maxLength={200} value={props.item.shortDescription} rows={2} onChange={(e) => { props.setStageData(props.index, { ...props.item, shortDescription: e.target.value }); }} placeholder="Enter value" required />
                    </Form.Group>
                    <Form.Group className="mb-3  d-flex">
                        <Form.Label className="mission-label">Long Description<span className="required-star">*</span>	</Form.Label>
                        {/* <Form.Control className="mission-full-width" as="textarea" rows={3} value={props.item.longDescription} onChange={(e) => { props.setMissionData(props.index, { ...props.item, longDescription: e.target.value }); }} placeholder="Enter value" /> */}
                        <div className="w-100">
                            <CKEditor
                                editor={Editor}
                                data={props.item.longDescription}
                                required
                                config={{
                                    placeholder: "Enter value",
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.setStageData(props.index, { ...props.item, longDescription: data });
                                    // console.log({ event, editor, data });
                                }}
                            />
                        </div>
                    </Form.Group>

                    <Accordion activeKey={activeKey}>
                        {tempStage.activities?.length > 0 && tempStage.activities.sort((a, b) => a.sequence - b.sequence).map((activity, index) => {
                            return <ActivityItem setActiveKey={() => toogleAccordion(index + 1)} item={activity} key={"activityId" + activity.activityName + index} index={index} parentIndex={props.index} setActivityData={props.activityChange} deleteActivity={deleteActivity} deleteNonSavedActivity={deleteNonSavedActivity} activityCount={props.item.activities.length} sequenceUp={activitySequenceUp} sequenceDown={activitySequenceDown} />
                        })
                        }
                    </Accordion>
                    {props.item.activities && props.item.activities.length < 10 &&
                        <Button variant="secondary" className="w-100 " onClick={() => { addActivity(); setActiveKey(props.item.activities.length + 1) }}>
                            + Add Activity
                        </Button>
                        /* <div className="add-mission-button" onClick={addActivity}>
                            + Add Activity</div> */}


                </Accordion.Body>
            </Form>
        </Accordion.Item>


        <Modal
            show={showDeleteModal}
            centered
            className="admin-modal"
        >
            <Modal.Header >
                <Modal.Title>Delete Stage</Modal.Title>
                <div className="close" onClick={() => setShowDeleteModal(false)}>
                    <span aria-hidden="true">&times;</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this stage?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" size="sm" onClick={deleteStage}>
                    Yes, Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default Stages;