import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgramSetupAxios } from "../../API";
import UserContext from "../../UserContext";
import DataTable from "../DataTable/DataTable";


const RatingTypes = (props) => {
    const user = useContext(UserContext);
    const [offeringRatingTypeList, setOfferingRatingTypeList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [tableData, setTableData] = React.useState({});
    const [filters, setFilters] = React.useState({});
    const [loaderShow, setLoaderShow] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [localRatingType, setLocalRatingType] = React.useState([]);



    useEffect(() => {
        generateTableData();
    }, [offeringRatingTypeList]);

    useEffect(() => {
        setOfferingRatingTypeList(props.offeringRatingTypeList);
    }, [props.offeringRatingTypeList])


    const generateTableData = () => {

        let columns = [
            {
                label: "Id",
                field: 'id',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Id',
                    className: 'hidden',
                },
                sort: true,
                filter: true,
                filterType: 'text',
            },
            {
                label: "Rating Type Name",
                field: 'ratingTypeName',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Rating Type Name',
                },
                sort: true,
                filter: true,
                filterType: 'text',


            },
            {
                label: "Sequence",
                field: 'sequence',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Sequence',
                },
                sort: true,
                filter: true,
                filterType: 'number',

            },
            {
                label: "Action",
                field: 'action',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                    className: 'edit-button-align text-center',
                },

            },
        ];
        let rows = [];
        offeringRatingTypeList.forEach((ratingType, index) => {
            let row = {};
            row.id = { value: (index + 1), label: (index + 1), export: (index + 1) };
            row.ratingTypeName = { value: ratingType.name, label: ratingType.name, export: ratingType.name };
            row.sequence = { value: ratingType.sortOrder, label: ratingType.sortOrder, export: ratingType.sortOrder };
            row.action = {
                value: "", label:
                    <div className="d-flex gap-2 justify-content-center">
                        <Button onClick={() => { setShow(true); setLocalRatingType(ratingType); }} variant="outline-primary" size="sm" >
                            <i className="fa fa-pencil" />
                        </Button >
                        {/* <DeleteLink {...ratingType} /> */}
                    </div>
            };
            rows.push(row);
        });

        setTableData({ columns, rows });
    };


    const addRatingTypes = (e) => {
        e.preventDefault();
        let data = {
            offeringId: user.selectedOffering,
            name: localRatingType.name,
            sortOrder: localRatingType.sortOrder,
        }

        ProgramSetupAxios.post(`/OfferingRatings/createtofferingratingtypes`, [data])
            .then(() => {
                props.getRatings();
                setShow(false);
            }).catch(err => {
                console.log("AddLevel: err", err);
            });
    }


    const updateRatingTypes = (e) => {
        e.preventDefault();
        ProgramSetupAxios.put(`/OfferingRatings/updateofferingratingtype`, localRatingType)
            .then(() => {
                props.getRatings();
                setShow(false);
            }).catch(err => {
                console.log("updateLevel: err", err);
            });
    };

    const DeleteLink = (link) => {
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        const handleDelete = () => {
            let data = {
                id: link.id,
                ...link,
                isActive: false
            }

            ProgramSetupAxios.post("/OfferingRatings/deleteofferingratingtypes", [data.id])
                .then(function () {
                    props.getRatings();
                    setShowDeleteModal(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setShowDeleteModal(false);
                });
        };


        return (
            <>
                <Button className="btn-text btn-sm" variant="outline-danger" onClick={() => setShowDeleteModal(true)}><i className="fa fa-trash" /></Button>
                <Modal
                    show={showDeleteModal}
                    centered
                    className="admin-modal"
                >
                    <Modal.Header >
                        <Modal.Title>Delete Scale</Modal.Title>
                        <div className="close" onClick={() => setShowDeleteModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this scale?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" size="sm" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="page-header mb-4">
                <h4>Rating Scale</h4>
                {/* <Button onClick={() => {
                    setShow(true); setLocalRatingType({
                        offeringId: user.selectedOffering,
                        missionId: 0,
                        name: 0,
                        description: 0,
                        maxRating: 0,
                        sortOrder: 0,
                        isOverallRating: true
                    });
                }} className="btn-sm">
                    + Add Rating Scale
                </Button> */}
            </div>
            <div className="data-table">
                <DataTable {...tableData} multiSelect={false} loaderShow={loaderShow} setFilters={setFilters} filters={filters} setFilterData={setFilterData} />
            </div>
            <Modal
                show={show}
                centered
                className="admin-modal"
                size="lg"
            >
                <form onSubmit={(e) => { localRatingType.id != 0 && localRatingType.id ? updateRatingTypes(e) : addRatingTypes(e) }}>
                    <Modal.Header>
                        <Modal.Title>{localRatingType.id != 0 && localRatingType.id ? "Edit" : "Add"} Rating Scale</Modal.Title>
                        <div className="close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="admin-modal">
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="levelName">Rating Name<span className="required-star">*</span></label>
                                <input type="text" maxLength={200} value={localRatingType.name || ''} className="form-control" onChange={(e) => {
                                    setLocalRatingType({ ...localRatingType, name: e.target.value });
                                }} required />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="role">Sequence<span className="required-star">*</span></label>
                                <input type="number" min={0} max={9999} value={localRatingType.sortOrder || ''} className="form-control"
                                    onChange={(e) => {
                                        setLocalRatingType({ ...localRatingType, sortOrder: e.target.value });
                                    }} required />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-text" variant="link" onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit">{localRatingType.id != 0 && localRatingType.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default RatingTypes;